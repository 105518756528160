import * as React from "react";
import { Fragment, useEffect, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Outlet, Link } from "react-router-dom";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { Bars3Icon, BellIcon, XMarkIcon } from "@heroicons/react/24/outline";
import Identicon from "react-identicons";
import Swal from "sweetalert2";
import Http from "../util/http";
import InAppAnnouncement from "../components/InAppAnnouncement";
import {
  findFlagUrlByIso3Code,
  findFlagUrlByCountryName,
  countries,
} from "country-flags-svg";
import http from "../util/http";
import axios from "axios";
import moment from "moment";
import { ChevronDownIcon, PencilSquareIcon } from "@heroicons/react/20/solid";
import AccessControl from "../util/accessControl";
import Error500 from "../pages/error/500";
import Notifications from "../components/dashboard/notifications";

function signOut() {
  localStorage.removeItem("user");
  localStorage.removeItem("token");
  localStorage.removeItem("organization");
  localStorage.removeItem("announcements");
  window.location.href = `${window.location.origin}/signin`;
}

function AddRoutesByPermissions() {
  let paths = [];
  let user = JSON.parse(localStorage.getItem("user"));

  if (user.permissions.includes("addClient")) {
    paths.push({ name: "New IPF", href: "/dashboard/clients", current: false });
  }

  if (user.permissions.includes("viewContracts")) {
    paths.push({
      name: "Contracts",
      href: "/dashboard/contracts",
      current: false,
    });
  }

  if (user.permissions.includes("viewCancellationRoom")) {
    paths.push({
      name: "Cancel-Room",
      href: "/dashboard/cancellations/cancel-room",
      current: false,
    });
  }

  if (user.permissions.includes("viewCancellationReport")) {
    paths.push({
      name: "Cancellations",
      href: "/dashboard/contracts/cancellations",
      current: false,
    });
  }

  if (user.permissions.includes("viewArrearsReport")) {
    paths.push({
      name: "Arrears",
      href: "/dashboard/contracts/arrears",
      current: false,
    });
  }
  if (user.permissions.includes("viewRefundsReport")) {
    paths.push({
      name: "Awaiting Refund",
      href: "/dashboard/contracts/refunds",
      current: false,
    });
  }

  return paths;
}

const userNavigation = [
  { name: "Your Profile", href: "/dashboard/profile" },
  { name: "Settings", href: "/dashboard/settings" },
  { name: "Sign out", href: "#", task: signOut },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function DashboardLayout({ children }) {
  const [path, setPath] = useState();
  const [user, setUser] = useState({ firstName: "", lastName: "", email: "" });
  const [navigation, SetNavigation] = useState([]);
  const [impersonate, setImpersonate] = useState(undefined);
  const [announcements, setAnnouncements] = useState([]);
  const [countries, setCountries] = useState([]);
  const [geolocation, setGeolocation] = useState(null);
  React.useEffect(() => {
    async function preLaunch() {
      let links = [
        { name: "Dashboard", href: "/dashboard", current: false },
        { name: "New IPF", href: "/dashboard/clients", current: false },
        { name: "Contracts", href: "/dashboard/contracts", current: false },
        {
          name: "Arrears",
          href: "/dashboard/contracts/arrears",
          current: false,
        },
        {
          name: "Awaiting Refund",
          href: "/dashboard/contracts/refunds",
          current: false,
        },
        {
          name: "Cancel-Room",
          href: "/dashboard/cancellations/cancel-room",
          current: false,
        },
        {
          name: "Cancellations",
          href: "/dashboard/contracts/cancellations",
          current: false,
        },
        {
          name: "Organizations",
          href: "/dashboard/organizations",
          current: false,
          admin: true,
        },
        {
          name: "Roles",
          href: "/dashboard/roles",
          current: false,
          admin: true,
        },
        {
          name: "Announcements",
          href: "/dashboard/announcements",
          current: false,
          admin: true,
        }
        //{name: 'Audits', href: '/dashboard/audits', current: false},
        //{name: 'IPS', href: '/dashboard/ips', current: false}
      ];

      if(process.env.REACT_APP_USE_SMS === 'true'){
        links.push({
          name: "Approval-Tool",
          href: "/dashboard/tools/approval",
          current: false,
          admin: true,
        })
      }

      if (localStorage.getItem("user") && localStorage.getItem("token")) {
        console.log("logged in");
        if (localStorage.getItem("announcements") === null) {
          localStorage.setItem("announcements", JSON.stringify([]));
        }
        setUser(JSON.parse(localStorage.getItem("user")));
      } else {
        console.log("not logged in");
        window.location.href = `${window.location.origin}/signin`;
      }

      //set user links based on permissions

      // eslint-disable-next-line default-case
      switch (JSON.parse(localStorage.getItem("user")).position) {
        case "admin":
          links = [{ name: "Dashboard", href: "/dashboard", current: false }];
          links = links.concat(AddRoutesByPermissions());
          break;
        case "regular":
          links = [{ name: "Dashboard", href: "/dashboard", current: false }];
          links = links.concat(AddRoutesByPermissions());
          break;
        case "god":
          links.push({
            name: "Permissions",
            href: "/dashboard/permissions",
            current: false,
            admin: true,
          });
          break;
      }

      document.documentElement.setAttribute("class", "bg-gray-100");
      let pathname = window.location.pathname
        .replace("/dashboard", "")
        .replace("/", "");

      if (pathname === "") {
        pathname = window.location.pathname.replace("/", "");
      }
      pathname = pathname.charAt(0).toUpperCase() + pathname.slice(1);
      links.forEach((link, index) => {
        let pathnameItems = pathname.split("/");
        //get the last location in pathname
        if (
          link.name.toLocaleLowerCase() ===
          pathnameItems[pathnameItems.length - 1].toLocaleLowerCase()
        ) {
          link.current = true;
        }
        if (
          pathnameItems[pathnameItems.length - 1].toLocaleLowerCase() ===
            "clients" &&
          link.name === "New IPF"
        ) {
          link.current = true;
        }
      });
      SetNavigation(links);
      setPath(pathname.split("/")[0]);
      setImpersonate(localStorage.getItem("impersonate"));
      await getCountries();
      await getAnnouncements();
    }

    preLaunch();
  }, []);

  async function getAnnouncements() {
    try {
      const shownAnnouncements = JSON.parse(
        localStorage.getItem("announcements")
      );
      const activeAnnouncements = [];

      for (var i = 0; i < shownAnnouncements.length; i++) {
        if (!shownAnnouncements.exp) {
          shownAnnouncements[i] = "";
        }
      }
      let dismissed = [];
      try {
        let arr = JSON.parse(localStorage.getItem("dismissed"));
        if (arr.length) {
          dismissed = arr;
        }
      } catch (e) {
        console.log(e);
      }

      let announcements = await Http.request(
        "admin",
        "notifications/get-active",
        {}
      );
      console.log({ announcements, dismissed });
      for (var x = 0; x < dismissed.length; x++) {
        for (var y = 0; y < announcements.length; y++) {
          if (dismissed[x].id === announcements[y]._id) {
            //decide whether to delete loan here
            if (moment(dismissed[x].time).diff(moment(), "hours") <= 0) {
              console.log("Dismissed time is hit");
            } else {
              console.log("Dismissed time has yet to come");
              announcements.splice(y, 1);
            }
          }
        }
      }

      for (let i = 0; i < announcements.length; i++) {
        if (!shownAnnouncements.includes(announcements[i]._id)) {
          activeAnnouncements.push(announcements[i]);
        }
      }
      setAnnouncements(activeAnnouncements);
    } catch (err) {
      console.log(err);
      await Swal.fire({
        icon: "error",
        title: "Oops...",
        text: err || "Failed to make request to servers.",
      });
    }
  }

  function viewAnnouncement(id) {
    const shownAnnouncements = JSON.parse(
      localStorage.getItem("announcements")
    );
    shownAnnouncements.push(id);
    localStorage.setItem("announcements", JSON.stringify(shownAnnouncements));
  }

  const endSession = async () => {
    try {
      let backup = JSON.parse(localStorage.getItem("userbackup"));
      let payload = {
        user: JSON.parse(localStorage.getItem("user")),
      };
      let headers = {
        "x-auth-token": backup.token,
        geolocation: backup.user.geolocation,
      };
      let response = await axios.post(
        `${process.env.REACT_APP_ADMIN_API}/api`,
        {
          target: "users/end-impersonate",
          payload,
        },
        { headers }
      );

      console.log(response);
      localStorage.setItem("token", backup.token);
      localStorage.setItem("organization", JSON.stringify(backup.organization));
      localStorage.setItem("user", JSON.stringify(backup.user));
      localStorage.setItem("role", backup.role);
      localStorage.removeItem("impersonate");
      localStorage.removeItem("userbackup");
      setImpersonate(undefined);

      Swal.fire({
        icon: "success",
        title: "Hurray!",
        text: `Impersonation session has been ended.`,
      });
      window.location.href = window.location.origin + "/dashboard";
    } catch (e) {
      console.log(e);
      Swal.fire({
        icon: "error",
        title: "Attention!",
        text: `Failed to end impersonation session.`,
      });
      //window.location.href = window.location.origin + '/dashboard'
    }
  };
  const getCountries = async () => {
    let result;
    if (Array.isArray(localStorage.countries)) {
      result = JSON.parse(localStorage.countries);
      setCountries(result);
    } else {
      try {
        result = await http.request("admin", "countries");
        setCountries(result);
        localStorage.setItem("countries", JSON.stringify(result));
      } catch (error) {
        console.log(error);
      }
    }

    let geo = JSON.parse(localStorage.getItem("geolocation"));
    let _user = JSON.parse(localStorage.getItem("user"));

    if (_user.position === "admin" || _user.position === "regular") {
      let index = result.findIndex((x) => x._id === _user.geolocation);

      if (index !== -1) {
        geo = result[index];
        setGeolocation(geo);
        setCountries([geo]);
        localStorage.setItem("geolocation", JSON.stringify(geo));
      }
    }
    console.log({ geo });
    if (!geo) {
      console.log({ result });
      let idx = result.findIndex((x) => x.code === "tto");

      if (idx !== -1) {
        setGeolocation(result[idx]);
        localStorage.setItem("geolocation", JSON.stringify(result[idx]));
      } else {
        setGeolocation(result[0]);
        localStorage.setItem("geolocation", JSON.stringify(result[0]));
      }
    } else {
      setGeolocation(geo);
      localStorage.setItem("geolocation", JSON.stringify(geo));
    }
  };

  const selectGeolocation = async (index) => {
    let geo = countries[index];
    //setGeolocation(geo)
    localStorage.setItem("geolocation", JSON.stringify(geo));
    window.location.reload();
  };
  return (
    <ErrorBoundary fallback={<Error500 />}>
      <div className="min-h-full">
        <Disclosure as="nav" className="bg-sky-800">
          {({ open }) => (
            <>
              <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <div className="flex h-16 items-center justify-between">
                  <div className="flex items-center">
                    <a className="flex-shrink-0" href="/dashboard">
                      <img
                        style={{ borderRadius: "50%" }}
                        className="h-8 w-8"
                        src="/logo.jpg"
                        alt="Solis Credit"
                      />
                    </a>
                    <div className="hidden md:block">
                      <div className="ml-10 flex 2">
                        {navigation.map((item) => (
                          <>
                            {!item.admin && (
                              <a
                                key={item.name}
                                href={item.href}
                                className={classNames(
                                  item.current
                                    ? "bg-sky-900 text-white"
                                    : "text-gray-300 hover:bg-sky-700 hover:text-white",
                                  "rounded-md px-3 py-2 text-sm font-medium text-nowrap"
                                )}
                                aria-current={item.current ? "page" : undefined}
                              >
                                {item.name}
                              </a>
                            )}
                          </>
                        ))}
                        {!["regular", "admin"].includes(user.position) && (
                          <>
                            <Menu
                              as="div"
                              className="relative inline-block text-left"
                            >
                              <div>
                                <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md hover:bg-sky-700 px-3 py-2 text-sm font-medium text-gray-300">
                                  Admin
                                  <ChevronDownIcon
                                    className="-mr-1 h-5 w-5 text-gray-400"
                                    aria-hidden="true"
                                  />
                                </Menu.Button>
                              </div>
                              <Transition
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                              >
                                <Menu.Items
                                  style={{ zIndex: 100000 }}
                                  className="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                                >
                                  <div className="py-1">
                                    {navigation.map((link) => (
                                      <>
                                        {link.admin === true && (
                                          <Menu.Item
                                            as="a"
                                            key={link.href}
                                            href={link.href}
                                            className="text-gray-700 block px-4 py-2 text-sm font-medium "
                                          >
                                            {link.name}
                                          </Menu.Item>
                                        )}
                                      </>
                                    ))}
                                  </div>
                                </Menu.Items>
                              </Transition>
                            </Menu>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="hidden md:block">
                    <div className="ml-4 flex items-center md:ml-6">
                      {impersonate === "true" && (
                        <button
                          onClick={() => {
                            endSession();
                          }}
                          className="inline-flex justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                        >
                          End Session
                        </button>
                      )}

                      {process.env.REACT_APP_USE_SMS === 'true' &&
                          <Notifications />
                      }

                      {/*Country*/}
                      {geolocation && (
                        <Menu as="div" className="relative ml-3">
                          <div>
                            <Menu.Button className="flex max-w-xs items-center bg-sky-800 text-sm text-white">
                              <span className="sr-only">Open country menu</span>
                              <img
                                className="mb-2"
                                style={{
                                  width: "40px",
                                  height: "19px",
                                  margin: "0",
                                }}
                                src={findFlagUrlByIso3Code(geolocation.code)}
                              />
                            </Menu.Button>
                          </div>

                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items className="absolute right-0 z-10 mt-2 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                              {countries.map((country, idx) => (
                                <Menu.Item key={country.code}>
                                  {({ active }) => (
                                    <>
                                      <div
                                        onClick={() => {
                                          selectGeolocation(idx);
                                        }}
                                        style={{ cursor: "pointer" }}
                                        className={
                                          "block px-2 py-2 text-sm text-gray-700"
                                        }
                                      >
                                        <img
                                          className=""
                                          style={{
                                            width: "50px",
                                            height: "19px",
                                            margin: "0",
                                          }}
                                          src={findFlagUrlByIso3Code(
                                            country.code
                                          )}
                                        />
                                      </div>
                                    </>
                                  )}
                                </Menu.Item>
                              ))}
                            </Menu.Items>
                          </Transition>
                        </Menu>
                      )}
                      {/* Profile dropdown */}
                      <Menu as="div" className="relative ml-3">
                        <div>
                          <Menu.Button className="flex max-w-xs items-center rounded-full bg-sky-800 text-sm text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                            <span className="sr-only">Open user menu</span>
                            <Identicon
                              bg={"white"}
                              size="40"
                              className="rounded-full"
                              string={user._id}
                            />
                          </Menu.Button>
                        </div>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            {userNavigation.map((item) => (
                              <Menu.Item key={item.name}>
                                {({ active }) => (
                                  <>
                                    {item.task !== undefined && (
                                      <a
                                        onClick={() => {
                                          item.task();
                                        }}
                                        style={{ cursor: "pointer" }}
                                        className={classNames(
                                          active ? "bg-sky-100" : "",
                                          "block px-4 py-2 text-sm text-gray-700"
                                        )}
                                      >
                                        {item.name}
                                      </a>
                                    )}
                                    {item.task === undefined && (
                                      <a
                                        href={item.href}
                                        className={classNames(
                                          active ? "bg-sky-100" : "",
                                          "block px-4 py-2 text-sm text-gray-700"
                                        )}
                                      >
                                        {item.name}
                                      </a>
                                    )}
                                  </>
                                )}
                              </Menu.Item>
                            ))}
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </div>
                  </div>
                  <div className="-mr-2 flex md:hidden">
                    {/* Mobile menu button */}
                    <Disclosure.Button className="inline-flex items-center justify-center rounded-md bg-sky-800 p-2 text-gray-400 hover:bg-sky-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                      <span className="sr-only">Open main menu</span>
                      {open ? (
                        <XMarkIcon
                          className="block h-6 w-6"
                          aria-hidden="true"
                        />
                      ) : (
                        <Bars3Icon
                          className="block h-6 w-6"
                          aria-hidden="true"
                        />
                      )}
                    </Disclosure.Button>
                  </div>
                </div>
              </div>

              <Disclosure.Panel className="md:hidden">
                <div className="space-y-1 px-2 pb-3 pt-2 sm:px-3">
                  {navigation.map((item) => (
                    <>
                      {item.admin !== true && (
                        <Disclosure.Button
                          key={item.name}
                          as="a"
                          href={item.href}
                          className={classNames(
                            item.current
                              ? "bg-sky-900 text-white"
                              : "text-gray-300 hover:bg-sky-700 hover:text-white",
                            "block rounded-md px-3 py-2 text-base font-medium"
                          )}
                          aria-current={item.current ? "page" : undefined}
                        >
                          {item.name}
                        </Disclosure.Button>
                      )}
                    </>
                  ))}
                </div>
                <div className="border-t border-gray-700 pb-3 pt-4">
                  <div className="flex items-center px-5">
                    <div className="flex-shrink-0">
                      <img
                        className="h-10 w-10 rounded-full"
                        src={user.imageUrl}
                        alt=""
                      />
                    </div>
                    <div className="ml-3">
                      <div className="text-base font-medium text-white">
                        {user.firstName} {user.lastName}
                      </div>
                      <div className="text-sm font-medium text-gray-400">
                        {user.email}
                      </div>
                    </div>
                    {process.env.REACT_APP_USE_SMS === 'true' &&
                        <button
                            type="button"
                            className="ml-auto flex-shrink-0 rounded-full bg-sky-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                        >
                          <span className="sr-only">View notifications</span>
                          <BellIcon className="h-6 w-6" aria-hidden="true"/>
                        </button>
                    }

                  </div>

                  <div className="mt-3 space-y-1 px-2">
                    {userNavigation.map((item) => (
                        <>
                          <Disclosure.Button
                              key={item.name}
                              as="a"
                              href={item.href}
                              className="block rounded-md px-3 py-2 text-base font-medium text-gray-400 hover:bg-sky-700 hover:text-white"
                        >
                          {item.name}
                        </Disclosure.Button>
                      </>
                    ))}
                  </div>
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>

        <header className="bg-white shadow-sm">
          <div className="mx-auto max-w-7xl px-4 py-4 sm:px-6 lg:px-8">
            <h1 className="text-2xl font-semibold leading-6 text-gray-900">
              {path}
            </h1>
          </div>
        </header>
        <main>
          {announcements.map((announcement, index) => (
            <InAppAnnouncement
              key={index}
              title={announcement.title}
              id={announcement._id}
              type={announcement.type}
              view={viewAnnouncement}
            />
          ))}
          <div className="w-100  py-6 sm:px-6 lg:px-8">
            <Outlet />
          </div>
        </main>
      </div>
    </ErrorBoundary>
  );
}
