import {
  setDeepValue,
  getDeepValue,
  formatPhoneNumber,
  getStatus,
} from "../../../../../util/util";
import http from "../../../../../util/http";
import AccessControl from "../../../../../util/accessControl";
import React, { useEffect, useState } from "react";
import swal from "sweetalert2";

import {
  PencilSquareIcon,
  CurrencyDollarIcon,
  EnvelopeIcon,
} from "@heroicons/react/24/outline";
import moment from "moment";
import { Fragment, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { findFlagUrlByIso3Code } from "country-flags-svg";
import TableRow from "../../../../../components/dashboard/tablerow";
import TelephoneInput from "../../../../../components/dashboard/telephoneInput";
import Swal from "sweetalert2";
import Http from "../../../../../util/http";
import VerifyClientModal from "../../../../../components/dashboard/verifyclient";
import Verify from '../../../../verify';
import DetailedSchedule from "../../../../../components/dashboard/DetailedSchedule";
import SummarySchedule from "../../../../../components/dashboard/SummarySchedule";

export default function RetailProfile() {
  const [user, setUser] = useState({});
  const [user2, setUser2] = useState({});
  const [personal, setPersonal] = useState({});
  const [contact, setContact] = useState({});
  const [contact2, setContact2] = useState({});
  const [address, setAddress] = useState({});
  const [address2, setAddress2] = useState({});
  const [employment, setEmployment] = useState({});
  const [employment2, setEmployment2] = useState({});
  const cancelButtonRef = useRef(null);
  const [curAction, setCurAction] = useState("");
  const [curField, setCurField] = useState("");
  const [payment, setPayment] = useState({});
  const [payment2, setPayment2] = useState({});
  const [open, setOpen] = useState(false);
  const [flag, setFlag] = useState("");
  const [editing, setEditing] = useState(false);
  const [current, setCurrent] = useState(0);
  const [contracts, setContracts] = useState(0);
  const [clientNumber, setClientNumber] = useState(0);
  const [tempIdType, setTempIdType] = useState(0);
  const [tempId, setTempId] = useState("");
  const [issuedDate, setIssuedDate] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [countries2, setCountries2] = useState([]);
  const [userInfo, setUserInfo] = useState({});
  const [dirty, setDirty] = useState(false);
  const [show, setShow] = useState(false);
  const [contractView, setContractView] = useState(false);
  const [page, setPage] = useState(1);
  const [curCountry, setCurCountry] = useState({});
  const [countries, setCountries] = useState([]);
  const [currentContract, setCurrentContract] = useState({});
  const [selectedContract, setSelectedContract] = useState({
    _id: "",
    contractNumber: "",
    status: "",
    insuranceCompany: "",
    policyStartDate: "",
    policyEndDate: "",
    totalPremiumFinanced: "",
    scheduledPaymentDate: "",
    policyBreakdown: [],
    paymentSchedule: [],
  });

  const [link,setLink] = useState("");

  function camelCaseToWords(camelCaseStr) {
    // Use a regular expression to split the string at uppercase letters
    const words = camelCaseStr.split(/(?=[A-Z])/);

    // Capitalize the first letter of each word and join them with a space
    const formattedStr = words
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");

    return formattedStr;
  }

  async function getCountries() {
    let countriesRaw = await http.request("admin", "countries");
    setCountries(countriesRaw);
    let countryId = JSON.parse(localStorage.getItem("geolocation"))._id;
    let curCountry = countriesRaw.filter(
      (country) => country._id == countryId
    )[0];
    setCurCountry(curCountry);
  }

  const [tabs, setTabs] = useState([
    { name: "Personal Details", current: true },
    { name: "Contact", current: false },
    { name: "Contracts", current: false },
  ]);
  const [politicallyExposed, setPoliticallyExposed] = useState(false);
  const [mortgage, setMortgage] = useState(false);

  function selectTab(index) {
    console.log(index);
    let tempTabs = tabs;
    tempTabs[index].current = true;
    tempTabs[current].current = false;
    setTabs(tempTabs);
    setCurrent(index);
  }

  function deepEqual(obj1, obj2) {
    if (obj1 === obj2) {
      return false;
    }

    if (
      typeof obj1 !== "object" ||
      typeof obj2 !== "object" ||
      obj1 === null ||
      obj2 === null
    ) {
      return true;
    }

    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
      return true;
    }

    for (const key of keys1) {
      if (!keys2.includes(key)) {
        return true;
      }

      if (!deepEqual(obj1[key], obj2[key])) {
        return true;
      }
    }

    return false;
  }

  function nullTransform(val) {
    if (val === null || val === undefined) {
      return "";
    }
    return val;
  }

  async function cancelLoan(id) {
    try {
      Swal.fire({
        title: "Attention!",
        text: "Are you sure you want to cancel this loan\n!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, send it!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          let response = await Http.request(
            "contracts",
            `contracts/cancel/${id}`
          );
          Swal.fire(
            "Success!",
            "Loan has been cancelled successfully.",
            "success"
          ).then(() => {
            window.location.reload();
          });
        }
      });
    } catch (e) {
      console.log(e);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Failed to cancel loan!",
      });
    }
  }

  function handlePhoneNumberChange(e, type) {
    console.log(e.target.value);
    let formattedNumber = "";
    const value = e.target.value.replace(/\D/g, "");
    if (e.target.value.length <= 9) {
      formattedNumber = e.target.value;
    } else {
      formattedNumber = `(${value.slice(0, 3)}) ${value.slice(
        3,
        6
      )}-${value.slice(6)}`;
    }

    // Remove non-numeric characters
    setKeyContact(type, formattedNumber);
  }

  async function getContracts() {
    let url = window.location.href.split("/");

    try {
      let contracts = await http.request(
        "contracts",
        "contracts/clients/retail/get-contracts",
        {
          perPage: 5,
          page,
          clientId: url[url.length - 1],
        }
      );

      if (contracts.contracts.length > 0) {
        setContracts(contracts.contracts);
      }
    } catch (error) {
      console.log(error);
      swal.fire(
        "Oops",
        "An error has occurred trying to get contracts",
        "error"
      );
    }
  }

  function FormatNumber(str) {
    return str.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  async function getClient() {
    let dialog = swal.fire({
      title: "Loading...",
      timerProgressBar: true,
      allowOutsideClick: false,
      didOpen: () => {
        swal.showLoading();
      },
      willClose: () => {},
    });
    let url = window.location.href.split("/");

    let countries3 = await http.request("admin", "countries");
    setCountries2(countries3);

    let countryId = JSON.parse(localStorage.getItem("geolocation"))._id;
    let curCountry = countries3.filter(
      (country) => country._id == countryId
    )[0];
    setCurCountry(curCountry);

    let userData = await http.request("contracts", "clients/retail/get-one", {
      id: url[url.length - 1],
    });
    dialog.close();
    if (userData.error != null) {
      swal.fire("Oops", "An error has occured", "error");
    } else {
      console.log(userData);
      setUserInfo(userData);
      setUser(userData.personal);
      setUser2(userData.personal);
      setAddress(userData.address);
      setAddress2(userData.address);
      setContact(userData.contact);
      setContact2(userData.contact);
      setEmployment(userData.employment);
      setEmployment2(userData.employment);
      setPayment(userData.accounts);
      setPayment2(userData.accounts);
      setPoliticallyExposed(userData.personal.politicallyExposed);
      setMortgage(userData.personal.mortgage);
      setClientNumber(userData.clientNumber);
      let country = countries3.filter(
        (item) => item.code == userData.address.addressCountry
      )[0];

      setTempId(userData.personal.id);
      setTempIdType(userData.personal.idType);
      setExpiryDate(userData.personal.expiryDate);
      setIssuedDate(userData.personal.issuedDate);
    }
  }

  function getPaymentScheduleStatus(status) {
    const mainStyle =
      "inline-flex items-center rounded-full px-7 py-1 text-xs text-white";
    const st = {
      paid: {
        name: "Paid",
        style: mainStyle + " bg-lime-500",
      },
      late: {
        name: "Late",
        style: mainStyle + " bg-red-600",
      },
      due: {
        name: "Due",
        style: mainStyle + " bg-cyan-600",
      },
      scheduled: {
        name: "Scheduled",
        style: "inline-flex items-center text-sm text-gray-500",
      },
    };
    return st[status]
      ? st[status]
      : {
          name: "Scheduled",
          style: "inline-flex items-center text-sm text-gray-500",
        };
  }

  function setKey(key, value) {
    let object = { ...user };

    setDeepValue(object, value, key);
    setUser(object);
    setDirty(deepEqual(user, user2));
  }

  function setKeyContact(key, value) {
    let object = { ...contact };

    setDeepValue(object, value, key);
    setContact(object);
    setDirty(deepEqual(contact, contact2));
  }

  function setKeyAddress(key, value) {
    let object = { ...address };

    setDeepValue(object, value, key);
    setAddress(object);
    setDirty(deepEqual(address, address2));
  }

  function setStatus(key, value) {
    let object = { ...userInfo };

    setDeepValue(object, value, key);
    setUserInfo(object);
  }

  function setKeyEmployment(key, value) {
    let object = { ...employment };

    setDeepValue(object, value, key);
    setEmployment(object);
    setDirty(deepEqual(employment, employment2));
  }

  function setKeyPayment(key, value) {
    let object = { ...payment };

    setDeepValue(object, value, key);
    setPayment(object);
    setDirty(deepEqual(payment, payment2));
  }

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  function getContract(id) {
    if (id !== "" && id !== null && id !== undefined) {
      let c = contracts.filter((item) => item._id == id)[0]
      console.log({c})
      setCurrentContract(c);
      genLink(c)
    } else {
      setContractView(false);
    }
  }
  function genLink(c){
    let base = `https://portal.yoozit.today/quickpay/1/${getDeepValue(
        c,
        "loan.loanNumber"
    )}/0/${c.email}`

    let geo = JSON.parse(localStorage.getItem("geolocation"))
    if (geo.code === 'grd' || geo.code === "lca"){
      base = 'https://dtu.linkuptt.com/linkupv2/index.php/welcome'
    }
    setLink(base)
  }

  function back() {
    if (dirty == true) {
      swal
        .fire({
          title: "Are you sure? You are leaving the page without saving.",
          showCancelButton: true,
          showConfirmButton: true,
          confirmButtonText: "Leave Page",
        })
        .then((result) => {
          if (result.isConfirmed) {
            window.history.back();
          }
        });
    } else {
      window.history.back();
    }
  }

  function isValidEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  async function saveForm() {

    if (isValidEmail(contact.email)) {
      console.log('Valid email:', contact.email);
    } else {
      console.log('Invalid email:', contact.email);
      swal.fire({
        title: 'Oops',
        text: 'Please check the email address.',
        icon: 'info',
        showConfirmButton: true
      })
      return
    }

    let dialog = swal.fire({
      title: "Loading...",
      timerProgressBar: true,
      allowOutsideClick: false,
      didOpen: () => {
        swal.showLoading();
      },
      willClose: () => {},
    });
    let url = window.location.href.split("/");


    const selectdDay = new Date(user.expiryDate).setHours(0, 0, 0, 0);
    const today = new Date(new Date().setHours(0, 0, 0, 0));
    user["politicallyExposed"] = politicallyExposed;
    user["mortgage"] = mortgage;
    let retail = {
      status: userInfo.status,
      personal: user,
      address,
      employment,
      contact,
      accounts: payment,
      type: "retail",
      docId: url[url.length - 1],
    };
    await await http
      .request(`contracts`, "clients/retail/update-one", {
        id: window.location.href.split("/")[url.length - 1],
        retail,
      })
      .then((result) => {
        dialog.close();
        if (result.error) {
          swal.fire("Oops", "An error has occured", "error");
        } else {
          swal.fire("Client Information saved!", "", "success");
          setDirty(false);
        }
      })
      .catch((e) => {
        Swal.fire("Oops", "Failed to save client information", "error");
      });
    setOpen(false);
  }

  function editId() {
    setEditing(!editing);
  }

  function handleDate(e) {
    // var day = new Date(e.target.value).getUTCDay();
    // const selectdDay = new Date(e.target.value).setHours(0, 0, 0, 0);
    // const today = new Date(new Date().setHours(0, 0, 0, 0));
    // if (selectdDay < today) {
    //   document.getElementById("expiryDate").value = new Date()
    //     .toISOString()
    //     .split("T")[0];
    //   alert("Previous day is not allowed");
    // }
  }

  async function getInstallments(id) {
    try {
      let response = await http.request(
        "contracts",
        "/contracts/get-installments",
        { id }
      );
      console.log({ response });
      return response.installments;
    } catch (err) {
      console.log(err);
    }
  }

  function getBalance() {
    let balance = 0;
    let paid = currentContract?.loan?.installments?.reduce(
      (n, { principalPaid, interest }) => {
        return n + (principalPaid + interest);
      },
      0
    );
    balance = currentContract?.loan?.startingBalance - paid;
    return FormatNumber(balance.toFixed(2));
  }
  async function sendEmailInvite(){
    try {
      let url = window.location.href.split("/")
      let response = await http.request("contracts", `contracts/clients/verify/email`, {id: `${window.location.href.split("/")[url.length - 1]}`});
      swal.fire("Hurray!", "Verification code has been sent to the clients email", "success");
    }catch(e){
      console.log(e);
      swal.fire("Oops", "Failed to send email verification code.", "error");
    }
  }

  useEffect(() => {
    getClient();
    setKeyAddress(
      "addressCountry",
      JSON.parse(localStorage.getItem("geolocation")).code
    );
    getContracts();
  }, []);

  return (
    <div className="flex justify-center ">
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={setOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div>
                    <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-blue-100">
                      <PencilSquareIcon
                        className="h-6 w-6 text-white-600"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900"
                      >
                        Manage Retail Client
                      </Dialog.Title>

                      {curAction == "user" &&
                        curField != "status" &&
                        curField != "dateOfBirth" &&
                        curField != "id" && (
                          <div className="block rounded-lg bg-gray-200 p-6 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] ">
                            <label className="block text-sm font-medium leading-6 text-gray-900">
                              {camelCaseToWords(curField)}
                            </label>
                            <input
                              type="text"
                              onChange={(e) => {
                                setKey(curField, e.target.value);
                              }}
                              value={user[curField]}
                              className="mt-2 block w-full rounded-md border-1 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                          </div>
                        )}

                      {curAction == "user" && curField == "dateOfBirth" && (
                        <div className="block rounded-lg bg-gray-200 p-6 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] ">
                          <label className="block text-sm font-medium leading-6 text-gray-900">
                            {camelCaseToWords(curField)}
                          </label>
                          <input
                            type="date"
                            onChange={(e) => {
                              setKey(curField, e.target.value);
                            }}
                            value={user[curField]}
                            className="mt-2 block w-full rounded-md border-1 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                        </div>
                      )}

                      {curAction == "user" && curField == "id" && (
                        <div>
                          <div className="mt-2 block rounded-lg bg-gray-200 p-6 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] ">
                            <label className="block text-sm font-medium leading-6 text-gray-900">
                              ID Information
                            </label>
                            <input
                              type="text"
                              onChange={(e) => {
                                setKey("id", e.target.value);
                              }}
                              value={user[curField]}
                              className="mt-2 block w-full rounded-md border-1 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                          </div>
                          <div className="mt-2 block rounded-lg bg-gray-200 p-6 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] ">
                            <label className="block text-sm font-medium leading-6 text-gray-900">
                              Issued Date
                            </label>
                            <input
                              type="date"
                              onChange={(e) => {
                                setKey("issuedDate", e.target.value);
                              }}
                              value={user.issuedDate}
                              className="mt-2 block w-full rounded-md border-1 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                          </div>
                          <div className="mt-2 block rounded-lg bg-gray-200 p-6 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] ">
                            <label className="block text-sm font-medium leading-6 text-gray-900">
                              Expiry Date
                            </label>
                            <input
                              type="date"
                              id="expiryDate"
                              onChange={(e) => {
                                setKey("expiryDate", e.target.value);
                              }}
                              value={user.expiryDate}
                              className="mt-2 block w-full rounded-md border-1 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                          </div>

                          <div className="mt-2 block rounded-lg bg-gray-200 p-6 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] ">
                            <label className="block text-sm font-medium leading-6 text-gray-900">
                              ID Type
                            </label>
                            <select
                              required
                              onChange={(e) => {
                                setKey("idType", e.target.value);
                              }}
                              value={user.idType}
                              className="mt-2 block w-full rounded-md border-1 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            >
                              <option value=""> Select ID Type</option>
                              <option value="National ID">National ID</option>
                              <option value="Driver's Licenses">
                                Driver's Licenses
                              </option>
                              <option value="Passport">Passport</option>
                            </select>
                          </div>
                        </div>
                      )}

                      {curAction == "address" &&
                        curField != "addressCountry" && (
                          <div className="block rounded-lg bg-gray-200 p-6 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] ">
                            <label className="block text-sm font-medium leading-6 text-gray-900">
                              {camelCaseToWords(curField)}
                            </label>
                            <input
                              type="text"
                              onChange={(e) => {
                                setKeyAddress(curField, e.target.value);
                              }}
                              value={address[curField]}
                              className="mt-2 block w-full rounded-md border-1 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                          </div>
                        )}

                      {curAction == "employment" && (
                        <div className="block rounded-lg bg-gray-200 p-6 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] ">
                          <label className="block text-sm font-medium leading-6 text-gray-900">
                            {camelCaseToWords(curField)}
                          </label>
                          <input
                            type="text"
                            onChange={(e) => {
                              setKeyEmployment(curField, e.target.value);
                            }}
                            value={employment[curField]}
                            className="mt-2 block w-full rounded-md border-1 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                        </div>
                      )}

                      {curAction == "contact" &&
                        !(
                          curField == "mobilePhone" ||
                          curField == "housePhone" ||
                          curField == "workPhone"
                        ) && (
                          <div className="block rounded-lg bg-gray-200 p-6 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] ">
                            <label className="block text-sm font-medium leading-6 text-gray-900">
                              {camelCaseToWords(curField)}
                            </label>
                            <input
                              type="text"
                              onChange={(e) => {
                                setKeyContact(curField, e.target.value);
                              }}
                              value={contact[curField]}
                              className="mt-2 block w-full rounded-md border-1 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                          </div>
                        )}

                      {curAction == "contact" &&
                        (curField == "mobilePhone" ||
                          curField == "housePhone" ||
                          curField == "workPhone") && (
                          <div className="block rounded-lg bg-gray-200 p-6 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] ">
                            <label className="block text-sm font-medium leading-6 text-gray-900">
                              {camelCaseToWords(curField)}
                            </label>
                            <TelephoneInput
                              setKey={setKeyContact}
                              path={curField}
                              value={contact[curField]}
                              className={
                                "mt-2 block w-full rounded-md border-1 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              }
                            />
                          </div>
                        )}

                      {curAction == "user" && curField == "status" && (
                        <div className="block rounded-lg bg-gray-200 p-6 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] ">
                          <label className="block text-sm font-medium leading-6 text-gray-900">
                            {camelCaseToWords(curField)}
                          </label>
                          <select
                            required
                            onChange={(e) => {
                              setStatus("status", e.target.value);
                            }}
                            value={userInfo.status}
                            className="mt-2 block w-full rounded-md border-1 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          >
                            <option value="">Select Status</option>
                            <option value="active">Active</option>
                            <option value="disabled">Disabled</option>
                          </select>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                    <AccessControl
                      permission={"updateCommercialClient"}
                      fallback={<></>}
                      target={["god"]}
                    >
                      <button
                        type="button"
                        className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                        onClick={() => saveForm()}
                      >
                        Save
                      </button>
                    </AccessControl>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                      onClick={() => {
                        setOpen(false);
                        getClient();
                      }}
                      ref={cancelButtonRef}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <div className="space-y-6 sm:px-6 lg:col-span-9 lg:px-0 w-full">
        <div className="shadow sm:overflow-hidden sm:rounded-md">
          <div className="space-y-6 bg-white px-4 py-6 sm:p-6">
            <h1 className="text-4xl text-center font-semibold  leading-6 text-gray-900">
              Retail Client Profile
            </h1>
            <hr />
            <div className="grid grid-cols-12 gap-1">
              <div
                className="w-1/12"
                style={{
                  border: "1px solid black",
                  borderRadius: "12%",
                  height: "64px",
                  width: "64px",
                }}
              >
                <img style={{}} src="/avatar4.png" alt="Solis Credit" />
              </div>
              <div className="col-span-10">
                <div className="grid grid-rows-2 grid-flow-col">
                  <div className="row-span-1 text-xl mb-1 ">
                    {user.firstname} {user.lastname}
                  </div>
                  <div className="grid grid-cols-11 gap-1 row-span-1">
                    <div className="lg:col-span-2 sm:col-span-11 ml-1">
                      <div className="flex items-center">
                        {address.addressCountry && (
                          <img
                            className="mr-2"
                            style={{ width: "35px", height: "19px" }}
                            src={findFlagUrlByIso3Code(address.addressCountry)}
                          />
                        )}
                        <b
                          style={{ marginLeft: "15px;" }}
                          class="text-sm ml-2"
                          className="col-span-3 text-lg "
                        >
                          {clientNumber}
                        </b>
                      </div>
                    </div>

                    <div className="lg:col-span-3 sm:col-span-11 text-sm  mt-1">
                      <b>Email:</b> {contact.email}
                    </div>
                    <div className="lg:col-span-4 sm:col-span-11 text-sm mt-1">
                      <b>{user.idType}: </b> {user.id}
                    </div>
                    <div className="lg:col-span-2 sm:col-span-11 text-sm  mt-1">
                      <b>Phone: </b> {formatPhoneNumber(contact.mobilePhone)}
                    </div>
                  </div>
                </div>
                <div className=" flex">
                  <p className="col-span-1 mr-2">Status:</p>
                  {userInfo.status == "active" && (
                    <>
                      <p className="col-span-2 text-green-700 bg-green-200 ring-green-600/10 rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset">
                        {userInfo.status}
                      </p>
                      <span>
                        <AccessControl
                          permission={"changeClientStatus"}
                          fallback={<></>}
                          target={["god"]}
                        >
                          <svg
                            onClick={() => {
                              setOpen(true);
                              setCurField("status");
                              setCurAction("user");
                            }}
                            xmlns="http://www.w3.org/2000/svg"
                            className="grid-cols-1 ml-3 mt-1 h-4 w-4 cursor-pointer hover:red-500"
                            viewBox="0 0 512 512"
                          >
                            <path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z" />
                          </svg>
                        </AccessControl>
                      </span>
                    </>
                  )}
                  {userInfo.status == "disabled" && (
                    <>
                      <p className="col-span-2 text-red-700 bg-red-200 ring-red-600/10 rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset">
                        {userInfo.status}
                      </p>
                      <span>
                        <AccessControl
                          permission={"changeClientStatus"}
                          fallback={<></>}
                          target={["god"]}
                        >
                          <svg
                            onClick={() => {
                              setOpen(true);
                              setCurField("status");
                              setCurAction("user");
                            }}
                            xmlns="http://www.w3.org/2000/svg"
                            className="grid-cols-1 ml-3 mt-1 h-4 w-4 cursor-pointer hover:red-500"
                            viewBox="0 0 512 512"
                          >
                            <path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z" />
                          </svg>
                        </AccessControl>
                      </span>
                    </>
                  )}
                </div>
                {process.env.REACT_APP_USE_SMS === 'true' && (
                    <div className="flex mt-2">
                      <p className="col-span-1 mr-2">Verified:</p>
                      <>
                    <span
                        className="inline-flex items-center gap-x-1.5 rounded-full px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200">
                      <svg
                          className={
                              "h-1.5 w-1.5 " +
                              (userInfo?.emailVerified === true
                                  ? "fill-green-500"
                                  : "fill-red-500")
                          }
                          viewBox="0 0 6 6"
                          aria-hidden="true"
                      >
                        <circle cx={3} cy={3} r={3}/>
                      </svg>
                      Email
                    </span>
                        <span
                            className="mx-2 inline-flex items-center gap-x-1.5 rounded-full px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200">
                      <svg
                          className={
                              "h-1.5 w-1.5 " +
                              (userInfo?.smsVerified === true
                                  ? "fill-green-500"
                                  : "fill-red-500")
                          }
                          viewBox="0 0 6 6"
                          aria-hidden="true"
                      >
                        <circle cx={3} cy={3} r={3}/>
                      </svg>
                      Telephone
                    </span>
                        <span>
                      {userInfo?.emailVerified !== true && (
                          <AccessControl
                              permission={"sendEmailVerification"}
                              fallback={<></>}
                              target={["god"]}
                          >
                            <button
                                onClick={() => sendEmailInvite()}
                                type="button"
                                className="inline-flex items-center gap-x-1.5 rounded-md bg-green-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                            >
                              Verify Email
                              <EnvelopeIcon
                                  aria-hidden="true"
                                  className="-mr-0.5 h-5 w-5"
                              />
                            </button>
                          </AccessControl>
                      )}
                    </span>
                      </>
                    </div>
                )
                }

              </div>
            </div>

            <div className="border-b border-gray-200 pb-5 sm:pb-0 p-5">
              <div className="mt-3 sm:mt-4">
                <div className="sm:hidden">
                  <label htmlFor="current-tab" className="sr-only">
                    Select a tab
                  </label>
                  <select
                      id="current-tab"
                      name="current-tab"
                      className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      defaultValue={tabs[current].name}
                      onChange={(e) => selectTab(e.target.value)}
                  >
                    {tabs.map((tab, index) => (
                        <option value={index}>{tab.name}</option>
                    ))}
                  </select>
                </div>
                <div className="hidden sm:block">
                  <nav className="-mb-px flex space-x-8">
                    {tabs.map((tab, index) => (
                        <a
                            key={tab.name}
                            onClick={() => selectTab(index)}
                            style={{cursor: "pointer"}}
                            className={classNames(
                                tab.current
                                    ? "border-indigo-500 text-indigo-600"
                                    : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                                "whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium"
                            )}
                            aria-current={tab.current ? "page" : undefined}
                        >
                          {tab.name}
                        </a>
                    ))}
                  </nav>
                </div>
              </div>
            </div>

            <div className="pl-5 pr-5 pb-5">
              {current == 0 && (
                  <div>
                    <div>
                      <h2 className="text-base font-semibold leading-6 text-gray-900 mb-5">
                        Click on a value to change
                      </h2>

                      <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-6 ">
                        <div className="lg:col-span-1 md:col-span-3 sm:col-span-3">
                          <label className="block text-sm font-medium leading-6 text-gray-900 ml-3">
                            First Name
                          </label>
                          <div className="grid grid-cols-3">
                            <input
                                type="text"
                            onChange={(e) => {
                              setKey("firstname", e.target.value);
                            }}
                            disabled
                            value={user.firstname}
                            className="mt-2 col-span-2 block w-full rounded-md border-1 text-gray-900 ring-inset ring-gray-300 placeholder:text-gray-400  focus:outline-none sm:text-sm sm:leading-6"
                          />
                          <div className="">
                            <svg
                              onClick={() => {
                                setOpen(true);
                                setCurField("firstname");
                                setCurAction("user");
                              }}
                              xmlns="http://www.w3.org/2000/svg"
                              className="grid-cols-1 ml-10 h-4 w-4 cursor-pointer hover:red-500 mt-5"
                              viewBox="0 0 512 512"
                            >
                              <path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z" />
                            </svg>
                          </div>
                        </div>
                      </div>

                      <div className="lg:col-span-1 md:col-span-3 sm:col-span-3">
                        <label className="block text-sm font-medium leading-6 text-gray-900 ml-3">
                          Middle Name
                        </label>
                        <div className="grid grid-cols-3">
                          <input
                            type="text"
                            disabled
                            onChange={(e) => {
                              setKey("middlename", e.target.value);
                            }}
                            value={user.middlename}
                            className="mt-2 col-span-2 block w-full rounded-md border-1 px-3 py-1.5 text-gray-900  ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                          <div className="">
                            <svg
                              onClick={() => {
                                setOpen(true);
                                setCurField("middlename");
                                setCurAction("user");
                              }}
                              xmlns="http://www.w3.org/2000/svg"
                              className="grid-cols-1 ml-10 h-4 w-4 cursor-pointer hover:red-500 mt-5"
                              viewBox="0 0 512 512"
                            >
                              <path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z" />
                            </svg>
                          </div>
                        </div>
                      </div>

                      <div className="lg:col-span-1 md:col-span-3 sm:col-span-3">
                        <label className="block text-sm font-medium leading-6 text-gray-900 ml-3">
                          Last Name
                        </label>
                        <div className="grid grid-cols-3">
                          <input
                            type="text"
                            disabled
                            value={user.lastname}
                            onChange={(e) => {
                              setKey("lastname", e.target.value);
                            }}
                            className="mt-2 block col-span-2 w-full rounded-md border-1 px-3 py-1.5 text-gray-900  ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                          <div className="">
                            <svg
                              onClick={() => {
                                setOpen(true);
                                setCurField("lastname");
                                setCurAction("user");
                              }}
                              xmlns="http://www.w3.org/2000/svg"
                              className="grid-cols-1 ml-10 h-4 w-4 cursor-pointer hover:red-500 mt-5"
                              viewBox="0 0 512 512"
                            >
                              <path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z" />
                            </svg>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-6 mt-5">
                      <div className="lg:col-span-1 md:col-span-3 sm:col-span-3">
                        <label className="block text-sm font-medium leading-6 text-gray-900 ml-3">
                          Date of Birth
                        </label>
                        <div className="grid grid-cols-3">
                          <input
                            type="text"
                            disabled
                            onChange={(e) => {
                              setKey("dateOfBirth", e.target.value);
                            }}
                            value={user.dateOfBirth}
                            className="mt-2 block w-full col-span-2 rounded-md border-1 px-3 py-1.5 text-gray-900  ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                          <div className="">
                            <svg
                              onClick={() => {
                                setOpen(true);
                                setCurField("dateOfBirth");
                                setCurAction("user");
                              }}
                              xmlns="http://www.w3.org/2000/svg"
                              className="grid-cols-1 ml-10 h-4 w-4 cursor-pointer hover:red-500 mt-5"
                              viewBox="0 0 512 512"
                            >
                              <path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z" />
                            </svg>
                          </div>
                        </div>
                      </div>

                      {curCountry.code == "lca" && (
                        <div className="lg:col-span-1 md:col-span-3 sm:col-span-3">
                          <label className="block text-sm font-medium leading-6 text-gray-900 ml-3">
                            Place of Birth
                          </label>
                          <div className="grid grid-cols-3">
                            <input
                              type="text"
                              disabled
                              onChange={(e) => {
                                setKey("placeOfBirth", e.target.value);
                              }}
                              value={user.placeOfBirth}
                              className="mt-2 col-span-2 block w-full rounded-md border-1 px-3 py-1.5 text-gray-900  ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                            <div className="">
                              <svg
                                onClick={() => {
                                  setOpen(true);
                                  setCurField("placeOfBirth");
                                  setCurAction("user");
                                }}
                                xmlns="http://www.w3.org/2000/svg"
                                className="grid-cols-1 ml-10 h-4 w-4 cursor-pointer hover:red-500 mt-5"
                                viewBox="0 0 512 512"
                              >
                                <path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z" />
                              </svg>
                            </div>
                          </div>
                        </div>
                      )}

                      {curCountry.code == "lca" && (
                        <div className="lg:col-span-1 md:col-span-3 sm:col-span-3">
                          <label className="block text-sm font-medium leading-6 text-gray-900 ml-3">
                            Nationality
                          </label>
                          <div className="grid grid-cols-3">
                            <input
                              type="text"
                              disabled
                              onChange={(e) => {
                                setKey("nationality", e.target.value);
                              }}
                              value={user.nationality}
                              className="mt-2 col-span-2 block w-full rounded-md border-1 px-3 py-1.5 text-gray-900  ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                            <div className="">
                              <svg
                                onClick={() => {
                                  setOpen(true);
                                  setCurField("nationality");
                                  setCurAction("user");
                                }}
                                xmlns="http://www.w3.org/2000/svg"
                                className="grid-cols-1 ml-10 h-4 w-4 cursor-pointer hover:red-500 mt-5"
                                viewBox="0 0 512 512"
                              >
                                <path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z" />
                              </svg>
                            </div>
                          </div>
                        </div>
                      )}

                      {/* <div className="lg:col-span-1 md:col-span-3 sm:col-span-3">
                        <label className="block text-sm font-medium leading-6 text-gray-900 ml-3">
                          Nationality
                        </label>
                        <select
                          required
                          onChange={(e) => {
                            setKey("nationality", e.target.value);
                          }}
                          value={user.nationality}
                          className="mt-2 block w-full rounded-md border-1 px-3 py-1.5 text-gray-900  ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        >
                          <option value="">Select Nationality</option>
                          {countries2.map((item) => (
                            <option key={item.code} value={item.code}>
                              {item.title}
                            </option>
                          ))}{" "}
                        </select>
                      </div> */}

                      <div className="lg:col-span-1 md:col-span-3 sm:col-span-3">
                        <label className="block text-sm font-medium leading-6 text-gray-900 ml-3">
                          ID {user.idType}{" "}
                        </label>
                        <div className="grid grid-cols-3">
                          <input
                            type="text"
                            disabled={true}
                            value={`${user.id} / Expires ${user.expiryDate}`}
                            className="mt-2 block col-span-2 w-full rounded-md border-1 px-3 py-1.5 text-gray-900  ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                          <div className="">
                            <svg
                              onClick={() => {
                                setOpen(true);
                                setCurField("id");
                                setCurAction("user");
                              }}
                              xmlns="http://www.w3.org/2000/svg"
                              className="grid-cols-1 ml-10 h-4 w-4 cursor-pointer hover:red-500 mt-5"
                              viewBox="0 0 512 512"
                            >
                              <path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z" />
                            </svg>
                          </div>
                        </div>
                      </div>

                      {editing == true && (
                        <div className="lg:col-span-1 md:col-span-3 sm:col-span-3">
                          <label className="block text-sm font-medium leading-6 text-gray-900 ml-3">
                            ID
                          </label>
                          <input
                            type="text"
                            disabled
                            onChange={(e) => {
                              setKey("id", e.target.value);
                            }}
                            value={user.id}
                            className="mt-2 block w-full rounded-md border-1 px-3 py-1.5 text-gray-900  ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                        </div>
                      )}

                      {editing == true && (
                        <div className="lg:col-span-1 md:col-span-3 sm:col-span-3">
                          <label className="block text-sm font-medium leading-6 text-gray-900 ml-3">
                            Issued Date
                          </label>
                          <input
                            type="date"
                            disabled
                            onChange={(e) => {
                              setKey("issuedDate", e.target.value);
                            }}
                            value={user.issuedDate}
                            className="mt-2 block w-full rounded-md border-1 px-3 py-1.5 text-gray-900  ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                        </div>
                      )}

                      {editing == true && (
                        <div className="lg:col-span-1 md:col-span-3 sm:col-span-3">
                          <label className="block text-sm font-medium leading-6 text-gray-900 ml-3">
                            Expiry Date
                          </label>
                          <input
                            type="date"
                            disabled
                            onChange={(e) => {
                              setKey("expiryDate", e.target.value);
                            }}
                            value={user.expiryDate}
                            className="mt-2 block w-full rounded-md border-1 px-3 py-1.5 text-gray-900  ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                        </div>
                      )}
                    </div>

                    {curCountry.code == "lca" && (
                      <div className="col-span-4 sm:col-span-4 mt-5">
                        <div className="flex items-center">
                          <label className="block text-sm font-medium leading-6 text-gray-900 mr-2 ml-3">
                            Politically Exposed Person
                          </label>
                          <input
                            type="checkbox"
                            onChange={(e) => {
                              setPoliticallyExposed(e.target.checked);
                            }}
                            checked={politicallyExposed}
                            className=" block rounded-md px-3 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                        </div>
                      </div>
                    )}

                    <div>
                      <div>
                        <h2 className="text-2xl ml-2 mb-5 mt-10 mb-10">
                          Address
                        </h2>
                      </div>
                      <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-6">
                        <div className="lg:col-span-1 md:col-span-3 sm:col-span-3">
                          <label className="block text-sm font-medium leading-6 text-gray-900 ml-3">
                            Address - First Line
                          </label>
                          <div className="grid grid-cols-3">
                            <input
                              type="text"
                              disabled
                              onChange={(e) => {
                                setKeyAddress(
                                  "addressFirstLine",
                                  e.target.value
                                );
                              }}
                              value={address.addressFirstLine}
                              className="mt-2 col-span-2 block w-full rounded-md border-1 px-3 py-1.5 text-gray-900  ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                            <div className="">
                              <svg
                                onClick={() => {
                                  setOpen(true);
                                  setCurField("addressFirstLine");
                                  setCurAction("address");
                                }}
                                xmlns="http://www.w3.org/2000/svg"
                                className="grid-cols-1 ml-10 h-4 w-4 cursor-pointer hover:red-500 mt-5"
                                viewBox="0 0 512 512"
                              >
                                <path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z" />
                              </svg>
                            </div>
                          </div>
                        </div>

                        <div className="lg:col-span-1 md:col-span-3 sm:col-span-3">
                          <label className="block text-sm font-medium leading-6 text-gray-900 ml-3">
                            Address - Second Line
                          </label>
                          <div className="grid grid-cols-3">
                            <input
                              type="text"
                              disabled
                              onChange={(e) => {
                                setKeyAddress(
                                  "addressSecondLine",
                                  e.target.value
                                );
                              }}
                              value={address.addressSecondLine}
                              className="mt-2 col-span-2 block w-full rounded-md border-1 px-3 py-1.5 text-gray-900  ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                            <div className="">
                              <svg
                                onClick={() => {
                                  setOpen(true);
                                  setCurField("addressSecondLine");
                                  setCurAction("address");
                                }}
                                xmlns="http://www.w3.org/2000/svg"
                                className="grid-cols-1 ml-10 h-4 w-4 cursor-pointer hover:red-500 mt-5"
                                viewBox="0 0 512 512"
                              >
                                <path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z" />
                              </svg>
                            </div>
                          </div>
                        </div>

                        <div className="lg:col-span-1 md:col-span-3 sm:col-span-3">
                          <label className="block text-sm font-medium leading-6 text-gray-900 ml-3">
                            Address - State or Province*
                          </label>
                          <div className="grid grid-cols-3">
                            <input
                              type="text"
                              disabled
                              onChange={(e) => {
                                setKeyAddress(
                                  "addressStateOrProvince",
                                  e.target.value
                                );
                              }}
                              value={address.addressStateOrProvince}
                              className="mt-2 block col-span-2 w-full rounded-md border-1 px-3 py-1.5 text-gray-900  ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                            <div className="">
                              <svg
                                onClick={() => {
                                  setOpen(true);
                                  setCurField("addressStateOrProvince");
                                  setCurAction("address");
                                }}
                                xmlns="http://www.w3.org/2000/svg"
                                className="grid-cols-1 ml-10 h-4 w-4 cursor-pointer hover:red-500 mt-5"
                                viewBox="0 0 512 512"
                              >
                                <path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z" />
                              </svg>
                            </div>
                          </div>
                        </div>

                        <div className="lg:col-span-1 md:col-span-3 sm:col-span-3">
                          <label className="block text-sm font-medium leading-6 text-gray-900 ml-3">
                            Address - City or Town
                          </label>
                          <div className="grid grid-cols-3">
                            <input
                              type="text"
                              disabled
                              onChange={(e) => {
                                setKeyAddress(
                                  "addressCityOrTown",
                                  e.target.value
                                );
                              }}
                              value={address.addressCityOrTown}
                              className="mt-2 block col-span-2 w-full rounded-md border-1 px-3 py-1.5 text-gray-900  ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                            <div className="">
                              <svg
                                onClick={() => {
                                  setOpen(true);
                                  setCurField("addressCityOrTown");
                                  setCurAction("address");
                                }}
                                xmlns="http://www.w3.org/2000/svg"
                                className="grid-cols-1 ml-10 h-4 w-4 cursor-pointer hover:red-500 mt-5"
                                viewBox="0 0 512 512"
                              >
                                <path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z" />
                              </svg>
                            </div>
                          </div>
                        </div>

                        <div className="lg:col-span-1 md:col-span-3 sm:col-span-3">
                          <label className="block text-sm font-medium leading-6 text-gray-900 ml-3">
                            Country
                          </label>
                          <div className="grid grid-cols-3">
                            <select
                              required
                              disabled
                              onChange={(e) => {
                                setKeyAddress("addressCountry", e.target.value);
                              }}
                              value={address.addressCountry}
                              className="mt-2 block w-full col-span-2 rounded-md border-1 px-3 py-1.5 text-gray-900  ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            >
                              <option value="">Select Country</option>
                              {countries2.map((item) => (
                                <option key={item.code} value={item.code}>
                                  {item.title}
                                </option>
                              ))}
                            </select>
                            {/* <div className="">
                              <svg
                                onClick={() => {
                                  setOpen(true);
                                  setCurField("addressCountry");
                                  setCurAction("address");
                                }}
                                xmlns="http://www.w3.org/2000/svg"
                                className="grid-cols-1 ml-10 h-4 w-4 cursor-pointer hover:red-500 mt-5"
                                viewBox="0 0 512 512"
                              >
                                <path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z" />
                              </svg>
                            </div> */}
                          </div>
                        </div>

                        {/* <div className="lg:col-span-1 md:col-span-3 sm:col-span-3">
                          <label className="block text-sm font-medium leading-6 text-gray-900 ml-3">
                            Country
                          </label>
                          <select
                            required
                            onChange={(e) => {
                              setKeyAddress("addressCountry", e.target.value);
                            }}
                            value={address.addressCountry}
                            className="mt-2 block w-full rounded-md border-1 px-3 py-1.5 text-gray-900  ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          >
                            <option value="">Select Country</option>
                            {countries2.map((item) => (
                              <option key={item.code} value={item.code}>
                                {item.title}
                              </option>
                            ))}
                          </select>
                        </div> */}
                      </div>
                    </div>

                    {curCountry.code == "lca" && (
                      <div>
                        <div>
                          <h2 className="text-2xl ml-2 mb-5 mt-10 mb-10">
                            Employment
                          </h2>
                        </div>
                        <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-6">
                          <div className="lg:col-span-1 md:col-span-3 sm:col-span-3">
                            <label className="block text-sm font-medium leading-6 text-gray-900 ml-3">
                              Occupation
                            </label>
                            <div className="grid grid-cols-3">
                              <input
                                type="text"
                                disabled
                                onChange={(e) => {
                                  setKeyEmployment(
                                    "occupation",
                                    e.target.value
                                  );
                                }}
                                value={employment.occupation}
                                className="mt-2 block col-span-2 w-full rounded-md border-1 px-3 py-1.5 text-gray-900  ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              />
                              <div className="">
                                <svg
                                  onClick={() => {
                                    setOpen(true);
                                    setCurField("occupation");
                                    setCurAction("employment");
                                  }}
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="grid-cols-1 ml-10 h-4 w-4 cursor-pointer hover:red-500 mt-5"
                                  viewBox="0 0 512 512"
                                >
                                  <path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z" />
                                </svg>
                              </div>
                            </div>
                          </div>

                          <div className="lg:col-span-1 md:col-span-3 sm:col-span-3">
                            <label className="block text-sm font-medium leading-6 text-gray-900 ml-3">
                              Employer
                            </label>
                            <div className="grid grid-cols-3">
                              <input
                                type="text"
                                disabled
                                onChange={(e) => {
                                  setKeyEmployment("employer", e.target.value);
                                }}
                                value={employment.employer}
                                className="mt-2 block w-full col-span-2 rounded-md border-1 px-3 py-1.5 text-gray-900  ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              />
                              <div className="">
                                <svg
                                  onClick={() => {
                                    setOpen(true);
                                    setCurField("employer");
                                    setCurAction("employment");
                                  }}
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="grid-cols-1 ml-10 h-4 w-4 cursor-pointer hover:red-500 mt-5"
                                  viewBox="0 0 512 512"
                                >
                                  <path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z" />
                                </svg>
                              </div>
                            </div>
                          </div>

                          <div className="lg:col-span-1 md:col-span-3 sm:col-span-3">
                            <label className="block text-sm font-medium leading-6 text-gray-900 ml-3">
                              Position
                            </label>
                            <div className="grid grid-cols-3">
                              <input
                                type="text"
                                disabled
                                onChange={(e) => {
                                  setKeyEmployment("position", e.target.value);
                                }}
                                value={employment.position}
                                className="mt-2 block w-full col-span-2 rounded-md border-1 px-3 py-1.5 text-gray-900  ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              />
                              <div className="">
                                <svg
                                  onClick={() => {
                                    setOpen(true);
                                    setCurField("position");
                                    setCurAction("employment");
                                  }}
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="grid-cols-1 ml-10 h-4 w-4 cursor-pointer hover:red-500 mt-5"
                                  viewBox="0 0 512 512"
                                >
                                  <path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z" />
                                </svg>
                              </div>
                            </div>
                          </div>

                          <div className="lg:col-span-1 md:col-span-3 sm:col-span-3">
                            <label className="block text-sm font-medium leading-6 text-gray-900 ml-3">
                              Source of Wealth
                            </label>
                            <div className="grid grid-cols-3">
                              <input
                                type="text"
                                onChange={(e) => {
                                  setKeyEmployment(
                                    "sourceOfWealth",
                                    e.target.value
                                  );
                                }}
                                value={employment.sourceOfWealth}
                                className="mt-2 block w-full col-span-2 rounded-md border-1 px-3 py-1.5 text-gray-900  ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              />
                              <div className="">
                                <svg
                                  onClick={() => {
                                    setOpen(true);
                                    setCurField("sourceOfWealth");
                                    setCurAction("employment");
                                  }}
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="grid-cols-1 ml-10 h-4 w-4 cursor-pointer hover:red-500 mt-5"
                                  viewBox="0 0 512 512"
                                >
                                  <path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z" />
                                </svg>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    {/* <div>
                      <div>
                        <h2 className="text-2xl ml-2 mb-5 mt-10">Payment Information</h2>
                      </div>
                      <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-6">
                        <div className="lg:col-span-1 md:col-span-3 sm:col-span-3">
                          <label className="block text-sm font-medium leading-6 text-gray-900 ml-3">
                            Payment Type
                          </label>
                          <select
                            required
                            onChange={(e) => {
                              setKeyPayment("paymentType", e.target.value);
                            }}
                            value={payment.paymentType}
                            className="mt-2 block w-full rounded-md border-1 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          >
                            <option value="">Select Type</option>
                            <option value="bank">Bank</option>
                            <option value="cash">Cash</option>
                          </select>
                        </div>

                        {payment.paymentType == 'bank' && <div className="lg:col-span-1 md:col-span-3 sm:col-span-3">
                          <label className="block text-sm font-medium leading-6 text-gray-900 ml-3">
                            Bank Name
                          </label>
                          <input
                            required
                            type="text"
                            onChange={(e) => {
                              setKeyPayment("bankName", e.target.value);
                            }}
                            value={employment.employmer}
                            className="mt-2 block w-full rounded-md border-1 px-3 py-1.5 text-gray-900  ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                        </div>}


                        {payment.paymentType == 'bank' && <div className="lg:col-span-1 md:col-span-3 sm:col-span-3">
                          <label className="block text-sm font-medium leading-6 text-gray-900 ml-3">
                            Bank Account Number
                          </label>
                          <input
                            type="text"
                            onChange={(e) => {
                              setKeyPayment(
                                "bankAccountNumber",
                                e.target.value
                              );
                            }}
                            value={payment.bankAccountNumber}
                            className="mt-2 block w-full rounded-md border-1 px-3 py-1.5 text-gray-900  ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                        </div>}

                        {payment.paymentType == 'bank' &&
                          <div className="lg:col-span-1 md:col-span-3 sm:col-span-3">
                            <label className="block text-sm font-medium leading-6 text-gray-900 ml-3">
                              Account Type
                            </label>
                            <select
                              className="mt-2 block w-full rounded-md border-1 px-3 py-1.5 text-gray-900  ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              onChange={(e) => {
                                setKeyPayment("accountType", e.target.value);
                              }}
                            >
                              <option key={"savings"}>Savings</option>
                              <option key={"chequing"}>Chequing</option>
                            </select>
                          </div>}
                      </div>
                    </div> */}
                  </div>
                </div>
              )}

              {current == 1 && (
                <div>
                  <div>
                    <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-6 mt-5">
                      <div className="lg:col-span-1 md:col-span-3 sm:col-span-3">
                        <label className="block text-sm font-medium leading-6 text-gray-900 ml-3">
                          Email
                        </label>
                        <div className="grid grid-cols-3">
                          <input
                            type="text"
                            value={contact.email}
                            disabled
                            onChange={(e) => {
                              setKeyContact("email", e.target.value);
                            }}
                            className="mt-2 block w-full col-span-2 rounded-md border-1 px-3 py-1.5 text-gray-900  ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                          <div className="">
                            <svg
                              onClick={() => {
                                setOpen(true);
                                setCurField("email");
                                setCurAction("contact");
                              }}
                              xmlns="http://www.w3.org/2000/svg"
                              className="grid-cols-1 ml-10 h-4 w-4 cursor-pointer hover:red-500 mt-5"
                              viewBox="0 0 512 512"
                            >
                              <path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z" />
                            </svg>
                          </div>
                        </div>
                      </div>

                      <div className="lg:col-span-1 md:col-span-3 sm:col-span-3">
                        <label className="block text-sm font-medium leading-6 text-gray-900 ml-3">
                          Phone (M)
                        </label>
                        <div className="grid grid-cols-3">
                          <TelephoneInput
                            setKey={setKeyContact}
                            path={"mobilePhone"}
                            value={contact.mobilePhone}
                            className={
                              "mt-2 block w-full col-span-2 rounded-md border-1 px-3 py-1.5 text-gray-900  ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            }
                          />
                          <div className="">
                            <svg
                              onClick={() => {
                                setOpen(true);
                                setCurField("mobilePhone");
                                setCurAction("contact");
                              }}
                              xmlns="http://www.w3.org/2000/svg"
                              className="grid-cols-1 ml-10 h-4 w-4 cursor-pointer hover:red-500 mt-5"
                              viewBox="0 0 512 512"
                            >
                              <path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z" />
                            </svg>
                          </div>
                        </div>
                      </div>

                      <div className="lg:col-span-1 md:col-span-3 sm:col-span-3">
                        <label className="block text-sm font-medium leading-6 text-gray-900 ml-3">
                          Phone (H)
                        </label>

                        <div className="grid grid-cols-3">
                          <TelephoneInput
                            setKey={setKeyContact}
                            path={"housePhone"}
                            value={contact.housePhone}
                            className={
                              "mt-2 block w-full col-span-2 rounded-md border-1 px-3 py-1.5 text-gray-900  ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            }
                          />
                          <div className="">
                            <svg
                              onClick={() => {
                                setOpen(true);
                                setCurField("housePhone");
                                setCurAction("contact");
                              }}
                              xmlns="http://www.w3.org/2000/svg"
                              className="grid-cols-1 ml-10 h-4 w-4 cursor-pointer hover:red-500 mt-5"
                              viewBox="0 0 512 512"
                            >
                              <path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z" />
                            </svg>
                          </div>
                        </div>
                      </div>

                      <div className="lg:col-span-1 md:col-span-3 sm:col-span-3">
                        <label className="block text-sm font-medium leading-6 text-gray-900 ml-3">
                          Phone (W)
                        </label>

                        <div className="grid grid-cols-3">
                          <TelephoneInput
                            setKey={setKeyContact}
                            path={"workPhone"}
                            value={contact.workPhone}
                            className={
                              "mt-2 block w-full col-span-2 rounded-md border-1 px-3 py-1.5 text-gray-900  ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            }
                          />
                          <div className="">
                            <svg
                              onClick={() => {
                                setOpen(true);
                                setCurField("workPhone");
                                setCurAction("contact");
                              }}
                              xmlns="http://www.w3.org/2000/svg"
                              className="grid-cols-1 ml-10 h-4 w-4 cursor-pointer hover:red-500 mt-5"
                              viewBox="0 0 512 512"
                            >
                              <path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z" />
                            </svg>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="grid grid-cols-12 gap-6 mt-5">
                      <div className="col-span-4 sm:col-span-4"></div>
                    </div>
                  </div>
                </div>
              )}

              {current == 2 && !contractView && (
                <ul role="list" className="divide-y divide-gray-100">
                  {contracts.length > 0 && (
                    <>
                      {contracts.map((contract) => (
                        <li
                          onClick={(e) => {
                            setCurrentContract(contract);
                            genLink(contact)
                            setContractView(true);
                          }}
                          key={contract.registrationNumber}
                          className="relative justify-between gap-x-6 py-5"
                        >
                          <div
                            style={{ cursor: "pointer" }}
                            className="border border-solid"
                          >
                            <div className="grid grid-cols-4 px-5 py-5">
                              <div className="col-span-1">
                                <span className="whitespace-nowrap py-4 pl-4 pr-3 text-2xl  text-gray-900">
                                  {nullTransform(
                                    getDeepValue(contract, "loan.loanNumber") ||
                                      ""
                                  )}
                                </span>
                                <br />
                                <br />
                                <span className="whitespace-nowrap py-4 pl-4 pr-3 text-md font-medium text-gray-900">
                                  Balance:{" "}
                                  <b>
                                    $
                                    {nullTransform(
                                      FormatNumber(
                                        `${parseFloat(
                                          getDeepValue(
                                            contract,
                                            "loan.balance.total"
                                          ) || 0
                                        ).toFixed(2)}`
                                      )
                                    )}
                                  </b>
                                </span>
                              </div>
                              <div className="col-span-2">
                                <span className="whitespace-nowrap px-3 py-4 font-extrabold text-lg text-cyan-700">
                                  {nullTransform(contract.policyType || "")}
                                </span>
                                <br />
                                <br />
                                <span className="whitespace-nowrap px-3 py-4 text-md font-medium text-gray-900">
                                  Policy Number:{" "}
                                  <b>
                                    {nullTransform(contract.policyNumber || "")}
                                  </b>
                                </span>
                              </div>
                              <div className="col-span-1 ">
                                <span
                                  className={getStatus(contract.status).style}
                                >
                                  {getStatus(contract.status).name}
                                </span>

                                <br />
                                <br />
                                <span className="whitespace-nowrap py-4 text-md font-medium text-gray-900">
                                  Premium Financed:{" "}
                                  <b>
                                    $
                                    {nullTransform(
                                      FormatNumber(
                                        `${getDeepValue(
                                          contract,
                                          "loan.principal"
                                        )}`
                                      )
                                    )}
                                  </b>
                                </span>
                              </div>
                            </div>
                          </div>
                        </li>
                      ))}
                    </>
                  )}
                </ul>
              )}

              {current == 2 && contractView && (
                <div className="container mx-auto ">
                  <div className="grid grid-cols-6 mb-1 items-center">
                    <div className="col-span-1">
                      <p className="">Select a Contract:</p>
                    </div>
                    <div className="col-span-3">
                      <select
                        required
                        id="current-tab"
                        name="current-tab"
                        value={currentContract._id}
                        onChange={(e) => {
                          getContract(e.target.value);
                        }}
                        className="mt-2 block w-full rounded-md border-1 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      >
                        <option value="" style={{ textAlign: "center" }}>
                          {" "}
                          Select Contract{" "}
                        </option>
                        {contracts.map((contract) => (
                          <option
                            value={contract._id}
                            style={{ textAlign: "center" }}
                          >
                            {" "}
                            {nullTransform(
                              getDeepValue(contract, "loan.loanNumber") || ""
                            )}{" "}
                            -{" "}
                            {nullTransform(
                              getDeepValue(contract, "policyType") || ""
                            )}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="grid grid-cols-4 mb-1 ">
                    <div className="col-span-1">
                      <p className="text-3xl">
                        {nullTransform(
                          getDeepValue(selectedContract, "loan.loanNumber") ||
                            ""
                        )}
                      </p>
                    </div>
                  </div>

                  <div className="flex w-11/12 my-5">
                    <div className="w-4/12">
                      <p className="text-2xl font-bold">
                        Loan ID:{" "}
                        {nullTransform(
                          getDeepValue(currentContract, "loan.loanNumber") || ""
                        )}
                      </p>
                    </div>
                    <div className="flex w-4/12">
                      <div className="w-4/12"></div>
                      <div className="w-4/12">
                        <span
                          className={
                            getStatus(currentContract.loan.status).style +
                            " justify-self-center"
                          }
                        >
                          {getStatus(currentContract.loan.status).name}
                        </span>
                      </div>
                      <div className="w-4/12"></div>
                    </div>
                    <div className="w-4/12">
                      <span className="isolate inline-flex rounded-md shadow-sm">
                        <a
                          target="_blank"
                          href={link}
                          className="relative -ml-px inline-flex items-center bg-indigo-600 px-3 py-2 text-sm font-semibold text-white ring-1 ring-inset ring-gray-300 hover:bg-indigo-500 focus:z-10"
                          rel="noreferrer"
                        >
                          <CurrencyDollarIcon
                            className="-ml-0.5 h-5 w-5"
                            aria-hidden="true"
                          />
                          Apply Payment
                        </a>
                        {["current", "late"].includes(
                          currentContract?.loan?.status
                        ) && (
                          <AccessControl
                            permission={"instantCancelLoan"}
                            fallback={<></>}
                            target={["god"]}
                          >
                            <button
                              onClick={() => {
                                cancelLoan(currentContract?.loan?.loanNumber);
                              }}
                              type="button"
                              className="relative -ml-px inline-flex items-center bg-red-600 px-3 py-2 text-sm font-semibold text-white ring-1 ring-inset ring-gray-300 hover:bg-red-500 focus:z-10"
                            >
                              Cancel Now
                            </button>
                          </AccessControl>
                        )}
                      </span>
                    </div>
                  </div>

                  <div className="mb-10">
                    <div className="mb-5">
                      <p className="text-3xl mb-5">Contract Name Placeholder</p>
                      <div className="grid grid-cols-6 gap-4">
                        <div className="col-span-3">
                          <div className="grid-rows-2 mb-3">
                            <p className="text-xl row-span-1">Policy Number</p>
                            <p className="row-span-1">
                              {nullTransform(
                                  getDeepValue(currentContract, "policyNumber") ||
                                  ""
                              )}
                            </p>
                          </div>

                          <div className="grid-rows-2  mb-3">
                            <p className="text-xl row-span-1">
                              Premium Financed
                            </p>
                            <p className="row-span-1">
                              $
                              {nullTransform(
                                  FormatNumber(
                                      `${getDeepValue(
                                          currentContract,
                                          "loan.principal"
                                      )}`
                                  )
                              )}
                            </p>
                          </div>

                          <div className="grid-rows-2  mb-3">
                            <p className="text-xl row-span-1">
                              Premium + Interest
                            </p>
                            <p className="row-span-1">
                              $
                              {nullTransform(
                                  FormatNumber(
                                      parseFloat(
                                          `${getDeepValue(
                                              currentContract,
                                              "loan.startingBalance"
                                          )}`
                                      ).toFixed(2)
                                  )
                              )}
                            </p>
                          </div>

                          <div className="grid-rows-2  mb-3">
                            <p className="text-xl row-span-1">
                              Initial Downpayment
                            </p>
                            <p className="row-span-1">
                              ${" "}
                              {FormatNumber(
                                  nullTransform(
                                      parseFloat(
                                          currentContract?.loan?.amortisation[0]
                                              ?.instalment || 0
                                      ).toFixed(2)
                                  )
                              )}
                            </p>
                          </div>

                          <div className="grid-rows-2  mb-3">
                            <p className="text-xl row-span-1">
                              Regular Monthly Payment
                            </p>
                            <p className="row-span-1">
                              $
                              {FormatNumber(
                                  parseFloat(
                                      currentContract?.loan?.instalment
                                  ).toFixed(2)
                              )}
                            </p>
                          </div>

                          <div className="grid-rows-2  mb-3">
                            <p className="text-xl row-span-1">
                              Scheduled Payment Date
                            </p>
                            <p className="row-span-1">
                              {nullTransform(
                                  moment(
                                      getDeepValue(currentContract, "paymentDate")
                                  )
                                      .add(1, "day")
                                      .date()
                              )}
                            </p>
                          </div>
                          <div className="grid-rows-2  mb-3">
                            <p className="text-xl row-span-1">
                              Is this a mortgage
                            </p>
                            <p className="row-span-1 text-base">
                              {nullTransform(
                                  `${getDeepValue(
                                      currentContract,
                                      "mortgage"
                                  )}` || "No"
                              ).toUpperCase()}
                            </p>
                          </div>
                          {currentContract?.loan?.status === "late" && (
                              <div className="grid-rows-2  mb-3">
                                <p className="text-xl row-span-1">
                                  Late Days
                                </p>
                                <p className="row-span-1 text-base">
                                  {nullTransform(
                                      `${getDeepValue(
                                          currentContract,
                                          "loan.lateDays"
                                      )}` || "0"
                                  ).toUpperCase()} Days
                                </p>
                              </div>
                          )

                          }
                        </div>

                        <div className="col-span-3">
                          <div className="grid-rows-2  mb-3">
                            <p className="text-xl row-span-1">
                              Policy Start Date:
                            </p>
                            <p className="row-span-1">
                              {nullTransform(
                                  getDeepValue(
                                      currentContract,
                                      "startDate"
                                  ).substring(0, 10) || ""
                              )}
                            </p>
                          </div>

                          <div className="grid-rows-2  mb-3">
                            <p className="text-xl row-span-1">
                              Policy End Date:
                            </p>
                            <p className="row-span-1">
                              {nullTransform(
                                getDeepValue(
                                  currentContract,
                                  "policyEndDate"
                                ).substring(0, 10) || ""
                              )}
                            </p>
                          </div>
                          <div className="grid-rows-2  mb-3">
                            <p className="text-xl row-span-1">Disbursed At:</p>
                            <p className="row-span-1">
                              {nullTransform(
                                getDeepValue(
                                  currentContract?.loan,
                                  "disbursedAt"
                                ).substring(0, 10) || ""
                              )}
                            </p>
                          </div>

                          <div className="grid-rows-2  mb-3">
                            <p className="text-xl row-span-1">IPF Period:</p>
                            <p className="row-span-1">
                              {nullTransform(
                                getDeepValue(currentContract, "months")
                              )}
                            </p>
                          </div>

                          <div className="grid-rows-2  mb-3">
                            <p className="text-xl row-span-1">
                              Insurance Period:
                            </p>
                            <p className="row-span-1">
                              {nullTransform(
                                moment(
                                  getDeepValue(currentContract, "policyEndDate")
                                ).diff(
                                  moment(
                                    getDeepValue(currentContract, "startDate")
                                  ),
                                  "months"
                                )
                              )}
                            </p>
                          </div>

                          {currentContract?.loan?.status !== "new" && (
                            <div className="grid-rows-2  mb-3">
                              <p className="text-xl row-span-1">
                                Pay-off Amount:
                              </p>
                              <p className="row-span-1">
                                $
                                {parseFloat(
                                    currentContract?.loan?.collections
                                        ?.payOffAmount || 0
                                ).toFixed(2)}
                              </p>
                            </div>)
                          }
                          {![
                            "cancelled",
                            "refunded",
                            "withdrawn",
                            "paidUp",
                          ].includes(currentContract?.loan?.status) && (
                              <div className="grid-rows-2  mb-3">
                                <p className="text-xl row-span-1">
                                  Cancellation Status:
                                </p>
                                <p className="row-span-1">
                                {nullTransform(
                                  getDeepValue(
                                    currentContract,
                                    "loan.cancellationStatus"
                                  ) || ""
                                )}
                              </p>
                            </div>
                          )}

                          <div className="grid-rows-2  mb-3">
                            <p className="text-xl row-span-1">Arrears Due:</p>
                            <p className="row-span-1">
                              $
                              {parseFloat(
                                currentContract?.loan?.due?.total || "0"
                              ).toFixed(2)}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {currentContract?.loan?.meta?.insuranceDetails?.use_processing === false || currentContract?.loan?.meta?.insuranceDetails?.use_processing === undefined&&
                      <DetailedSchedule currentContract={currentContract}/>
                  }
                  {currentContract?.loan?.meta?.insuranceDetails?.use_processing === true &&
                      <SummarySchedule currentContract={currentContract}/>
                  }
                </div>
              )}
            </div>
          </div>
          <div className="bg-gray-100 px-4 py-3 text-right sm:px-6">
            <button
              type="button"
              onClick={back}
              className="text-sm font-semibold leading-6 text-gray-900 mx-3"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
