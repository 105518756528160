import React, { useState } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import Http from "../../../util/http";
import FileUpload from "./fileupload";
import Cropper from "./cropper";
import {getImageTypeFromBase64,base64ToBlob } from "../../../util/util";

export default function Logo({ organization, setOrganization }) {
  const [selectedFile, setSelectedFile] = useState(null);
  const [preview, setPreview] = useState(null);
  const [crop, setCrop] = useState({ aspect: 1, width: 50, height: 50, x: 25, y: 25 });
  const [croppedImage, setCroppedImage] = useState(null);
  const [logo, setSignature] = useState(null);
  const [open, setOpen] = useState(false)
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    console.log({ file });
    if (file) {
      if (file.size > 10 * 1024 * 1024) {
        Swal.fire({
          icon: "error",
          title: "File too large",
          text: "Please select a file smaller than 10MB.",
        });
        return;
      }
      setSelectedFile(file);
      const reader = new FileReader();
      reader.onload = () => {
        console.log({ res: reader.result });
        setPreview(reader.result);
        setOpen(true)
      }; // Generate preview
      reader.readAsDataURL(file);
      
    }
  };



  const handleUpload = async () => {
    if (croppedImage) {
      Swal.fire({
        title: "Uploading...",
        allowOutsideClick: false,
        didOpen: () => Swal.showLoading(),
      });

      const formData = new FormData();
      let blob = base64ToBlob(croppedImage.split(',')[1], getImageTypeFromBase64(croppedImage));
      console.log({ blob });
      formData.append("file", blob, "croppedImage.jpg");
      const payload = { id: organization._id };
      formData.append("payload", JSON.stringify(payload));
      formData.append("target", "organizations/add-logo");

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_ADMIN_API}/api`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              "x-auth-token": localStorage.getItem("token"),
              geolocation: JSON.parse(localStorage.getItem("geolocation"))._id,
            },
          }
        );
        setOrganization(response.data);
        localStorage.setItem("organization", JSON.stringify(response.data));
        setCroppedImage(null)
        setOpen(false)
        Swal.fire("Success", "Your file has been uploaded.", "success");
      } catch (error) {
        Swal.fire("Error", "Failed to upload the file.", "error");
      }
    }
  };

  const removeLoan = async () => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "Do you want to remove the logo?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, remove it!",
      });

      if (result.isConfirmed) {
        let response = await Http.request(
          "admin",
          "organizations/remove-logo",
          {
            id: organization._id,
          }
        );
        console.log(response);
        setOrganization({ response });
        setSignature(null);
        localStorage.setItem("organization", JSON.stringify(response));
        Swal.fire("Success", "Logo removed successfully.", "success").then(()=>{
          window.location.reload();
        });;
      }
    } catch (error) {
      Swal.fire("Error", "Failed to remove logo.", "error");
    }
  };

  const viewSignature = async () => {
    try {
      const response = await Http.request(
        "admin",
        "organizations/view-logo",
        {
          id: organization._id,
        }
      );
      setSignature(response.url);
    } catch (error) {
      console.log(error);
      Swal.fire("Error", "Failed to view logo.", "error");
    }
  };

  return (
    <div className="mx-auto mt-6 max-w-5xl px-4 sm:px-6 lg:px-8">
      <Cropper aspectRatio={4/4} open={open} setOpen={setOpen} preview={preview} croppedImage={croppedImage} setCroppedImage={setCroppedImage} handleUpload={handleUpload}/>
      <form>
        <div className="space-y-12">
          <div className="border-b border-gray-900/10 pb-12">
            <h2 className="text-base/7 font-semibold text-gray-900">
              Company Logo
            </h2>
            <p className="mt-1 text-sm/6 text-gray-600">
              This Logo will be placed on notice of cancellations.
            </p>

            {!organization.logo && (
              <div className="mt-5 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <div className="col-span-full">
                  <FileUpload handleFileChange={handleFileChange} />
                  {/* {croppedImage && (
                    <button
                      onClick={handleUpload}
                      className="mt-4 px-4 py-2 text-white bg-indigo-500 hover:bg-indigo-600 rounded shadow"
                    >
                      Upload Cropped Image
                    </button>
                  )} */}
                </div>
              </div>
            )}
            {logo && (
              <div className="my-5 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <div className="col-span-full">
                  <img
                    style={{ width: "12rem" }}
                    src={`${logo}`}
                    alt="logo"
                  />
                </div>
              </div>
            )}
            {organization.logo && (
              <div className="flex">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    removeLoan();
                  }}
                  className="flex items-center px-4 py-2 mt-3 text-white bg-red-500 hover:bg-red-600 rounded shadow"
                >
                  <i className="fas fa-unlink mr-2"></i>
                  Remove Logo
                </button>
                {!logo && (
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      viewSignature();
                    }}
                    className="ml-2 flex items-center px-4 py-2 mt-3 text-white bg-green-500 hover:bg-green-600 rounded shadow"
                  >
                    <i className="fas fa-logo mr-2"></i>
                    View Logo
                  </button>
                )}
                {logo && (
                  <button onClick={(e)=>{setSignature(null)}} className="ml-2 flex items-center px-4 py-2 mt-3 text-white bg-blue-500 hover:bg-blue-600 rounded shadow">
                    <i className={`fas fa-eye-slash mr-2`}></i>
                    Hide Logo
                  </button>
                )}
              </div>
            )}
          </div>
        </div>
      </form>
    </div>
  );
}
