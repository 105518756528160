import { getDeepValue, subString } from "../util/util";
import { useEffect, useState } from "react";
import Flag from "react-world-flags";
import Pagination from "./pagination";
import LoanCancellationActions from "./LoanCancellationActions";
import { ExclamationTriangleIcon } from "@heroicons/react/20/solid";
import { ChevronUpDownIcon } from "@heroicons/react/20/solid";

const statuses = {
  awaitingCancellation: 'text-yellow-500 bg-yellow-50 ring-yellow-400/20',
  writtenOff: 'text-gray-600 bg-gray-50 ring-gray-500/10',
  late: 'text-red-700 bg-red-50 ring-red-600/10',
  cancelled: 'text-red-700 bg-red-200 ring-red-600/10',
  paidUp: 'text-teal-700 bg-teal-200 ring-teal-600/10',
  commercial: 'text-cyan-700 bg-cyan-200 ring-cyan-600/10',
  cancellationNotice: 'text-teal-700 bg-teal-200 ring-teal-600/10',
  current: 'text-green-700 bg-green-200 ring-green-600/10',
  paidOff: 'text-cyan-700 bg-cyan-200 ring-cyan-600/10',
  "": 'text-white bg-green-500 ring-green-900/10',
  pendingApproval: 'text-yellow-700 bg-yellow-200 ring-yellow-600/10',
  approved: 'text-green-700 bg-green-200 ring-green-600/10'
}
export default function LoanCancellationTable({
  contracts,
  getData,
  paging,
  doSorting,
  totals,
  initState,
}) {
  const fields = [
    { title: "Name", key: "firstName", sorting: false },
    { title: "Company", key: "company", sorting: false },
    { title: "Branch", key: "branch", sorting: false },
    { title: "Agent", key: "agent", sorting: false },
    { title: "Policy Number", key: "policyNumber", sorting: false },
    { title: "Loan Number", key: "loanNumber", sorting: false },
    { title: "Premium Financed", key: "premiumFinanced", sorting: false },
    { title: "Total Due Now", key: "totalDueNow", sorting: false },
    { title: "Principal Balance", key: "principalBalance", sorting: false },
    { title: "BFTTL Time on Risk", key: "bfttlTimeOnRisk", sorting: false },
    {
      title: "Expected Net Premium Settlement - BFTTL",
      key: "expectedNetPremiumSettlement",
      sorting: false,
    },
    {
      title: 'Projected Estimated "Free Insurance" Provided by Insurer',
      key: "projectedEstimatedFreeInsurance",
      sorting: false,
    },
    { title: "Cancellations Status", key: "collectionsStatus", sorting: false },
    { title: "Tenure", key: "time", sorting: false },
    { title: "Days In Arrears", key: "daysInArrears", sorting: false },
    {
      title: "Cancellation Request Date",
      key: "cancellationRequestDate",
      sorting: false,
    },
  ];
  const [sortName, setSortName] = useState("");
  const [sortDirection, setSortDirection] = useState(0);
  useEffect(() => {}, [contracts, initState]);

  function sort(name) {
    let direction = 0;
    return new Promise((resolve) => {
      if (sortName !== name) {
        setSortName(name);
        setSortDirection(direction);
        resolve({ name, direction });
      } else {
        direction = sortDirection === 2 ? 0 : sortDirection + 1;
        setSortDirection(direction);
        resolve({ name, direction });
      }
    });
  }

  function nullTransform(val) {
    if (val === null || val === undefined) {
      return "";
    }
    return val;
  }
  function FormatNumber(str) {
    return str.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  return (
    <div className="hidden sm:block">
      <div className="mx-auto ">
        <div className="mt-2 flex flex-col">
          <div className="min-w-full overflow-hidden overflow-x-auto align-middle shadow sm:rounded-lg">
            {contracts.length > 0 && (
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    {fields.map((field, index) => {
                      return (
                        <th
                          key={index}
                          onClick={(e) => {
                            sort(field.key).then((sorting) => {
                              doSorting(sorting.name, sorting.direction);
                            });
                          }}
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-md font-semibold text-gray-900 sm:pl-6"
                        >
                          {field.title}{" "}
                          {/*<ChevronUpDownIcon className="h-5 w-5" aria-hidden="true" />*/}
                        </th>
                      );
                    })}
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-md font-semibold text-gray-900 sm:pl-6"
                    >
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {contracts.map((loan, index) => (
                    <tr
                      key={index}
                      className="bg-white cursor-pointer w-100"
                    >
                                                  <td  className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                        {nullTransform(loan?.firstName || "")}{" "}
                        {nullTransform(loan?.lastName || "")}
                      </td>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6" onClick={()=>{window.location = `${window.location.origin}/dashboard/clients/${loan.contractType}/profile/${loan.contractClient}/${loan.contractId}`}}>
                        {nullTransform(subString(loan?.company, 20) || "")}
                      </td>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6" onClick={()=>{window.location = `${window.location.origin}/dashboard/clients/${loan.contractType}/profile/${loan.contractClient}/${loan.contractId}`}}>
                        {nullTransform(loan?.branch || "")}
                      </td>

                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6" onClick={()=>{window.location = `${window.location.origin}/dashboard/clients/${loan.contractType}/profile/${loan.contractClient}/${loan.contractId}`}}>
                        {nullTransform(loan?.agent || "")}
                      </td>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6" onClick={()=>{window.location = `${window.location.origin}/dashboard/clients/${loan.contractType}/profile/${loan.contractClient}/${loan.contractId}`}}>
                        {nullTransform(loan?.policyNumber || "")}
                      </td>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6" onClick={()=>{window.location = `${window.location.origin}/dashboard/clients/${loan.contractType}/profile/${loan.contractClient}/${loan.contractId}`}}>
                        {nullTransform(loan?.loanNumber || "")}
                      </td>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6" onClick={()=>{window.location = `${window.location.origin}/dashboard/clients/${loan.contractType}/profile/${loan.contractClient}/${loan.contractId}`}}>
                        $
                        {nullTransform(
                          FormatNumber(
                            parseFloat(loan?.premiumFinanced).toFixed(2) || "0"
                          )
                        )}
                      </td>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6" onClick={()=>{window.location = `${window.location.origin}/dashboard/clients/${loan.contractType}/profile/${loan.contractClient}/${loan.contractId}`}}>
                        $
                        {nullTransform(
                          FormatNumber(
                            parseFloat(loan?.totalDueNow || 0).toFixed(2) || "0"
                          )
                        )}
                      </td>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6" onClick={()=>{window.location = `${window.location.origin}/dashboard/clients/${loan.contractType}/profile/${loan.contractClient}/${loan.contractId}`}}>
                        $
                        {nullTransform(
                          FormatNumber(
                            parseFloat(loan?.principalBalance || 0).toFixed(
                              2
                            ) || "0"
                          )
                        )}
                      </td>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6" onClick={()=>{window.location = `${window.location.origin}/dashboard/clients/${loan.contractType}/profile/${loan.contractClient}/${loan.contractId}`}}>
                        ${nullTransform(loan?.bfttlTimeOnRisk || "")}
                      </td>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6" onClick={()=>{window.location = `${window.location.origin}/dashboard/clients/${loan.contractType}/profile/${loan.contractClient}/${loan.contractId}`}}>
                        $
                        {nullTransform(
                          loan?.expectedNetPremiumSettlement || ""
                        )}
                      </td>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6" onClick={()=>{window.location = `${window.location.origin}/dashboard/clients/${loan.contractType}/profile/${loan.contractClient}/${loan.contractId}`}}>
                        $
                        {nullTransform(
                          loan?.projectedEstimatedFreeInsurance || ""
                        )}
                      </td>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6" onClick={()=>{window.location = `${window.location.origin}/dashboard/clients/${loan.contractType}/profile/${loan.contractClient}/${loan.contractId}`}}>
                        {loan?.cancellationStatus && loan?.cancellationStatus !== "" &&
                            <div
                            className={classNames(
                              statuses[loan?.cancellationStatus],
                              "rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset flex justify-center"
                            )}
                          >
                            <div>
                              {nullTransform(loan?.cancellationStatus || "")}
                            </div>
                          </div>
                        }
                        {loan?.cancellationStatus === "" &&
                            <div>
                            <div>
                              notApplicable
                            </div>
                          </div>
                        }
                      </td>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6" onClick={()=>{window.location = `${window.location.origin}/dashboard/clients/${loan.contractType}/profile/${loan.contractClient}/${loan.contractId}`}}>
                        {nullTransform(
                          loan?.time || ""
                        )}
                      </td>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6" onClick={()=>{window.location = `${window.location.origin}/dashboard/clients/${loan.contractType}/profile/${loan.contractClient}/${loan.contractId}`}}>
                        {nullTransform(
                          loan?.daysInArrears?.substring(0, 10) || ""
                        )}
                      </td>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6" onClick={()=>{window.location = `${window.location.origin}/dashboard/clients/${loan.contractType}/profile/${loan.contractClient}/${loan.contractId}`}}>
                        {nullTransform(
                          loan?.cancelRequestedAt?.substring(0, 10) || ""
                        )}
                      </td>
                      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                        {/* <a href={`${window.location.pathname}/${contract._id}`} className="text-indigo-600 hover:text-indigo-900">
                                            View<span className="sr-only">, {contract.name}</span>
                                        </a>*/}
                        <LoanCancellationActions
                          id={loan._id}
                          contractId={loan.contractId}
                          contractClient={loan.contractClient}
                          contractType={loan.contractType}
                          contract={loan}
                        ></LoanCancellationActions>
                      </td>
                    </tr>
                  ))}
                  <tr className="border-t border-gray-500 bg-slate-400">
                    <th
                      colSpan="6"
                      scope="colgroup"
                      className=" py-2 pl-4 pr-3 text-left text-base font-semibold text-gray-900 pl-6"
                    >
                      Totals
                    </th>
                    <th
                      scope="colgroup"
                      className="py-2 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 pl-6"
                    >
                      $
                      {FormatNumber(
                        `${parseFloat(totals.premiumFinanced).toFixed(2)}` ||
                          "0"
                      )}
                    </th>
                    <th
                      scope="colgroup"
                      className=" py-2 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 pl-6"
                    >
                      $
                      {FormatNumber(
                        `${parseFloat(totals.totalDueNow || 0).toFixed(2)}` || "0"
                      )}
                    </th>
                    <th
                      scope="colgroup"
                      className=" py-2 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 pl-6"
                    >
                      $
                      {FormatNumber(
                        `${parseFloat(totals.principalBalance).toFixed(2)}` ||
                          "0"
                      )}
                    </th>
                    <th
                      scope="colgroup"
                      className=" py-2 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 pl-6"
                    >
                      $
                      {FormatNumber(
                        `${parseFloat(totals.bfttlTimeOnRisk).toFixed(2)}` ||
                          "0"
                      )}
                    </th>
                    <th
                      scope="colgroup"
                      className=" py-2 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 pl-6"
                    >
                      $
                      {FormatNumber(
                        `${parseFloat(
                          totals.expectedNetPremiumSettlement
                        ).toFixed(2)}` || "0"
                      )}
                    </th>
                    <th
                      scope="colgroup"
                      className=" py-2 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 pl-6"
                    >
                      $
                      {FormatNumber(
                        `${parseFloat(
                          totals.projectedEstimatedFreeInsurance
                        ).toFixed(2)}` || "0"
                      )}
                    </th>
                    <th
                      colSpan="4"
                      scope="colgroup"
                      className=" py-2 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 pl-6"
                    ></th>
                  </tr>
                </tbody>
              </table>
            )}
            {contracts.length === 0 && !initState && (
              <div className="rounded-md bg-yellow-50 p-4">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <ExclamationTriangleIcon
                      className="h-5 w-5 text-yellow-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3">
                    <h3 className="text-sm font-medium text-yellow-800">
                      No Loans Found!
                    </h3>
                    <div className="mt-2 text-sm text-yellow-700">
                      <p>
                        You can use filter parameters provided to find the
                        records you are looking for
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {contracts.length === 0 && initState && (
              <div className="rounded-md bg-yellow-50 p-4">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <ExclamationTriangleIcon
                      className="h-5 w-5 text-yellow-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3">
                    <h3 className="text-sm font-medium text-yellow-800">
                      Loading Available Loans......
                    </h3>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div>
            {contracts.length > 0 && (
              <Pagination getData={getData} paging={paging} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
