import Pagination from "../../../components/pagination";
import { useState, useEffect } from "react";
import Http from "../../../util/http";
import { json2csv } from "json-2-csv";
import Swal from "sweetalert2";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const statuses = {
  awaitingCancellation: "text-yellow-500 bg-yellow-50 ring-yellow-400/20",
  writtenOff: "text-gray-600 bg-gray-50 ring-gray-500/10",
  late: "text-red-700 bg-red-50 ring-red-600/10",
  cancelled: "text-red-700 bg-red-200 ring-red-600/10",
  paidUp: "text-teal-700 bg-teal-200 ring-teal-600/10",
  commercial: "text-cyan-700 bg-cyan-200 ring-cyan-600/10",
  retail: "text-teal-700 bg-teal-200 ring-teal-600/10",
  current: "text-green-700 bg-green-200 ring-green-600/10",
  paidOff: "text-cyan-700 bg-cyan-200 ring-cyan-600/10",
  //current: 'text-white bg-green-500 ring-green-900/10',
  pendingApproval: "text-yellow-700 bg-yellow-200 ring-yellow-600/10",
  approved: "text-green-700 bg-green-200 ring-green-600/10",
};

export default function ArrearsPage(props) {
  const [contracts, setContracts] = useState([]);
  const [paging, setPaging] = useState({
    text: "",
    sort: "",
    total: 0,
    page: 1,
    count: 0,
    perPage: 10,
    type: "",
    status: "",
  });
  const [organizations, setOrganizations] = useState([]);
  const [organization, setOrganization] = useState({});
  const [coverTypes, setCoverTypes] = useState([]);
  const [showSelect, setShowSelect] = useState(true);
  const [policyType, setPolicyType] = useState("");
  const [lateDays, setLateDays] = useState("");
  const [fetchTrigger, setFetchTrigger] = useState(false);
  const [companies, setCompanies] = useState([]);

  async function search(field) {
    let pagingData = { ...paging };
    pagingData[field.key] = field.value;
    pagingData.page = 1;
    setPaging(pagingData);
    // let url = window.location.origin + window.location.pathname +'?' +new URLSearchParams(pagingData)
    // window.history.replaceState( {} , 'contracts', url)
    getData(pagingData);
  }

  async function getCoverTypes() {
    try {
      let payload = {
        text: "",
        sort: "",
        total: 0,
        page: 1,
        count: 0,
        perPage: 999,
        type: "",
        companyType: "",
      };
      let response = await Http.request("admin", "coverTypes/get-all", payload);
      if (coverTypes.length < 1) {
        response.coverages = response.coverages.filter(
          (value, index, self) =>
            index === self.findIndex((t) => t.name === value.name)
        );
        setCoverTypes(response.coverages);
      }
    } catch (err) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Failed to make request to servers.",
      });
    }
  }

  useEffect(() => {
    let user = JSON.parse(localStorage.getItem("user"));
    if (user.position !== "master" && user.position !== "god") {
      console.log("do not show");
      setShowSelect(false);
    }
    let org = JSON.parse(localStorage.getItem("organization"));
    setOrganization(org);
    let pagingData = {};

    pagingData = { ...paging };
    pagingData.page = parseInt(pagingData.page);
    getData(pagingData, true);
    getCoverTypes();
    getOrganizations();

    if (fetchTrigger) {
      getData(pagingData, true);
      getCoverTypes();
      setFetchTrigger(false); // Reset the trigger after fetching
    }
  }, [fetchTrigger]);

  const fields = [
    {
      title: "Company Name",
      key: "meta.insuranceDetails.company_name",
      sorting: false,
    },
    { title: "Loan Number", key: "loan", sorting: false },
    { title: "Client", key: "name", sorting: false },
    { title: "Agent", key: "name", sorting: false },
    { title: "Type", key: "", sorting: false },
    { title: "Status", key: "status", sorting: false },
    { title: "Late Days", key: "lateDays", sorting: false },
    { title: "Policy Number", key: "policyNum", sorting: false },
    { title: "Installments", key: "premium", sorting: false },
    { title: "Months", key: "premium", sorting: false },
    { title: "Premium Financed", key: "premium", sorting: false },
    {
      title: "Total Due",
      key: "loanData.meta.insuranceDetails.insurance_total_premium",
      sorting: false,
    },
    { title: "Action", key: "status", sorting: false },
  ];

  function getOrganizations() {
    Http.request("admin", "organizations/get-all", {
      text: "",
      page: 1,
      sort: "",
      total: 0,
      count: 0,
      perPage: 100000,
      companyType: "",
      limit: 100000,
    })
      .then((response) => {
        let orgs = response.organizations;
        let _brokers = [];
        let _companies = [];
        orgs.forEach((org) => {
          if (org.type.includes("Broker")) {
            _brokers.push({ value: org.name, text: org.name, _id: org._id });
          } else {
            _companies.push({ value: org.name, text: org.name, _id: org._id });
          }
        });
        setCompanies(_companies);
      })
      .catch((e) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Failed to make request to servers.",
        });
      });
  }

  function getData(pagingData, showloader) {
    let dialog;
    if (showloader) {
      dialog = Swal.fire({
        title: "Loading...",
        timerProgressBar: true,
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
        willClose: () => {},
      });
    }

    const payload = {
      ...pagingData, // Your existing paging data
      policyType, // Add the policyType filter
      lateDays, // Add the lateDays filter
      // Include other filters as needed
    };

    Http.request("contracts", "contract/arrears/get-all", payload)
      .then((response) => {
        setContracts(response.loans);
        setPaging((prev) => ({
          ...prev,
          total: response.total,
          count: response.loans.length,
          perPage: response.perPage,
          text: payload.text || "",
          status: document.getElementById("status")?.value || "",
          type: document.getElementById("type")?.value || "",
          // It seems like you might be setting status and type again here, make sure to align these with your state if needed
        }));

        // setPaging({
        //   ...newPagingData,
        //   total: response.total,
        //   count: response.loans.length,
        //   perPage: response.perPage,
        //   text: document.getElementById("search").value || "",
        //   status: document.getElementById("status")?.value || "",
        //   type: document.getElementById("type")?.value || "",
        // });
        if (showloader) {
          dialog.close();
        }
      })
      .catch((e) => {
        console.log(e);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Failed to make request to servers.",
        });
      });
  }
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  function setCompany(value) {
    const pagingData = { ...paging };
    pagingData.company = value;
    pagingData.page = 1;
    setPaging(pagingData);
    //getData(pagingData);
    //search({key:'company', value: value})
  }
  const modelTitle = (str) => {
    var text = [];
    for (var i = 0; i < str.length; i++) {
      let char = "";
      if (str.charAt(i) === str.charAt(i).toUpperCase()) {
        char = ` ${str.charAt(i)}`;
      } else {
        char = str.charAt(i);
      }
      text.push(char);
    }
    text = text.join(",");
    while (text.includes(",")) {
      text = text.replace(",", "");
    }
    return capitalizeFirstLetter(text);
  };
  function renameKeys(obj, newKeys) {
    const keyValues = Object.keys(obj).map((key) => {
      const newKey = newKeys[key] || key;
      return { [newKey]: obj[key] };
    });
    return Object.assign({}, ...keyValues);
  }
  function updateFields(items) {
    for (var i = 0; i < items.length; i++) {
      delete items[i].contractClient;
      delete items[i].contractId;
      let oldKeys = Object.keys(items[i]);
      let newKeys = {};

      for (let j = 0; j < oldKeys.length; j++) {
        newKeys[oldKeys[j]] = modelTitle(oldKeys[j]);
      }

      items[i] = renameKeys(items[i], newKeys);
      console.log(items[i]);
    }
    return items;
  }
  function exportData(type) {
    let dialog;
    let pagingData = { ...paging };
    let showloader = true;
    if (showloader) {
      dialog = Swal.fire({
        title: "Loading...",
        timerProgressBar: true,
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
        willClose: () => {},
      });
    }
    console.log(pagingData);
    if (type === "csv") {
      pagingData.exportCsv = true;
    } else {
      pagingData.exportExcel = true;
    }
    Http.request("contracts", "contract/arrears/export/get-all", pagingData)
      .then((response) => {
        if (showloader) {
          dialog.close();
        }
        if (pagingData.exportExcel) {
          const url = window.URL.createObjectURL(
            new Blob([new Uint8Array(response.buffer.data)])
          );
          const a = document.createElement("a");
          a.setAttribute("href", url);
          a.setAttribute("download", `${Date()}_excel_export_arrears.xlsx`);
          a.click();
        } else {
          response.loans = updateFields(response.loans);
          json2csv(response.loans, {}).then((jsonData) => {
            const blob = new Blob([jsonData], { type: "text/csv" });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.setAttribute("href", url);
            a.setAttribute("download", `${Date()}_csv_export_arrears.csv`);
            a.click();
          });
        }
      })
      .catch((e) => {
        console.log(e);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Failed to make request to servers.",
        });
      });
  }

  function selectType(id) {
    let pagingData = { ...paging };
    pagingData.type = id;
    setPaging(pagingData);
    // let url = window.location.origin + window.location.pathname +'?' +new URLSearchParams(pagingData)
    // window.history.replaceState( {} , 'users', url)
    getData(pagingData);
  }

  function selectStatus(id) {
    let pagingData = { ...paging };
    pagingData.status = id;
    pagingData.page = 1;
    pagingData.count = 0;
    // let url = window.location.origin + window.location.pathname +'?' +new URLSearchParams(pagingData)
    // window.history.replaceState( {} , 'users', url)
    setPaging(pagingData);
    getData(pagingData);
  }

  function resetFilter() {
    setPolicyType("");
    setLateDays("");

    const resetPagingData = {
      ...paging,
      page: 1,
      total: 0,
      type: "",
      status: "",
      text: "",
      sort: "",
      company: "",
      coverageType: "",
    };

    // Update state
    setCompany(null);
    setPaging(resetPagingData);
    setFetchTrigger(true);

    //getData(paging, true);
  }

  function FormatNumber(str) {
    return str.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const handleInputChange = (e) => {
    let { value } = e.target;
    // Replace non-special characters with an empty string
    const disallowedChars = new Set("!@#$%^&*()\\?\"{}[]'");

    // Filter the input to remove disallowed characters
    const filteredValue = Array.from(value)
      .filter((char) => !disallowedChars.has(char))
      .join("");

    // Log the original and filtered values for debugging
    console.log(`Original input received: ${value}`);
    console.log(
      `Filtered input (disallowed characters removed): ${filteredValue}`
    );

    if (filteredValue !== value) {
      console.warn(
        "Invalid character detected, certain special characters are not allowed."
      );
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Invalid character detected, certain special characters are not allowed.",
      });
    }

    // Update the state with the filtered value regardless
    search({ key: "text", value: filteredValue });
  };

  function nullTransform(val) {
    if (val === null || val === undefined) {
      return "";
    }
    return val;
  }
  function openLoan(contract) {
    let purpose = contract.purpose?.toLowerCase();
    if (purpose === "insurance-premium-financing") {
      purpose = "retail";
    }
    window.location.replace(
      `${window.location.origin}/dashboard/clients/${purpose}/profile/${contract.clientId}/${contract.contractId}`
    );
  }

  return (
    <div className="flex justify-center ">
      <div className="space-y-6 sm:px-6 lg:col-span-9 lg:px-0 w-full">
        <div className="shadow sm:overflow-hidden sm:rounded-md">
          <div className="space-y-6 bg-white px-4 py-6 sm:p-6">
            <div className="mb-10">
              <h1 className="text-4xl text-center font-semibold  leading-6 text-gray-900">
                (Arrears)
              </h1>
            </div>

            <div className="px-4 mt-7">
              <div className="mx-auto px-4 mb-4">
                <div className="mt-6 flex gap-x-4" action="#">
                  <div className="min-w-0 flex-1">
                    <label className="sr-only">Search</label>
                    <div className="relative rounded-md shadow-sm">
                      <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                        <svg
                          className="h-5 w-5 text-gray-400"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            fillRule="evenodd"
                            d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                      </div>
                      <input
                        value={paging.text}
                        onChange={handleInputChange}
                        type="search"
                        name="search"
                        id="search"
                        className="block w-full rounded-md border-0 py-1.5 pl-10 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        placeholder="Search using Loan number, Client Name,Policy Number"
                      />
                    </div>
                  </div>
                  <div className="min-w-0 flex-1">
                    <label className="sr-only">Search By Company</label>
                    <div
                      className="relative rounded-md shadow-sm"
                      style={{ height: "100%" }}
                    >
                      {
                        <select
                          name="contractAge"
                          id="contractAge"
                          className="block h-full w-full rounded-md border-0 bg-white py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          value={paging.company}
                          onChange={(e) => {
                            setCompany(e.target.value);
                          }}
                        >
                          <option value="">Select a Company</option>
                          {companies.map((company) => {
                            return (
                              <option key={company.value} value={company._id}>
                                {company.text}
                              </option>
                            );
                          })}
                        </select>
                      }
                    </div>
                  </div>
                  <div className="relative">
                    <select
                      value={policyType}
                      onChange={(e) => {
                        setPolicyType(e.target.value);
                      }}
                      name="type"
                      autoComplete="type"
                      className="block h-full w-full rounded-md border-0 bg-white py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    >
                      <option>Policy Type</option>
                      {coverTypes.map((policy, index) => {
                        return (
                          <option value={policy.name}>{policy.name}</option>
                        );
                      })}
                    </select>
                  </div>

                  {/* Dropdown Menu 2 */}
                  <div className="relative">
                    <select
                      onChange={(e) => {
                        setLateDays(e.target.value);
                      }}
                      name="type"
                      autoComplete="type"
                      value={lateDays}
                      className="block h-full w-full rounded-md border-0 bg-white py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    >
                      <option value="">Late days</option>
                      <option value="under30">Under 30 days</option>
                      <option value="under60">Under 60 days</option>
                      <option value="under90">Under 90 days</option>
                      <option value="over90">Over 90 days</option>
                    </select>
                  </div>

                  <span className="isolate inline-flex rounded-md shadow-sm">
                    <button
                      onClick={() => {
                        getData(paging, true);
                      }}
                      type="button"
                      className="relative -ml-px inline-flex items-center bg-indigo-600 px-3 py-2 text-sm font-semibold text-white ring-1 ring-inset ring-gray-300 hover:bg-indigo-500 focus:z-10"
                    >
                      Filter
                    </button>
                    <button
                      onClick={() => {
                        resetFilter();
                      }}
                      type="button"
                      className="relative -ml-px inline-flex items-center bg-indigo-600 px-3 py-2 text-sm font-semibold text-white ring-1 ring-inset ring-gray-300 hover:bg-indigo-500 focus:z-10"
                    >
                      Reset
                    </button>
                    <button
                      onClick={() => {
                        exportData("csv");
                      }}
                      type="button"
                      className="relative -ml-px inline-flex items-center bg-indigo-600 px-3 py-2 text-sm font-semibold text-white ring-1 ring-inset ring-gray-300 hover:bg-indigo-500 focus:z-10"
                    >
                      Export CSV
                    </button>
                    <button
                      onClick={() => {
                        exportData("excel");
                      }}
                      type="button"
                      className="relative -ml-px inline-flex items-center rounded-r-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white ring-1 ring-inset ring-gray-300 hover:bg-indigo-500 focus:z-10"
                    >
                      Export Excel
                    </button>
                  </span>
                </div>
              </div>

              {/* User table*/}
              <div className="hidden sm:block">
                <div className="mx-auto px-4">
                  <div className="mt-2 flex flex-col">
                    <div className="min-w-full overflow-hidden overflow-x-auto align-middle shadow sm:rounded-lg">
                      <table className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-gray-50">
                          <tr>
                            {fields.map((field, index) => {
                              return (
                                <th
                                  key={index}
                                  scope="col"
                                  className="py-3.5 pl-4 pr-3 text-left text-md font-semibold text-gray-900 sm:pl-6"
                                >
                                  {field.title}
                                </th>
                              );
                            })}
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                          {contracts.map((contract, index) => (
                            <tr
                              onClick={() => openLoan(contract)}
                              key={index}
                              className="bg-white cursor-pointer w-100"
                            >
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                {nullTransform(
                                  JSON.stringify(
                                    contract?.meta?.insuranceDetails
                                      ?.company_name || ""
                                  ).replace(/^"(.*)"$/, "$1") || ""
                                )}
                              </td>
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                {nullTransform(contract?.loanNumber || "")}
                              </td>
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                {nullTransform(
                                  contract?.client?.personal.fullName || ""
                                )}
                              </td>
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                {nullTransform(
                                  contract?.createdBy?.fullName || ""
                                )}
                              </td>
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                <div
                                  className={classNames(
                                    statuses[contract?.contractType],
                                    "rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset flex justify-center"
                                  )}
                                >
                                  <div>
                                    {nullTransform(
                                      contract?.purpose?.toLowerCase() || ""
                                    )}
                                  </div>
                                </div>
                              </td>
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                <div
                                  className={classNames(
                                    statuses[contract?.status],
                                    "rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset flex justify-center"
                                  )}
                                >
                                  <div>
                                    {nullTransform(contract?.status || "")}
                                  </div>
                                </div>
                              </td>
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                {nullTransform(contract?.lateDays || "")}
                              </td>
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                {nullTransform(
                                  contract?.meta?.insuranceDetails
                                    ?.policy_number || ""
                                )}
                              </td>
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                $
                                {nullTransform(
                                  FormatNumber(
                                    parseFloat(
                                      contract?.instalment || "0"
                                    ).toFixed(2) || "0"
                                  )
                                )}
                              </td>
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                {nullTransform(contract?.time || "")}
                              </td>
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                $
                                {nullTransform(
                                  FormatNumber(
                                    parseFloat(contract?.principal).toFixed(
                                      2
                                    ) || "0"
                                  )
                                )}
                              </td>
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                $
                                {nullTransform(
                                  FormatNumber(
                                    parseFloat(
                                      contract?.due?.total || 0
                                    ).toFixed(2) || "0"
                                  )
                                )}
                              </td>
                              <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                <a
                                  onClick={() => openLoan(contract)}
                                  className="text-indigo-600 hover:text-indigo-900 mr-1"
                                >
                                  View
                                </a>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div className="shadow sm:overflow-hidden sm:rounded-b-md">
                      <Pagination getData={getData} paging={paging} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
