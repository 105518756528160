import { useEffect,useState } from "react"


export default function Info({organization}) {
    const [geo, setGeo] = useState({})
    useEffect(()=>{
        try {
            setGeo(JSON.parse(localStorage.getItem('geolocation')))
            console.log({info: organization})
        } catch (error) {
            console.log(error)
        }
    },[setGeo])
    return (
        <div className="mx-auto mt-6 max-w-5xl px-4 sm:px-6 lg:px-8">
        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
          <div  className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">Phone</dt>
                  <dd className="mt-1 text-sm text-gray-900">{organization?.contactInfo?.telephone}</dd>
          </div>
          <div  className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">Email</dt>
                  <dd className="mt-1 text-sm text-gray-900">{organization?.contactInfo?.email}</dd>
          </div>
          <div  className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">Address</dt>
                  <dd className="mt-1 text-sm text-gray-900">{organization?.address?.street}, {organization?.address?.state}, {organization?.address?.city}, {geo?.title}</dd>
          </div>
          
        </dl>
      </div>
    )
}