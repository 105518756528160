"use client";

import React, { useState } from "react";
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/24/outline";
import Http from "../../util/http";
import {
  setDeepValue,
  Capitalize,
  FormatNumber,
  nullTransform,
  delay,
} from "../../util/util";
import moment from "moment";
import MonthGrid from "./monthgrid";
import { Tooltip } from 'react-tooltip'

export default function RetailAmortizeModal({
  open,
  setOpen,
  action,
  save,
  link,
  name,
  client,
}) {
  const [info, setInfo] = useState({
    name: "",
    premium: 0,
    months: "",
    startDate: moment().format().substring(0, 10),
    policyEndDate: "",
    endDate: "",
  });
  const [policies, setPolicies] = useState([
    {
      number: 0,
      type: "",
      premium: 0,
      company: "",
    },
  ]);
  const [summary, setSummary] = useState(null);
  const [premiumMask, setPremiumMask] = useState("$0.00");
  const [months, setMonths] = useState([]);
  async function changeVal(val, key) {
    let payload = { ...info };
    payload[key] = val;

    if (key === "startDate") {
      let _date = moment(val).add(1, "months").format().substring(0, 10);
      payload.paymentDate = _date;
    }
    setInfo(payload);
    await delay(1000);
    if (key === "premium") {
      if (isNaN(val) || val <= 0) {
        setPremiumMask("$0.00");
      } else {
        const CurrencyFormat = Intl.NumberFormat("en-us", {
          style: "currency",
          currency: "USD",
        });
        setPremiumMask(CurrencyFormat.format(val));
      }
    }

    await getSummary(payload);
  }
  async function getSummary(payload) {
    try {
      if (
        payload.premium === 0 ||
        payload.startDate === "" ||
        payload.policyEndDate === ""
      ) {
        return;
      }
      console.log({ payload });
      let response = await Http.request(
        "contracts",
        "contracts/amortise/dynamic",
        payload
      );
      setMonths(response.payload);
    } catch (err) {
      console.log(err);
    }
  }
  function closeModal(){

    setInfo({
      name: "",
      premium: 0,
      months: "",
      startDate: moment().format().substring(0, 10),
      policyEndDate: "",
      endDate: "",
    })
    setSummary(null)
    setMonths([])
    setPremiumMask("$0.00")
    setOpen(false);
  }
  function next(){
    let _info = { ...info };
    //_info.policyEndDate = moment(_info.startDate).add(summary.data.length - 1, 'month').format('YYYY-MM-DD');
    //console.log({_info})
    save(action, { info:_info, policies, summary });
  }
  return (
    <Dialog open={open} onClose={closeModal} className="relative z-10">
      <Dialog.Backdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <Dialog.Panel
            transition
            style={{ minWidth: "60vw" }}
            className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
          >
            <div className="">
              <div className="flex divide-x">
                <div className="w-10/12 font-bold text-xl">
                  New {Capitalize(action)} Summary for {name}
                </div>
                <div className="w-2/12 mx-0">
                  {link !== undefined && (
                    <a
                      href={link}
                      type="button"
                      className="inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      View Profile
                      <i className="fa-regular fa-user"></i>
                    </a>
                  )}
                  {/*{link === undefined && (*/}
                  {/*  <button*/}
                  {/*    onClick={() => save(action, { info, policies, summary })}*/}
                  {/*    type="button"*/}
                  {/*    className="inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"*/}
                  {/*  >*/}
                  {/*    Create Profile*/}
                  {/*    <i className="fa-regular fa-user"></i>*/}
                  {/*  </button>*/}
                  {/*)}*/}
                </div>
              </div>
              <div className="mt-3 text-center flex sm:mt-5">
                <div className="w-1/3 text-left">
                  <div>
                    <div className="text-xl font-bold leading-7 text-gray-900">
                      Insurance Premium:
                    </div>
                    <div className="text-sm text-gray-500">
                      Total premium to be financed,including tax.
                    </div>
                    <div className="mt">
                      <input
                        onChange={(e) => {
                          changeVal(parseFloat(e.target.value), "premium");
                        }}
                        type="number"
                        name="premium"
                        id="premium"
                        style={{ width: "100%" }}
                        className="block w-100 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        placeholder="Enter premium"
                      />
                      <p>Premium Value: {premiumMask}</p>
                    </div>
                  </div>
                  <div className="mt-6">
                    <div className="text-xl font-bold leading-7 text-gray-900">
                      First Payment Date:
                    </div>
                    <div className="text-sm text-gray-500">
                      Please input date that your policy starts here.
                    </div>
                    <div className="mt-2 flex">
                      <input
                        value={info.startDate}
                        onChange={(e) => {
                          changeVal(e.target.value, "startDate");
                        }}
                        type="date"
                        name="start"
                        id="start"
                        style={{ width: "100%" }}
                        className="block w-100 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        min={new Date().toISOString().split("T")[0]} // Set min attribute to today's date
                        max={moment().endOf('month').format('YYYY-MM-DD')}
                      />
                    </div>
                  </div>
                  <div className="mt-6">
                    <div className="text-xl font-bold leading-7 text-gray-900">
                      Policy End Date:
                      <span className={"mx-2"}>
                        <a data-tooltip-id="my-tooltip" data-tooltip-html="Your contract must end a month before your policy end date.</br> You will only see the repayment months that show an end date a month before the policy end date.">
                          <i className="fa-solid fa-info cursor-pointer"></i>
                        </a>
                        <Tooltip id="my-tooltip" style={{fontSize: "12px",cursor: "pointer"}}/>
                      </span>
                    </div>
                    <div className="text-sm text-gray-500">
                      Your contract must end a month before your policy end date.
                    </div>
                    <div className="mt-2 flex">
                      <input
                          value={info.policyEndDate}
                          onChange={(e) => {
                            changeVal(e.target.value, "policyEndDate");
                        }}
                        type="date"
                        name="end"
                        id="end"
                        style={{ width: "100%" }}
                        className="block w-100 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        min={moment(info.startDate).add(2, 'months').format('YYYY-MM-DD')} // Set min attribute to today's date
                        max={moment().add(12, 'months').format('YYYY-MM-DD')}
                      />
                    </div>
                  </div>
                </div>

                <div className="w-2/3 px-4">
                  <MonthGrid
                    months={months}
                    setSummary={setSummary}
                    info={info}
                    setInfo={setInfo}
                    summary={summary}
                  />
                </div>
              </div>
            </div>
            <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
              <button
                type="button"
                disabled={summary === null}
                onClick={() => {
                  next();
                }}
                className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
              >
                Create Contract
              </button>
              <button
                type="button"
                data-autofocus
                onClick={() => closeModal()}
                className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
              >
                Cancel
              </button>
            </div>
          </Dialog.Panel>
        </div>
      </div>
    </Dialog>
  );
}
