import React, { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { CheckIcon, XCircleIcon } from "@heroicons/react/24/outline";
import Http from "../../util/http";
import Swal from "sweetalert2";
import AccessControl from "../../util/accessControl";

export default function VerifyClientModal({
  client,
  target,
  show,
  setShow,
  setClient,
  openModal,
  createContract
}) {
  const [verifyClient, setVerifyClient] = useState(false);
  const cancelButtonRef = useRef(null);
  const smsinputref = useRef(null);
  const [email, setEmail] = useState("");
  const [id, setId] = useState(null);
  const [sms, setSms] = useState("");
  const [open, setOpen] = useState(false);
  useEffect(() => {
    if (show === true) {
      setOpen(true);
    }
  }, [show]);

  async function sendVerificationCode() {
    try {
      let response = await Http.request(
        "contracts",
        "contracts/clients/send-verification",
        { id: client._id, target }
      );
      setVerifyClient(true);
      if (target === "email") {
        setOpen(false);
      }
      setId(response.id);
    } catch (e) {
      console.log(e);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "An error occurred while trying to send account verification codes.",
      });
    }
  }

  async function confirm() {
    try {
      let response = await Http.request(
        "contracts",
        "contracts/clients/confirm-verification",
        {
          id: client._id,
          info: id,
          email,
          sms,
          target,
        }
      );
      Swal.fire({
        icon: "success",
        title: "Hurray!",
        text: "Telephone Number has been successfully verified.",
      }).then(()=>{
        setSms("");
        let _client = {...client};
        _client.smsVerified = true;
        setClient(_client);
        setOpen(false);
        setShow(false);
        setVerifyClient(false);
        createContract();
      })
    } catch (e) {
      console.log(e);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "An error occurred while trying to confirm account verification.",
      });
    }
  }

  async function cancel() {
    setOpen(false);
    if (setShow) {
      setShow(false);
      setVerifyClient(false);
    }
  }

  return (
    <div>
      {target === "email" && (
        <AccessControl
          permission={"verifyClientInformation"}
          fallback={<></>}
          target={["god"]}
        >
          <svg
            onClick={() => {
              setOpen(true);
            }}
            xmlns="http://www.w3.org/2000/svg"
            className="grid-cols-1 ml-3 mt-1 h-4 w-4 cursor-pointer hover:red-500"
            viewBox="0 0 512 512"
          >
            <path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z" />
          </svg>
        </AccessControl>
      )}
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          static
          onClose={() => null}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div style={{ zIndex: 1000000 }}>
                    <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-red-100">
                      <XCircleIcon
                        className="h-6 w-6 text-red-600"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900"
                      >
                        Client Not Verified
                      </Dialog.Title>
                      <div className="mt-2">
                        {!verifyClient && (
                          <p className="text-sm text-gray-500">
                            Good Day, In order to create a contract you will
                            have to ensure that the client's email and telephone
                            number has been verified. A verification code will
                            be sent to
                            {target === "email" && (
                              <>
                                email -{" "}
                                <strong>
                                  {client?.contact?.email?.toLowerCase() ||
                                    client?.businessEmailAddress?.toLowerCase()}
                                </strong>{" "}
                              </>
                            )}
                            {target === "mobile" && (
                              <>
                                {" "}
                                telephone -{" "}
                                <strong>
                                  {client?.contact?.mobilePhone ||
                                    client?.businessPhoneNumberOne}
                                </strong>
                              </>
                            )}
                            .
                          </p>
                        )}
                        {verifyClient && (
                          <>
                            {target !== "email" && (
                              <div className="sm:col-span-4 mt-2">
                                <label
                                  htmlFor="email"
                                  className="block text-left text-sm font-medium leading-6 text-gray-900"
                                >
                                  Telephone Verification Code
                                </label>
                                <div className="mt-2">
                                  <input
                                    disabled={false}
                                    value={sms}
                                    onChange={(e) => {
                                      setSms(e.target.value);
                                    }}
                                    type="text"
                                    placeholder={
                                      "Enter code sent to the client's telephone."
                                    }
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                    {!verifyClient && (
                      <button
                        type="button"
                        className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                        onClick={() => sendVerificationCode()}
                      >
                        Verify
                      </button>
                    )}
                    {verifyClient && (
                      <button
                        type="button"
                        className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                        onClick={() => confirm()}
                      >
                        Confirm
                      </button>
                    )}
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                      onClick={() => {
                        cancel();
                      }}
                      ref={cancelButtonRef}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}
