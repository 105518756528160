import React from "react";
import moment from "moment";
import Http from "../../../../util/http";
import Swal from "sweetalert2";
import { findFlagUrlByIso3Code } from "country-flags-svg";

import { useEffect, useState } from "react";
import ConsumerClientInfoVerifyModal from "../../../../components/ConsumerClientInfoVerifyModal";
import http from "../../../../util/http";
import Infopib from "../../../../components/infopib";
import VerifyClientModal from "../../../../components/dashboard/verifyclient";
import SlimSchedule from "../../../../components/dashboard/slimschedule";
import {sortListAlphabeticallyByKey} from "../../../../util/util";

export default function CreateRetailContract() {
  const [showBreakdown,setShowBreakDown] = useState(false)
  const [client, setClient] = useState({
    personal: {},
    contact: {},
    address: {},
  });
  const [info, setInfo] = useState({
    premium: 0,
    months: 0,
    startDate: "",
    paymentDate: "",
    policyType: "",
    paymentType: "cash",
    insuranceCompany: "",
    policyNumber: "",
    policyEndDate: "",
    policyStartDate: moment().format("YYYY-MM-DD"),
  });
  const [summary, setSummary] = useState({
    data: [],
    headers: {},
  });
  const [modalToggle, setModalToggle] = useState(false);
  const [coverTypes, setCoverTypes] = useState([]);
  const [modalAction, setModalAction] = useState(0);
  const [commUser, setCommUser] = useState({
    _id: "",
    firstname: "",
    middlename: "",
    lastname: "",
    contactEmail: "",
    contactMobilePhone: "",
    contactHousePhone: "",
    contactWorkPhone: "",
    addressFirstLine: "",
    addressSecondLine: "",
    addressCityOrTown: "",
    addressStateOrProvince: "",
    addressCountry: "",
    dateOfBirth: "",
    placeOfBirth: "",
    nationality: "",
    idType: "",
    id: "",
    issuedDate: "",
    expiryDate: "",
    politicallyExposed: false,
    employmentOccupation: "",
    employmentEmployer: "",
    employmentPosition: "",
    employmentSourceOfWealth: "",
  });
  const [organization, setOrganization] = useState(null);
  const [companies, setCompanies] = useState([]);
  const [openVerify, setOpenVerify] = useState(false);

  useEffect(() => {
    async function Init() {
      setOrganization(JSON.parse(localStorage.organization));
      const contractDraft = window.localStorage.getItem("contractDraft");
      if (typeof contractDraft !== "undefined" && contractDraft !== null) {
        const contractDraftContent = JSON.parse(contractDraft);
        if (contractDraftContent.action !== "retail") return;
        console.log({contractDraftContent: contractDraftContent.payload.info.startDate})
        contractDraftContent.payload.info.paymentDate = moment(contractDraftContent.payload.info.startDate).format("YYYY-MM-DD")
        setInfo(contractDraftContent.payload.info);
        try {
          contractDraftContent.payload.info.months = parseInt(
            contractDraftContent.payload.info.months || 0
          );
          contractDraftContent.payload.info.premium = parseFloat(
            contractDraftContent.payload.info.premium || 0
          );
          contractDraftContent.payload.info.type = "retail";
          contractDraftContent.payload.info.policyStartDate = moment().format("YYYY-MM-DD");
         // window.localStorage.removeItem("contractDraft");
          let response = await Http.request(
            "contracts",
            "contracts/amortise",
            contractDraftContent.payload.info
          );
          setSummary(response);
        } catch (err) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: err.message,
          });
        }
      } else {
        window.localStorage.removeItem("contractDraft");
      }
    }

    async function fetchAPI() {
      let dialog = Swal.fire({
        title: "Loading...",
        timerProgressBar: true,
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
        willClose: () => {},
      });
      try {
        let response = await Http.request(
          "contracts",
          "clients/retail/get-one",
          { id: window.location.search.replace("?id=", "") }
        );
        console.log({ response });
        setClient(response);
        await getCoverTypes();
        await getCompanies();
        dialog.close();
      } catch (e) {
        dialog.close();
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Failed to make request to servers.",
        }).then(() => {
          window.location.href = `${window.location.origin}/dashboard/clients/not-found`;
        });
      }
    }

    fetchAPI().then(() => {
      Init();
    });
  }, []);
  const delay = (delayInms) => {
    return new Promise((resolve) => setTimeout(resolve, delayInms));
  };

  async function changeVal(val, key) {
    // console.log("Fields have been disabled")
    // return;
    let payload = { ...info };
    payload[key] = val;
    if (key === "startDate") {
      let _date = moment(val).add(1, "months").format().substring(0, 10);
      payload.paymentDate = _date;
      console.log({ payload });
    }
    setInfo(payload);
    await delay(1000);
    //let isBetween = inRange(payload);
    await getSummary(payload);
  }

  async function createContract() {
    let dialog = Swal.fire({
      title: "Loading...",
      timerProgressBar: true,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
      willClose: () => {},
    });
    try {
      if (summary.data.length === 0) {
        return;
      } else {
        let payload = { ...info };
        payload.summary = summary;
        payload.client = client;
        payload.user = localStorage.getItem("user")._id;
        let response = await Http.request(
          "contracts",
          "contracts/retail/create",
          payload
        );
        setModalToggle(false);
        dialog.close();
        Swal.fire({
          icon: "success",
          title: "Hurray!",
          text: `Client retail contract created successfully. Contract will be returned to your inbox shortly`,
        }).then(() => {
          window.location.href = `${
            window.location.origin
          }/dashboard/clients/retail/profile/${window.location.search.replace(
            "?id=",
            ""
          )}`;
        });
      }
    } catch (err) {
      dialog.close();
      setModalToggle(false);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Failed to create a contract for this client. Please contact Solis staff.",
      });
    }
  }

  function saveModalData(obj) {
    //let isvalidPolicyEnd = isContractPeriodValid();
    console.log({obj})
    let isvalidPolicyEnd = true;
    if (!isvalidPolicyEnd) return;
    const personal = {
      firstname: obj.firstname,
      middlename: obj.middlename,
      lastname: obj.lastname,
      dateOfBirth: obj.dateOfBirth,
      placeOfBirth: obj.placeOfBirth,
      nationality: obj.nationality,
      idType: obj.idType,
      id: obj.id,
      issuedDate: obj.issuedDate,
      expiryDate: obj.expiryDate,
      politicallyExposed: obj.politicallyExposed,
    };

    const address = {
      addressFirstLine: obj.addressFirstLine,
      addressSecondLine: obj.addressSecondLine,
      addressCityOrTown: obj.addressCityOrTown,
      addressStateOrProvince: obj.addressStateOrProvince,
      addressCountry: obj.addressCountry,
    };
    const employment = {
      occupation: obj.employmentOccupation,
      employer: obj.employmentEmployer,
      position: obj.employmentPosition,
      sourceOfWealth: obj.employmentSourceOfWealth,
    };
    const contact = {
      email: obj.contactEmail,
      mobilePhone: obj.contactMobilePhone,
      housePhone: obj.contactHousePhone,
      workPhone: obj.contactWorkPhone,
    };
    let retail = {
      personal,
      address,
      employment,
      contact,
    };
    let expired = isIdExpired(personal);
    console.log({expired})
    if (!expired) {
      http
        .request(`contracts`, "clients/retail/update-one", {
          id: obj._id,
          retail,
        })
        .then((result) => {
          Swal.fire({
            icon: "success",
            title: "Hurray!",
            text: `Retail client information saved`,
          })
            .then(() => {
              setModalToggle(false);
              if(process.env.REACT_APP_USE_SMS === 'true'){
                console.log('Now using sms verification.')
                setOpenVerify(true)
              }else{
                createContract();
              }
            })
            .catch((e) => {
              createContract();
            });
        });
    } else {
      //setModalToggle(false);
    }
  }

  async function setDay(day) {
    // console.log("button disabled");
    // return;
    let paymentDate;
    let daysInMonth = moment(info.startDate).add(1, "months").daysInMonth();
    if (day === 30 && daysInMonth < 30) {
      day = daysInMonth;
    }
    paymentDate = moment(info.startDate)
      .set({ date: day })
      .add(1, "month")
      .format()
      .substring(0, 10);
    if (
      info.paymentDate !== "" &&
      info.paymentDate !== undefined &&
      info.paymentDate !== null
    ) {
      paymentDate = moment(new Date(`${info.startDate}`))
        .add(1, "month")
        .set({ date: day })
        .format()
        .substring(0, 10);
    }

    let payload = { ...info };
    payload.paymentDate = paymentDate;
    setInfo(payload);
    getSummary(payload);
  }

  async function getSummary(payload) {
    // let ids = ["premium", "months", "policyStart", "paymentDate"];
    // setInvalidFields(ids);
    let isBetween = inRange(payload);
    if (!isBetween) {
      return;
    }
    try {
      if (parseInt(payload.months) > 11 || parseInt(payload.months) < 3) {
        alert("Contract period must be between 3 and 12 months");
        return;
      }
      if (
        payload.premium === 0 ||
        payload.months === undefined ||
        payload.months === 0 ||
        payload.months === "" ||
        payload.startDate === "" ||
        payload.paymentDate === ""
      ) {
        return;
      }
      let validsecond = isvalidSecondPaymentDate(
        payload.startDate,
        payload.paymentDate
      );
      if (!validsecond) {
        return;
      }
      payload.months = parseInt(payload.months || 0);
      payload.premium = parseFloat(payload.premium || 0);
      let response = await Http.request(
        "contracts",
        "contracts/amortise",
        payload
      );
      setSummary(response);
    } catch (err) {
      console.log(err);
    }
  }

  async function setMonth(val) {
    console.log({ val });
    if (val === "") {
      await changeVal("", "months");
      return;
    }

    if (parseInt(val) < 12 || parseInt(val) > 2) {
      await changeVal(val, "months");
      //getSummary()
    }
  }

  function nullTransform(val) {
    if (val === null || val === undefined) {
      return "";
    }
    return val;
  }

  function FormatNumber(str) {
    return str.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  function openModal() {
    let ids = [
      //"premium",
      "paymentType",
      "insuranceCompany",
      "policyNumber",
      "policyType",
      //"months",
      "policyStart",
      "policyEndDate",
      "paymentDate",
    ];
    //setInvalidFields(ids);
    let _info = { ...info };
    _info.paymentType = 'cash'

    _info.policyType = document.getElementById("policyType")?.value;
    _info.insuranceCompany = document.getElementById("insuranceCompany")?.value;
    _info.policyNumber = document.getElementById("policyNumber")?.value;
    _info.policyStartDate = document.getElementById("policyStartDate")?.value;
    console.log({_info})
    setInfo(_info);
    let isBetween = inRange(_info);
    if (!isBetween) {
      Swal.fire({
        icon: "info",
        title: "Opps!",
        // state that start date cannot be older than today
        text: "Please ensure that the start date is not older than today.",
      });
      return;
    }

let emptyFields = [];

if (_info.policyEndDate === undefined || _info.policyEndDate.length === 0) {
  emptyFields.push("policyEndDate");
}
if (_info.policyType === undefined || _info.policyType.length === 0) {
  emptyFields.push("policyType");
}
if (_info.insuranceCompany === undefined || _info.insuranceCompany.length === 0) {
  emptyFields.push("insuranceCompany");
}
if (_info.policyNumber === undefined || _info.policyNumber.length === 0) {
  emptyFields.push("policyNumber");
}
if (_info.premium === undefined || _info.premium <= 0) {
  emptyFields.push("premium");
}
if (_info.policyStartDate === undefined || _info.policyStartDate.length <= 1) {
  emptyFields.push("policyStartDate");
}
if (_info.startDate === undefined || _info.startDate.length <= 1) {
  emptyFields.push("startDate");
}
if (_info.paymentDate === undefined || _info.paymentDate.length <= 1) {
  emptyFields.push("paymentDate");
}

if (emptyFields.length > 0) {
  console.log("Empty fields:", emptyFields);
  setInvalidFields(emptyFields);
  Swal.fire({
    icon: "info",
    title: "Oops...",
    text: "Please ensure that all fields are filled out.",
  });
} else {
  // Continue with the next steps
  setCommUser({
    _id: client._id,
    firstname: client.personal.firstname,
    middlename: client.personal.middlename,
    lastname: client.personal.lastname,
    contactEmail: client.contact.email,
    contactMobilePhone: client.contact.mobilePhone,
    contactHousePhone: client.contact.housePhone,
    contactWorkPhone: client.contact.workPhone,
    addressFirstLine: client.address.addressFirstLine,
    addressSecondLine: client.address.addressSecondLine,
    addressCityOrTown: client.address.addressCityOrTown,
    addressStateOrProvince: client.address.addressStateOrProvince,
    addressCountry: client.address.addressCountry,
    dateOfBirth: client.personal.dateOfBirth,
    placeOfBirth: client.personal.placeOfBirth,
    nationality: client.personal.nationality,
    idType: client.personal.idType,
    id: client.personal.id,
    issuedDate: client.personal.issuedDate,
    expiryDate: client.personal.expiryDate,
    politicallyExposed: client.personal.politicallyExposed,
    employmentOccupation: client.employment.occupation,
    employmentEmployer: client.employment.employer,
    employmentPosition: client.employment.position,
    employmentSourceOfWealth: client.employment.sourceOfWealth,
    smsVerified: client.smsVerified,
    emailVerified: client.emailVerified,
    type: client.type,
  });
  setModalToggle(true);
}
  }

  async function getCoverTypes() {
    try {
      let payload = {
        text: "",
        sort: "",
        total: 0,
        page: 1,
        count: 0,
        perPage: 999,
        type: "",
        companyType: "",
      };
      let response = await Http.request("admin", "coverTypes/get-all", payload);
      if (coverTypes.length < 1) {
        response.coverages = response.coverages.filter(
          (value, index, self) =>
            index === self.findIndex((t) => t.name === value.name)
        );
        let _info = { ...info };
        _info.paymentType = "cash";
        // _info.policyType = response.coverages[0].name;
        _info.policyType = '';
        _info.insuranceCompany = JSON.parse(localStorage.organization)._id;
        setInfo(_info);
        response.coverages = sortListAlphabeticallyByKey(response.coverages, 'name');
        setCoverTypes(response.coverages);
      }
    } catch (err) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Failed to make request to servers.",
      });
    }
  }

  async function getCompanies() {
    try {
      let payload = {
        page: 1,
        sort: "",
        total: 2,
        count: 2,
        perPage: 100,
        companyType: "Insurance Company",
        limit: 100,
      };
      let response = await Http.request(
        "admin",
        "organizations/get-all",
        payload
      );

      let _org = JSON.parse(localStorage.organization);
      if (
        _org.type === "Insurance Company" ||
        _org.type === "BFTTL Administration"
      ) {
        setCompanies([_org]);
      } else {
        response.organizations = sortListAlphabeticallyByKey(response.organizations, 'name');
        setCompanies(response.organizations);
      }
    } catch (e) {
      console.log(e);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Failed to get companies.",
      });
    }
  }

  async function setInvalidFields(ids) {
    for (let i = 0; i < ids.length; i++) {
      try{
        let input = document.getElementById(ids[i]);

        if (input.value === "0" || input.value === "") {
          input.className =
              "block w-9/12 rounded-md border-0 py-1.5 text-red-900 ring-1 ring-inset ring-red-300 placeholder:text-red-300 focus:ring-2 focus:ring-inset focus:ring-red-500 sm:text-sm sm:leading-6";
        } else {
          input.className =
              "block w-9/12 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6";
        }
      }catch (e) {

      }
    }
  }

  function inRange(payload) {
    let isBetween = false;
    try {
      let date = moment(payload.startDate);
      isBetween = date.isBetween(
        moment().subtract(1, "day"),
        moment().add(2, "years")
      );
      console.log({ isBetween, date: date.toDate(), raw: payload.startDate });
    } catch (error) {
      console.log("Error checking date", error);
    }
    return isBetween;
  }

  function isvalidSecondPaymentDate(startDate, secondPaymentDate) {
    var start = moment(startDate);
    var second = moment(secondPaymentDate);
    console.log({ start, second });
    var diff = second.diff(start, "day");
    console.log("diff", diff);
    if (diff > 0 && diff <= 61) {
      console.log("in range");
      return true;
    } else {
      console.log("out of range");
      // Swal.fire({
      //   icon: "info",
      //   title: "Oops...",
      //   text: "Payment Date can only be within a 2 month range of Start Date.",
      // });
      return false;
    }
  }

  function isIdExpired(personal) {
    console.log({ personal });
    if (personal.expiryDate === undefined || personal.expiryDate.length === 0) {
      Swal.fire({
        icon: "info",
        title: "Oops...",
        text: "Please ensure that client id information filled out.",
      });
      return true;
    }
    let _expiryDate = moment(personal.expiryDate);
    let isExpired = _expiryDate.isBefore(
      moment(info.startDate).add(1, "weeks")
    );
    console.log({ isExpired, _expiryDate: _expiryDate.toDate(), info: info });
    if (isExpired) {
      Swal.fire({
        icon: "info",
        title: "Oops...",
        text: "Please use another form of client identification. This one has expired or is expiring soon.",
      });
      return true;
    } else {
      return false;
    }
  }

  function isContractPeriodValid() {
    var ldate = new Date(
      new Date(
        summary.data[summary.data.length - 1]?.dueDate.replaceAll("-", "/")
      ).setHours(0, 0, 0, 0)
    );
    var ppdate = new Date(
      new Date(info.policyEndDate.replaceAll("-", "/")).setHours(0, 0, 0, 0)
    );
    var sdate = new Date(
      new Date(info.startDate.replaceAll("-", "/")).setHours(0, 0, 0, 0)
    );
    var diffDays = parseInt((ldate - ppdate) / (1000 * 60 * 60 * 24), 10);
    var isAfterStart = ppdate > sdate;
    let isValid = false;
    console.log({
      ldate,
      ppdate,
      isValid,
      diffDays,
      isAfterStart,
    });
    if (diffDays >= 30 && isAfterStart === true) {
      isValid = true;
    }
    if (!isValid) {
      Swal.fire({
        icon: "info",
        title:
          "Please review the policy end date it does not meet the requirements.",
        text: "Last payment for the contract should be at least one month prior to the policy end date.",
      });
      return isValid;
    } else {
      return true;
    }
  }

  function startVerify() {
    console.log({months: parseInt(info.months)});
    if (parseInt(info.months) > 11 || parseInt(info.months) < 3) {
      alert("Contract period must be between 3 and 12 months");
    }else{
      setOpenVerify(true);
    }
    
  }

  function nextModal() {
    openModal();
  }

  return (
    <>
      <Infopib />
      <VerifyClientModal
        target={"mobile"}
        show={openVerify}
        setShow={setOpenVerify}
        client={client}
        setClient={setClient}
        openModal={openModal}
        createContract={createContract}
      />
      <div className="mx-20">
        <div className="mt-6 flex justify-center w-100">
          <div className="w-100">
            <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
              New IPF Contract
            </h2>
            <ConsumerClientInfoVerifyModal
              open={modalToggle}
              setOpen={setModalToggle}
              commUser={commUser}
              setCommUser={setCommUser}
              save={saveModalData}
              setOpenVerify={setOpenVerify}
            ></ConsumerClientInfoVerifyModal>
          </div>
        </div>
        <div className="mt-7 flex">
          <div
            className="w-1/12"
            style={{
              border: "1px solid black",
              borderRadius: "12%",
              height: "64px",
              width: "64px",
            }}
          >
            <img style={{}} src="/avatar4.png" alt="Solis Credit" />
          </div>
          <div className="w-11/12 grid ml-4" style={{}}>
            <div className="min-w-100">
              <h2 className="text-xl  leading-7 text-gray-900">
                {nullTransform(client.personal.firstname)}{" "}
                {nullTransform(client.personal.lastname)}
              </h2>
            </div>
            <div className="min-w-100 flex mt-3">
              <div className="mr-2 mt-1" style={{ width: "35px" }}>
                <img
                  className="mb-2"
                  style={{ width: "35px", height: "19px" }}
                  src={findFlagUrlByIso3Code(
                    nullTransform(client.address.addressCountry)
                  )}
                />
              </div>
              <div className="text-sm font-semibold leading-7 text-gray-900">
                {nullTransform(client.clientNumber || "")}
              </div>
              <div className="flex">
                <div className="ml-3 text-sm font-semibold leading-7 text-gray-900">
                  Email:
                </div>
                <div className="ml-2 text-sm leading-7 text-gray-900">
                  {nullTransform(client.contact.email)}
                </div>
              </div>
              <div className="flex">
                <div className="ml-3 text-sm font-semibold leading-7 text-gray-900">
                  {nullTransform(client.personal.idType || "")}:
                </div>
                <div className="ml-2 text-sm leading-7 text-gray-900">
                  {nullTransform(client.personal.id || "")}
                </div>
              </div>
              <div className="flex">
                <div className="ml-3 text-sm font-semibold leading-7 text-gray-900">
                  Phone:
                </div>
                <div className="ml-2 text-sm leading-7 text-gray-900">
                  {nullTransform(client.contact.mobilePhone)}
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr className="mt-7" />

        <div className="flex w-100 pt-12">
          <div className="grid w-1/2 mr-10">

            <div className="text-xl font-bold leading-7 text-gray-900">
              Insurance Company
            </div>
            <div className="text-sm text-gray-500">
              Enter desired insurance company.
            </div>
            <div className="mt-2">
              <select
                  id="insuranceCompany"
                  required
                  onChange={(e) => {
                    changeVal(e.target.value, "insuranceCompany");
                  }}
                  value={info.insuranceCompany}
                  className="mt-2 block w-9/12 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              >
                <option value="">Select Insurance Company</option>
                {companies.map((company,index) => (
                    <option key={index} value={company._id}>{company.name}</option>
                ))}
              </select>
            </div>


            <div className="text-xl font-bold leading-7 text-gray-900 mt-5">
              Policy Number:
            </div>
            <div className="text-sm text-gray-500">
              Enter Policy number here.
            </div>
            <div className="mt-2">
              <input
                  onChange={(e) => {
                    changeVal(e.target.value, "policyNumber");
                  }}
                  value={info.policyNumber}
                  type="text"
                  name="policyNumber"
                  id="policyNumber"
                  className="block w-9/12 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  placeholder="Enter policy number"
              />
            </div>

            <div>
              <div className="text-xl font-bold leading-7 text-gray-900 mt-6">
                Policy Type:
              </div>

              <select
                  id={"policyType"}
                  required
                  onChange={(e) => {
                    changeVal(e.target.value, "policyType");
                  }}
                  value={info.policyType}
                  className="mt-2 block w-9/12 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              >
                <option value="">Select Policy Type</option>
                {coverTypes.map((policy,index) => (
                    <option key={index} value={policy.name}>{policy.name}</option>
                ))}
              </select>
            </div>
            <div className="mt-6">
              <div className="text-xl font-bold leading-7 text-gray-900">
                Policy Start Date:
              </div>
              <div className="text-sm text-gray-500">
                When did your policy start.
              </div>
              <div className="mt-2">
                <input
                    value={info.policyStartDate}
                    onChange={(e) => {
                      changeVal(e.target.value, "policyStartDate");
                    }}
                    type="date"
                    name="policyStartDate"
                    id="policyStartDate"
                    className="block w-9/12 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <div className="mt-6">
              <div className="text-xl font-bold leading-7 text-gray-900">
                Preferred Payment Date:
              </div>
              <div className="text-sm text-gray-500">
                What day of the month will regular payments be made?
              </div>
              <div className="mt-2 flex">
                <input
                    value={info.paymentDate}
                    onChange={(e) => {
                      changeVal(e.target.value, "paymentDate");
                    }}
                    min={new Date().toISOString().split('T')[0]} // Sets the minimum selectable date to today
                    type="date"
                    name="start"
                    id="paymentDate"
                    max={moment().endOf("month").format("YYYY-MM-DD")}
                    className="block w-4/12 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
                <div className="flex w-8/12 ml-3">
                  <span
                      onClick={() => {
                        setDay(15);
                      }}
                      style={{cursor: "pointer"}}
                      className="flex justify-center rounded-full bg-gray-300 px-5 py-1 text-sm font-medium text-gray-600 pt-2"
                  >
                    15
                  </span>
                  <span
                      onClick={() => {
                        setDay(25);
                      }}
                      style={{cursor: "pointer"}}
                      className="flex justify-center rounded-full bg-gray-300 px-5 py-1 text-sm font-medium text-gray-600 pt-2 ml-2"
                  >
                    25
                  </span>
                  <span
                      onClick={() => {
                        setDay(30);
                      }}
                      style={{cursor: "pointer"}}
                      className="flex justify-center rounded-full bg-gray-300 px-5 py-1 text-sm font-medium text-gray-600 pt-2 ml-2"
                  >
                    30
                  </span>
                </div>
              </div>
            </div>


            <div className="flex items-center mt-6">
              <label className="block text-xl font-medium leading-6 text-gray-900 mr-2">
                Is this asset a mortgage?
              </label>
              <input
                  type="checkbox"
                  onChange={(e) => {
                    changeVal(e.target.checked, "mortgage");
                  }}
                  checked={info.mortgage}
                  className=" block rounded-md px-3 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          <div className="grid w-1/2">
            <div>
              <div className="text-2xl leading-7 text-gray-900">Summary</div>
              <div className="grid pt-4">
                <div className="text-lg leading-6 text-gray-900 w-100">
                  Total Premium Financed:
                </div>
                <div className="text-sm font-bold leading-6 text-gray-900 w-100">
                  $
                  {FormatNumber(
                      nullTransform(
                          parseFloat(summary?.submission?.principal || 0).toFixed(2)
                      )
                  )}
                </div>
              </div>
              <div className="grid pt-2 mb-2">
                <div className="text-lg leading-6 text-gray-900 w-100">
                  First Payment Date:
                </div>
                <div className="text-sm font-bold leading-6 text-gray-900 w-100">
                  {info.startDate}
                </div>
              </div>
              <div className="grid mb-2">
                <div className="text-lg leading-6 text-gray-900 w-100">
                  Policy End Date:
                </div>
                <div className="text-sm font-bold leading-6 text-gray-900 w-100">
                  {info.policyEndDate}
                </div>
              </div>
              <div className="grid mb-2">
                <div className="text-lg leading-6 text-gray-900 w-100">
                  Loan Tenure:
                </div>
                <div className="text-sm font-bold leading-6 text-gray-900 w-100">
                  {summary.data.length || 0} Months
                </div>
              </div>
              <div className="grid mb-2">
                <div className="text-lg leading-6 text-gray-900 w-100">
                  Monthly Installment:
                </div>
                <div className="text-sm font-bold leading-6 text-gray-900 w-100">
                  {summary.data.length > 0 && (
                      <>
                        $
                        {FormatNumber(
                            nullTransform(
                                parseFloat(summary?.raw?.props?.monthlyInstalment || 0).toFixed(2) ||
                                ""
                            )
                        )}
                      </>
                  )}
                </div>
              </div>

              <div className="flex">
                <div className="grid w-1/3">
                  <a
                      href={"/dashboard/clients"}
                      type="button"
                      className="rounded-md text-center bg-orange-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600"
                  >
                    Cancel
                  </a>
                </div>
                <div className="grid w-1/3 mx-2">
                  <button
                      onClick={() => {
                        openModal();
                      }}
                      type="button"
                      className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    Create Contract
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr className="mt-7"/>
        <div className="flex w-100 pt-5">
          <div className="grid w-100">

            {showBreakdown === false &&
                <div style={{"cursor": "pointer"}} onClick={() => {
                  setShowBreakDown(true)
                }} className="text-2xl leading-7 text-blue-500 hover:text-blue-700">
                  Show Payment Schedule <i className="fa-solid fa-arrow-down"></i>
                </div>
            }
            {showBreakdown === true &&
                <div style={{"cursor": "pointer"}} onClick={() => {
                  setShowBreakDown(false)
                }} className="text-2xl leading-7 text-gray-900">
                Payment Schedule
                </div>
            }

            {showBreakdown === true &&
                <SlimSchedule summary={summary} type={"retail"}/>
            }
          </div>
        </div>
      </div>
    </>
  );
}