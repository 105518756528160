import {
  getDeepValue,
  getStatus,
  nullTransform,
  FormatNumber,
} from "../../util/util";
import { CurrencyDollarIcon } from "@heroicons/react/24/outline";
import AccessControl from "../../util/accessControl";
import moment from "moment/moment";
import DetailedSchedule from "./DetailedSchedule";
import SummarySchedule from "./SummarySchedule";
import React from "react";
import Http from "../../util/http";
import Swal from 'sweetalert2';
import ViewContract from "./viewContract";

export default function RetailContractView({
  contract,
  contracts,
  getContract,
  cancelLoan,
  link,
}) {


  return (
    <div className="container mx-auto ">
      <div className="grid grid-cols-6 mb-1 items-center">
        <div className="col-span-1">
          <p className="">Select a Contract:</p>
        </div>
        <div className="col-span-3">
          <select
            required
            id="current-tab"
            name="current-tab"
            value={contract._id}
            onChange={(e) => {
              getContract(e.target.value);
            }}
            className="mt-2 block w-full rounded-md border-1 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          >
            <option value="" style={{ textAlign: "center" }}>
              {" "}
              Select Contract{" "}
            </option>
            {contracts?.map((contract, index) => (
              <option
                key={index}
                value={contract._id}
                style={{ textAlign: "center" }}
              >
                {" "}
                {nullTransform(
                  getDeepValue(contract, "loan.loanNumber") || ""
                )}{" "}
                - {nullTransform(getDeepValue(contract, "policyType") || "")}
              </option>
            ))}
          </select>
        </div>
      </div>


      <div className="flex w-11/12 my-5">
        <div className="w-4/12">
          <p className="text-2xl font-bold">
            Loan ID:{" "}
            {nullTransform(getDeepValue(contract, "loan.loanNumber") || "")}
          </p>
        </div>
        <div className="flex w-4/12">
          <div className="w-4/12"></div>
          <div className="w-4/12">
            <span
              className={
                getStatus(contract.loan.status).style + " justify-self-center"
              }
            >
              {getStatus(contract.loan.status).name}
            </span>
          </div>
          <div className="w-4/12"></div>
        </div>
        <div className="w-4/12">
          <span className="isolate inline-flex rounded-md shadow-sm">
            <a
              target="_blank"
              href={link}
              className="relative -ml-px inline-flex items-center bg-indigo-600 px-3 py-2 text-sm font-semibold text-white ring-1 ring-inset ring-gray-300 hover:bg-indigo-500 focus:z-10"
              rel="noreferrer"
            >
              <CurrencyDollarIcon
                className="-ml-0.5 h-5 w-5"
                aria-hidden="true"
              />
              Apply Payment
            </a>
            {["current", "late"].includes(contract?.loan?.status) && (
              <AccessControl
                permission={"instantCancelLoan"}
                fallback={<></>}
                target={["god"]}
              >
                <button
                  onClick={() => {
                    cancelLoan(contract?.loan?.loanNumber);
                  }}
                  type="button"
                  className="relative -ml-px inline-flex items-center bg-red-600 px-3 py-2 text-sm font-semibold text-white ring-1 ring-inset ring-gray-300 hover:bg-red-500 focus:z-10"
                >
                  Cancel Now
                </button>
              </AccessControl>
            )}
            <ViewContract contract={contract}/>
          </span>
        </div>
      </div>

      <div className="mb-10">
        <div className="mb-5">
          <p className="text-3xl mb-5">Contract Name Placeholder</p>
          <div className="grid grid-cols-6 gap-4">
            <div className="col-span-3">
              <div className="grid-rows-2 mb-3">
                <p className="text-xl row-span-1">Policy Number</p>
                <p className="row-span-1">
                  {nullTransform(getDeepValue(contract, "policyNumber") || "")}
                </p>
              </div>

              <div className="grid-rows-2  mb-3">
                <p className="text-xl row-span-1">Premium Financed</p>
                <p className="row-span-1">
                  $
                  {nullTransform(
                    FormatNumber(`${getDeepValue(contract, "loan.principal")}`)
                  )}
                </p>
              </div>

              <div className="grid-rows-2  mb-3">
                <p className="text-xl row-span-1">Premium + Interest</p>
                <p className="row-span-1">
                  $
                  {nullTransform(
                    FormatNumber(
                      parseFloat(
                        `${getDeepValue(contract, "loan.startingBalance")}`
                      ).toFixed(2)
                    )
                  )}
                </p>
              </div>

              <div className="grid-rows-2  mb-3">
                <p className="text-xl row-span-1">Initial Downpayment</p>
                <p className="row-span-1">
                  ${" "}
                  {FormatNumber(
                    nullTransform(
                      parseFloat(
                        contract?.loan?.amortisation[0]?.instalment || 0
                      ).toFixed(2)
                    )
                  )}
                </p>
              </div>

              <div className="grid-rows-2  mb-3">
                <p className="text-xl row-span-1">Regular Monthly Payment</p>
                <p className="row-span-1">
                  $
                  {FormatNumber(
                    parseFloat(contract?.loan?.instalment).toFixed(2)
                  )}
                </p>
              </div>

              <div className="grid-rows-2  mb-3">
                <p className="text-xl row-span-1">Scheduled Payment Date</p>
                <p className="row-span-1">
                  {nullTransform(
                    moment(getDeepValue(contract, "paymentDate"))
                      .add(1, "day")
                      .date()
                  )}
                </p>
              </div>
              <div className="grid-rows-2  mb-3">
                <p className="text-xl row-span-1">Is this a mortgage</p>
                <p className="row-span-1 text-base">
                  {nullTransform(
                    `${getDeepValue(contract, "mortgage")}` || "No"
                  ).toUpperCase()}
                </p>
              </div>
              {contract?.loan?.status === "late" && (
                <div className="grid-rows-2  mb-3">
                  <p className="text-xl row-span-1">Late Days</p>
                  <p className="row-span-1 text-base">
                    {nullTransform(
                      `${getDeepValue(contract, "loan.lateDays")}` || "0"
                    ).toUpperCase()}{" "}
                    Days
                  </p>
                </div>
              )}
            </div>

            <div className="col-span-3">
              <div className="grid-rows-2  mb-3">
                <p className="text-xl row-span-1">Policy Start Date:</p>
                <p className="row-span-1">
                  {nullTransform(
                    getDeepValue(contract, "startDate").substring(0, 10) || ""
                  )}
                </p>
              </div>

              <div className="grid-rows-2  mb-3">
                <p className="text-xl row-span-1">Policy End Date:</p>
                <p className="row-span-1">
                  {nullTransform(
                    getDeepValue(contract, "policyEndDate").substring(0, 10) ||
                      ""
                  )}
                </p>
              </div>
              <div className="grid-rows-2  mb-3">
                <p className="text-xl row-span-1">Disbursed At:</p>
                <p className="row-span-1">
                  {nullTransform(
                    getDeepValue(contract?.loan, "disbursedAt").substring(
                      0,
                      10
                    ) || ""
                  )}
                </p>
              </div>

              <div className="grid-rows-2  mb-3">
                <p className="text-xl row-span-1">IPF Period:</p>
                <p className="row-span-1">
                  {nullTransform(getDeepValue(contract, "months"))}
                </p>
              </div>

              <div className="grid-rows-2  mb-3">
                <p className="text-xl row-span-1">Insurance Period:</p>
                <p className="row-span-1">
                  {nullTransform(
                    moment(getDeepValue(contract, "policyEndDate")).diff(
                      moment(getDeepValue(contract, "startDate")),
                      "months"
                    )
                  )}
                </p>
              </div>

              {contract?.loan?.status !== "new" && (
                <div className="grid-rows-2  mb-3">
                  <p className="text-xl row-span-1">Pay-off Amount:</p>
                  <p className="row-span-1">
                    $
                    {parseFloat(
                      contract?.loan?.collections?.payOffAmount || 0
                    ).toFixed(2)}
                  </p>
                </div>
              )}
              {!["cancelled", "refunded", "withdrawn", "paidUp"].includes(
                contract?.loan?.status
              ) && (
                <div className="grid-rows-2  mb-3">
                  <p className="text-xl row-span-1">Cancellation Status:</p>
                  <p className="row-span-1">
                    {nullTransform(
                      getDeepValue(contract, "loan.cancellationStatus") || ""
                    )}
                  </p>
                </div>
              )}

              <div className="grid-rows-2  mb-3">
                <p className="text-xl row-span-1">Arrears Due:</p>
                <p className="row-span-1">
                  ${parseFloat(contract?.loan?.due?.total || "0").toFixed(2)}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {contract?.loan?.meta?.insuranceDetails?.use_processing === false ||
        (contract?.loan?.meta?.insuranceDetails?.use_processing ===
          undefined && <DetailedSchedule currentContract={contract} />)}

      {contract?.loan?.meta?.insuranceDetails?.use_processing === true && (
        <SummarySchedule currentContract={contract} />
      )}
    </div>
  );
}
