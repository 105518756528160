import {useState, useEffect} from 'react'
import {setDeepValue,formatPhoneNumber} from '../../../util/util'
import Http from '../../../util/http'
import Swal from 'sweetalert2'
import AccessControl from '../../../util/accessControl'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus, faTrash, faPencil, faUsers,faEnvelopesBulk} from "@fortawesome/free-solid-svg-icons";
import OrganizationBranchManageModal from "../../../components/OrganizationBranchManageModal";
import TelephoneInput from '../../../components/dashboard/telephoneInput'
import SearchUserModal from "../../../components/dashboard/SearchUserModal";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function OrganizationInfo() {

    const [organization, setOrganization] = useState({
        _id:'',
        address: {street: '', city: '', state: '', zip: '', country: ''},
        contactInfo: {telephone: '', email: ''},
        name: '',
        type: 'insurers',
        registrationNumber: Date.now(),
        branchInput: '',
        branches: [],
        geolocation:'',
        permissions:[]
    })
    const [countries, setCountries] = useState([]);
    const [organizationClients, setOrganizationClients] = useState([]);
    const [companyTypes, setCompanyTypes] = useState([]);
    const [modalToggle, setModalToggle] = useState(false);
    const [modalAction, setModalAction] = useState('');
    const [modalClients, setModalClients] = useState([]);
    const [modalIndex, setModalIndex] = useState(0);
    const [exportBtnLoading, setExportBtnLoading] = useState(false);
    const [modalBranch, setModalBranch] = useState({
        title: "",
        address: "",
        email: "",
        contactPerson: "",
        telephone: ""
    });

    const [openSearch,setOpenSearch] = useState(false);

    const initialize  = () => {
        getCompanyType()
        Http.request('admin', 'countries', []).then((response) => {
            setCountries(response);
        });

        Http.request('admin', 'organizations/get-one', {id: window.location.pathname.split("/")[3]}).then((response) => {
            response.branchInput = '';
            Http.request('admin', 'branches/get-all', {selectedOrganization: window.location.pathname.split("/")[3]}).then((branchResponse) => {
                response.branches = branchResponse;
                setOrganization(response);
            }).catch((e) => {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Failed to make request to servers.',
                })
            })
        }).catch((e) => {
            console.log(e)

            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Failed to make request to servers.',
            })
        })
        Http.request('admin', 'users/get-by-organization', {selectedOrganization: window.location.pathname.split("/")[3]}).then((response) => {
            const holder = [];
            response.map((client) => {
                holder.push({_id:client._id, name:`${client.firstName} ${client.lastName} (${client.position})`})
            });
            setModalClients(holder);
        }).catch((e) => {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Failed to make request to servers.',
            })
        })
    }

    const getAllUsers = async () => {
        try
          {
            setExportBtnLoading(true)
            const dateName = (new Date().toDateString()).replaceAll(" ","_") + "_" + (new Date().toTimeString()).replaceAll(" ","_");
            Http.request('contracts','users/export/get-all', {id: window.location.pathname.split("/")[3]}).then((response)=>{
              const url = window.URL.createObjectURL(new Blob([new Uint8Array(response.buffer.data)]))
              const a = document.createElement('a')
              a.setAttribute('href', url)
              a.setAttribute('download', `${dateName}_excel_export_.xlsx`);
              a.click()
                setExportBtnLoading(false)
          }).catch((e)=>{
              Swal.fire({
                  icon: 'error',
                  title: 'Oops...',
                  text: e.message || 'Failed to make request to servers.',
              }).then(() => {
                    setExportBtnLoading(false)
              })
          })
          }catch(err){
            console.log(err)
          }
      }

    useEffect(() => {
        initialize()
    }, [])

    function setKey(key, value) {
        console.log({key: key, value: value})
        let object = {...organization}
        setDeepValue(object, value, key)
        setOrganization(object)
    }

    function getCompanyType() {
        Http.request('admin', 'companyTypes/get-all', {
            text: '',
            sort: '',
            total: 0,
            page: 1,
            count: 0,
            perPage: 999,
            type: '',
            companyType: ''
        }).then((response) => {
            setCompanyTypes(response.coverages)
        })
    }

    function deleteBranch(idx) {
        Swal.fire({
            title: 'Are you sure?',
            text: "The currently selected branch will be permanently removed from the system when organization is saved!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
        }).then((result) => {
            if (result.isConfirmed) {
                const match = organization.branches.splice(idx,1);
                try {
                    Http.request('admin', 'branches/remove-one', {id:match[0]._id}).then((response) => {
                        const newList = organization.branches.filter((e) => e !== match);
                        setKey('branches', newList);
                    }).catch((err) => {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: err.message || 'Failed to make request to servers.',
                        })
                    })
                } catch (err) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: err.message || 'Failed to make request to servers.',
                    })
                }
            }
        })
    }

    function editBranch(idx) {
        setModalAction("edit");
        setModalIndex(idx);
        setModalBranch(organization.branches[idx]);
        setModalToggle(true);
    }

    function addBranch() {
        setModalAction("create");
        setModalIndex(null);
        setModalToggle(true);
    }

    function saveBranch(branchInfo,index, action){
        let dialog = Swal.fire({
      title: 'Loading...',
      timerProgressBar: true,
      allowOutsideClick: false, 
      didOpen: () => {
        Swal.showLoading()
      },
      willClose: () => {
      }
    })
        branchInfo.selectedOrganization = organization._id;
        if(action === "create"){
            try {
                delete branchInfo._id;
                Http.request('admin', 'branches/create', branchInfo).then((response) => {
                    organization.branches.push(response);
                    setModalToggle(false);
                    dialog.close()
                }).catch((err) => {
                    dialog.close()
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: err.message || 'Failed to make request to servers.',
                    })
                })
            } catch (err) {
                dialog.close()
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: err.message || 'Failed to make request to servers.',
                })
            }
        }else{
            try {
                let params = {...branchInfo};
                delete params._id;
                Http.request('admin', 'branches/update-one', {id:branchInfo._id, branchInfo:params}).then((response) => {
                    organization.branches[index] = response;
                    initialize()
                    setModalToggle(false);
                    dialog.close()
                }).catch((err) => {
                    dialog.close()
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: err.message || 'Failed to make request to servers.',
                    })
                })
            } catch (err) {
                dialog.close()
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: err.message || 'Failed to make request to servers.',
                })
            }
        }
    }

    function goToUsers(branch){
        window.location.href = `${window.location.origin}/dashboard/users?organization=${branch.organization}&branch=${branch._id}`
    }

    function save(e) {
        let dialog = Swal.fire({
            title: 'Loading...',
            timerProgressBar: true,
            allowOutsideClick: false, 
            didOpen: () => {
              Swal.showLoading()
            },
            willClose: () => {
            }
          })
        e.preventDefault();
        try {
            delete organization.branchInput;
            let payload = {...organization}
            delete payload._id
            delete payload.branches
            Http.request('admin', 'organizations/update-one', {
                id: organization._id,
                organizationData: payload
            }).then((response) => {
                dialog.close()
                window.location = `${window.location.origin}/dashboard/organizations`
            }).catch((err) => {
                console.log(err)
                dialog.close()
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Failed to make request to servers.',
                })
            })
        } catch (err) {
            dialog.close()
            console.log(err)
        }
    }

    function remove(e) {
        e.preventDefault();
        try {
            Swal.fire({
                title: 'Remove Organization!',
                text: "This action is not reversible. Are you sure?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: '#3085d6',
                confirmButtonText: 'Delete It!'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    Http.request('admin', 'organizations/remove-one', {id: organization._id}).then((response) => {
                        console.log(response)
                        Swal.fire({
                            icon: 'success',
                            title: 'Hurray!',
                            text: 'Organization removed successfully',
                        }).then(() => {
                            window.location = `${window.location.origin}/dashboard/organizations`
                        });
                    }).catch((err) => {
                        console.log(err)
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'Failed to make request to servers.',
                        })
                    })
                }
            })

        } catch (err) {
            console.log(err)
        }
    }
    function invite(branch) {
        try 
        {
            
            Swal.fire({
                icon: 'info',
                title: 'Attention!',
                text: 'Do you want to send out account invitations?',
                showCancelButton: true,
                confirmButtonText: "Send",
              }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    Http.request('admin', 'users/branch-invite', {branch: branch._id, organization:branch.organization}).then((response) => {
                        Swal.fire({
                            icon: 'success',
                            title: 'Hurray!',
                            text: 'Account invitation information sent successfully.',
                        })
                    }).catch((err) => {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'Failed to make request to servers.',
                        })
                    })
                }
            });
        } catch (err) {
            console.log(err)
        }
    }
    return (
        <div className="shadow sm:overflow-hidden sm:rounded-md ">
            <SearchUserModal setOpen={setOpenSearch} open={openSearch} organization={organization}/>
            <div className="space-y-6 sm:px-6 lg:col-span-9 lg:px-0">
                <div>
                    <div className="shadow sm:overflow-hidden sm:rounded-md">
                        <div className="space-y-6 bg-white px-4 py-6 sm:p-6">
                            <div className="flex justify-between items-start">
                                <div>
                                    <h2 className="text-lg font-semibold leading-6 text-gray-900">Organization
                                        Information</h2>
                                    <p className="mt-1 text-sm text-gray-500">Please ensure all fields are filled in
                                        correctly.</p>
                                </div>
                                <div>
                                    {exportBtnLoading ? (
                                        <button
                                            type="button"
                                            className="inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm cursor-not-allowed"
                                        >
                                            Preparing...
                                        </button>
                                    ) : (
                                        <button onClick={getAllUsers} type="button"
                                                className="relative -ml-px inline-flex items-center bg-indigo-600 px-3 py-2 text-sm font-semibold text-white ring-1 ring-inset ring-gray-300 hover:bg-indigo-500 focus:z-10">Export
                                            users</button>
                                    )}
                                    {/*<button onClick={()=>{setOpenSearch(true)}} type="button"*/}
                                    {/*        className="relative -ml-px inline-flex items-center bg-indigo-600 px-3 py-2 text-sm font-semibold text-white ring-1 ring-inset ring-gray-300 hover:bg-indigo-500 focus:z-10">Search*/}
                                    {/*    users*/}
                                    {/*</button>*/}
                                </div>
                            </div>


                            <div className="grid grid-cols-6 gap-10">
                                <div className="col-span-6 sm:col-span-3">
                                    <label className="block text-sm font-medium leading-6 text-gray-900">
                                    Name
                                    </label>
                                    <input
                                        required
                                        type="text"
                                        onChange={(e) => {
                                            setKey('name', e.target.value)
                                        }}
                                        value={organization.name}
                                        className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>

                                <div className="col-span-6 sm:col-span-3">
                                    <label className="block text-sm font-medium leading-6 text-gray-900">
                                        Registration Number
                                    </label>
                                    <input
                                        type="text"
                                        value={organization.registrationNumber}
                                        onChange={(e) => {
                                            setKey('registrationNumber', e.target.value)
                                        }}
                                        className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>

                                <div className="col-span-6 sm:col-span-3">
                                    <label htmlFor="email-address"
                                           className="block text-sm font-medium leading-6 text-gray-900">
                                        Email address
                                    </label>
                                    <input
                                        value={organization.contactInfo.email}
                                        onChange={(e) => {
                                            setKey('contactInfo.email', e.target.value)
                                        }}
                                        required
                                        type="email"
                                        name="email-address"
                                        id="email-address"
                                        autoComplete="email"
                                        className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                                <div className="col-span-6 sm:col-span-3">
                                    <label htmlFor="telephone"
                                           className="block text-sm font-medium leading-6 text-gray-900">
                                        Telephone
                                    </label>
                                    <TelephoneInput isRequired={true} setKey={setKey} path={'contactInfo.telephone'} value={organization.contactInfo.telephone} className={"mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"}/>
                                    
                                </div>

                                <div className="col-span-6 sm:col-span-3">
                                    <label className="block text-sm font-medium leading-6 text-gray-900">
                                        Organization Type
                                    </label>
                                    <select
                                        id="type"
                                        name="type"
                                        required
                                        autoComplete="type"
                                        value={organization.type}
                                        onChange={(e) => {
                                            setKey('type', e.target.value)
                                        }}
                                        className="mt-2 block w-full rounded-md border-0 bg-white py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    >

                                        <option key={''} value={''}>Choose organization type</option>
                                        {companyTypes.map(item => <option key={item._id}
                                                                          value={item.name}>{item.name}</option>)}
                                    </select>
                                </div>

                                <div className="col-span-6">
                                    <label htmlFor="street-address"
                                           className="block text-sm font-medium leading-6 text-gray-900">
                                        Street address
                                    </label>
                                    <input
                                        type="text"
                                        name="street-address"
                                        id="street-address"
                                        value={organization.address.street}
                                        onChange={(e) => {
                                            setKey('address.street', e.target.value)
                                        }}
                                        required
                                        autoComplete="street-address"
                                        className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>

                                <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                                    <label htmlFor="city" className="block text-sm font-medium leading-6 text-gray-900">
                                        City
                                    </label>
                                    <input
                                        type="text"
                                        name="city"
                                        id="city"
                                        value={organization.address.city}
                                        onChange={(e) => {
                                            setKey('address.city', e.target.value)
                                        }}
                                        required
                                        autoComplete="address-level2"
                                        className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>

                                <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                                    <label htmlFor="region"
                                           className="block text-sm font-medium leading-6 text-gray-900">
                                        State / Province
                                    </label>
                                    <input
                                        type="text"
                                        name="region"
                                        id="region"
                                        autoComplete="address-level1"
                                        value={organization.address.state}
                                        onChange={(e) => {
                                            setKey('address.state', e.target.value)
                                        }}
                                        required
                                        className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>

                                <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                                    <label htmlFor="postal-code"
                                           className="block text-sm font-medium leading-6 text-gray-900">
                                        ZIP / Postal code
                                    </label>
                                    <input
                                        type="text"
                                        name="postal-code"
                                        id="postal-code"
                                        autoComplete="postal-code"
                                        value={organization.address.zip}
                                        onChange={(e) => {
                                            setKey('address.zip', e.target.value)
                                        }}
                                        className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>

                                <div className="col-span-6">
                                    <label className="block text-sm font-medium leading-6 text-gray-900">
                                        Country
                                    </label>
                                    <select
                                        required
                                        disabled
                                        id="country"
                                        name="country"
                                        value={organization.address.country}
                                        onChange={(e) => {
                                            setKey('address.country', e.target.value)
                                        }}
                                        className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    >
                                        <option value=""> Select a Country</option>
                                        {countries.map(item => <option key={item.code}
                                                                       value={item.code}> {item.title} </option>)}
                                    </select>
                                </div>
                            </div>

                            <div className="mb-10">
                                <div className="mb-5">
                                    <div>
                                        <h2 className="text-lg font-semibold leading-6 text-gray-900">Organization
                                            Branches</h2>
                                        <p className="mt-1 text-sm text-gray-500">Add the name of any branches
                                            affiliated with this organization.</p>
                                    </div>
                                </div>
                                <OrganizationBranchManageModal
                                    open={modalToggle}
                                    setOpen={setModalToggle}
                                    action={modalAction}
                                    clients={modalClients}
                                    index={modalIndex}
                                    branchInfo={modalBranch}
                                    organization={organization}
                                    save={saveBranch}
                                >
                                </OrganizationBranchManageModal>
                                <ul role="list" className="divide-y divide-y-8 divide-white">
                                    {organization.branches.map((branch, index) => (
                                        <li key={index} className="flex bg-stone-200">
                                            <div className="p-5 grid grid-cols-12">
                                                <div className="col-span-3">
                                                    <span className="font-bold">Title:</span>&nbsp;
                                                    <span>{branch.title}</span>
                                                </div>
                                                <div className="col-span-3">
                                                    <span className="font-bold">Address:</span> &nbsp;
                                                    <span>{branch.address}</span>
                                                </div>
                                                <div className="col-span-3">
                                                    <span className="font-bold">Email:</span>&nbsp;
                                                    {branch?.email !== null && (
                                                        <>
                                                            <a
                                                                className="transititext-primary text-primary transition duration-150 ease-in-out hover:text-primary-600 focus:text-primary-600 active:text-primary-700 dark:text-primary-400 dark:hover:text-primary-500 dark:focus:text-primary-500 dark:active:text-primary-600"
                                                                data-te-toggle="tooltip"
                                                                title={branch?.email}
                                                            >{branch?.email?.substring(0,20)}....</a
                                                            >
                                                        </>
                                                    )}

                                                </div>
                                                <div className="col-span-3">
                                                    <span className="font-bold">Telephone:</span>&nbsp;
                                                    <span>{formatPhoneNumber(branch.telephone)}</span>
                                                </div>
                                                <div className="col-span-2">
                                                    <div>Type: {branch.type}</div>
                                                    <div className='flex'>
                                                    <button
                                                        type="button"
                                                        onClick={() => {
                                                            editBranch(index)
                                                        }}
                                                        className="rounded-full h-10 w-10 bg-blue-700 mr-2">
                                                        <FontAwesomeIcon
                                                            icon={faPencil}
                                                            className="font-medium text-2xl leading-7 mt-1"
                                                            style={{color: "white"}}
                                                        />
                                                    </button>
                                                    <AccessControl fallback={<></>} permission={'deleteOrganizationBranch'} target={['god']}>
                                                        <button
                                                            type="button"
                                                            onClick={() => {
                                                                deleteBranch(index)
                                                            }}
                                                            className="rounded-full h-10 w-10 bg-red-700 mr-2">
                                                            <FontAwesomeIcon
                                                                icon={faTrash}
                                                                className="font-medium text-2xl leading-7 mt-1"
                                                                style={{color: "white"}}
                                                            />
                                                        </button>
                                                    </AccessControl>
                                                    <button
                                                        type="button"
                                                        onClick={() => {
                                                            goToUsers(branch)
                                                        }}
                                                        className="rounded-full h-10 w-10 bg-sky-700">
                                                        <FontAwesomeIcon
                                                            icon={faUsers}
                                                            className="font-medium text-2xl leading-7 mt-1"
                                                            style={{color: "white"}}
                                                        />
                                                    </button>
                                                    <button
                                                        type="button"
                                                        onClick={() => {
                                                            invite(branch)
                                                        }}
                                                        className="rounded-full h-10 w-10 bg-green-700 ml-2">
                                                        <FontAwesomeIcon
                                                            icon={faEnvelopesBulk}
                                                            className="font-medium text-2xl leading-7 mt-1"
                                                            style={{color: "white"}}
                                                        />
                                                    </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    ))}
                                    <li key={organization.branches.length} className="flex bg-stone-200">
                                        <div className="p-5 grid grid-cols-2">
                                            <div className="col-span-1">
                                                <button
                                                    type="button"
                                                    onClick={() => {
                                                        addBranch()
                                                    }}
                                                    className="rounded-full h-10 w-10 bg-lime-600">
                                                    <FontAwesomeIcon
                                                        icon={faPlus}
                                                        className="font-medium text-2xl leading-7 mt-1"
                                                        style={{color: "white"}}
                                                    />
                                                </button>
                                            </div>
                                            <div className="col-span-1">
                                                <p>Add Branch</p>
                                            </div>

                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="bg-gray-100 px-4 py-3 text-right sm:px-6">
                            <a href='/dashboard/organizations' type="button"
                               className="text-sm font-semibold leading-6 text-gray-900 mx-3">Cancel</a>
                            <AccessControl fallback={<></>} permission={'removeOrganization'} target={['god']}>
                                <button
                                    onClick={(e) => {
                                        remove(e)
                                    }}
                                    type="submit"
                                    className="mx-2 inline-flex justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                                >
                                    Remove
                                </button>
                            </AccessControl>

                            <AccessControl fallback={<></>} permission={'updateOrganization'} target={['god']}>
                                <button
                                    onClick={(e) => {
                                        save(e)
                                    }}
                                    type="submit"
                                    className="inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                >
                                    Save
                                </button>
                            </AccessControl>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


