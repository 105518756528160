import {CheckCircleIcon, XMarkIcon} from '@heroicons/react/20/solid'
import {useState} from "react";
import * as React from "react";
import InAppAnnouncementModal from "./InAppAnnouncementModal";
import moment from "moment";

export default function InAppAnnouncement({title, id, type, view }) {
    const [modalToggle, setModalToggle] = useState(false);
    let detailsId = id;
    let detailsType = type;
    const [theme, setTheme] = useState({});
    const [visible, setVisible] = useState(true);


    React.useEffect(() => {
        switch (type) {
            case "info": {
                setTheme({
                    mainClass: "p-4 mb-4 text-blue-800 border border-blue-300 rounded-lg bg-blue-50 dark:bg-gray-800 dark:text-blue-400 dark:border-blue-800",
                    viewMoreBtn: "text-white bg-blue-800 hover:bg-blue-900 focus:ring-4 focus:outline-none focus:ring-blue-200 font-medium rounded-lg text-xs px-3 py-1.5 mr-2 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800",
                    dismissBtn: "text-blue-800 bg-transparent border border-blue-800 hover:bg-blue-900 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-200 font-medium rounded-lg text-xs px-3 py-1.5 text-center dark:hover:bg-blue-600 dark:border-blue-600 dark:text-blue-400 dark:hover:text-white dark:focus:ring-blue-800"
                });

                break;
            }
            case "success": {
                setTheme({
                    mainClass: "p-4 mb-4 text-green-800 border border-green-300 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400 dark:border-green-800",
                    viewMoreBtn: "text-white bg-green-800 hover:bg-green-900 focus:ring-4 focus:outline-none focus:ring-green-200 font-medium rounded-lg text-xs px-3 py-1.5 mr-2 text-center inline-flex items-center dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800",
                    dismissBtn: "text-green-800 bg-transparent border border-green-800 hover:bg-green-900 hover:text-white focus:ring-4 focus:outline-none focus:ring-green-200 font-medium rounded-lg text-xs px-3 py-1.5 text-center dark:hover:bg-green-600 dark:border-green-600 dark:text-green-400 dark:hover:text-white dark:focus:ring-green-800"
                });
                break;
            }
            case "warning": {
                setTheme({
                    mainClass: "p-4 mb-4 text-yellow-800 border border-yellow-300 rounded-lg bg-yellow-50 dark:bg-gray-800 dark:text-yellow-400 dark:border-yellow-800",
                    viewMoreBtn: "text-white bg-yellow-800 hover:bg-yellow-900 focus:ring-4 focus:outline-none focus:ring-yellow-200 font-medium rounded-lg text-xs px-3 py-1.5 mr-2 text-center inline-flex items-center dark:bg-yellow-600 dark:hover:bg-yellow-700 dark:focus:ring-yellow-800",
                    dismissBtn: "text-yellow-800 bg-transparent border border-yellow-800 hover:bg-yellow-900 hover:text-white focus:ring-4 focus:outline-none focus:ring-yellow-200 font-medium rounded-lg text-xs px-3 py-1.5 text-center dark:hover:bg-yellow-600 dark:border-yellow-600 dark:text-yellow-400 dark:hover:text-white dark:focus:ring-yellow-800"
                });
                break;
            }
            case "critical": {
                setTheme({
                    mainClass: "p-4 mb-4 text-red-800 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 dark:border-red-800",
                    viewMoreBtn: "text-white bg-red-800 hover:bg-red-900 focus:ring-4 focus:outline-none focus:ring-red-200 font-medium rounded-lg text-xs px-3 py-1.5 mr-2 text-center inline-flex items-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800",
                    dismissBtn: "text-red-800 bg-transparent border border-red-800 hover:bg-red-900 hover:text-white focus:ring-4 focus:outline-none focus:ring-red-200 font-medium rounded-lg text-xs px-3 py-1.5 text-center dark:hover:bg-red-600 dark:border-red-600 dark:text-red-400 dark:hover:text-white dark:focus:ring-red-800"
                });
                break;
            }
        }
    }, []);

    function openModal() {
        setModalToggle(true);
    }
    function dissmissAnnouncement(id){
        let dismissed = []
        try{
            let arr = JSON.parse(localStorage.getItem("dismissed"));
            if(arr.length){
                dismissed = arr
            }
        }catch(e){
            console.log(e);
        }
        dismissed.push({
            id: id,
            time: moment().add(1,'day').toDate()
        })

        localStorage.setItem('dismissed',JSON.stringify(dismissed))

    }

    return (
        <div className="mt-5 mr-5 ml-5">
            {visible && (
                <div id="alert-additional-content-1"
                     className={theme.mainClass}
                     role="alert">
                    <div className="flex items-center">
                        <svg className="flex-shrink-0 w-4 h-4 mr-2" aria-hidden="true"
                             xmlns="http://www.w3.org/2000/svg"
                             fill="currentColor" viewBox="0 0 20 20">
                            <path
                                d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
                        </svg>
                        <span className="sr-only">Info</span>
                        <h3 className="text-lg font-medium">{title}</h3>
                    </div>
                    <div className="flex">
                        <button type="button"
                                className={theme.viewMoreBtn}
                                onClick={(e) => {
                                    e.preventDefault();
                                    view(detailsId);
                                    openModal();
                                }}
                        >
                            <InAppAnnouncementModal
                                open={modalToggle}
                                setOpen={setModalToggle}
                                id={detailsId}
                                type={detailsType}
                            >
                            </InAppAnnouncementModal>
                            <svg className="-ml-0.5 mr-2 h-3 w-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                                 fill="currentColor" viewBox="0 0 20 14">
                                <path
                                    d="M10 0C4.612 0 0 5.336 0 7c0 1.742 3.546 7 10 7 6.454 0 10-5.258 10-7 0-1.664-4.612-7-10-7Zm0 10a3 3 0 1 1 0-6 3 3 0 0 1 0 6Z"/>
                            </svg>
                            View more
                        </button>
                        <button type="button"
                                onClick={(e) => {
                                    e.preventDefault();
                                    dissmissAnnouncement(id)
                                    setVisible(false);
                                }}
                                className={theme.dismissBtn}
                                data-dismiss-target="#alert-additional-content-1" aria-label="Close">
                            Dismiss
                        </button>
                    </div>
                </div>
            )}









            {/* {type === "info" && (
                <div className="rounded-md bg-blue-50 p-4">
                    <div className="flex">
                        <div className="flex-shrink-0">
                            <CheckCircleIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />
                        </div>
                        <div className="ml-3 mr-10">
                            <p className="text-sm font-medium text-blue-800">{title}</p>
                        </div>
                        <a href="" onClick={(e) => {
                            e.preventDefault();
                            openModal(link);
                        }} className="whitespace-nowrap font-medium text-blue-700 hover:text-blue-600">
                            View Details
                        </a>
                        <InAppAnnouncementModal
                            open={modalToggle}
                            setOpen={setModalToggle}
                            link={detailsLink}
                        >
                        </InAppAnnouncementModal>
                        <div className="ml-auto pl-3">
                            <div className="-mx-1.5 -my-1.5">
                                <button
                                    type="button"
                                    className="inline-flex rounded-md bg-blue-50 p-1.5 text-blue-500 hover:bg-blue-100 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 focus:ring-offset-blue-50"
                                >
                                    <span className="sr-only">Dismiss</span>
                                    <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {type === "success" && (
                <div className="rounded-md bg-green-50 p-4">
                    <div className="flex">
                        <div className="flex-shrink-0">
                            <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
                        </div>
                        <div className="ml-3 mr-10">
                            <a className="text-sm font-medium text-green-800">{title}</a>
                        </div>
                        <a href="" onClick={(e) => {
                            e.preventDefault();
                            openModal(link);
                        }} className="whitespace-nowrap font-medium text-green-700 hover:text-blue-600">
                            View Details
                        </a>
                        <InAppAnnouncementModal
                            open={modalToggle}
                            setOpen={setModalToggle}
                            link={detailsLink}
                        >
                        </InAppAnnouncementModal>
                        <div className="ml-auto pl-3">
                            <div className="-mx-1.5 -my-1.5">
                                <button
                                    type="button"
                                    className="inline-flex rounded-md bg-green-50 p-1.5 text-green-500 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 focus:ring-offset-green-50"
                                >
                                    <span className="sr-only">Dismiss</span>
                                    <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {type === "warning" && (

            )}
            {type === "critical" && (
                <div className="rounded-md bg-red-50 p-4">
                    <div className="flex">
                        <div className="flex-shrink-0">
                            <CheckCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                        </div>
                        <div className="ml-3 mr-10">
                            <a className="text-sm font-medium text-red-800">{title}</a>
                        </div>
                        <a href="" onClick={(e) => {
                            e.preventDefault();
                            openModal(link);
                        }} className="whitespace-nowrap font-medium text-red-700 hover:text-blue-600">
                            View Details
                        </a>
                        <InAppAnnouncementModal
                            open={modalToggle}
                            setOpen={setModalToggle}
                            link={detailsLink}
                        >
                        </InAppAnnouncementModal>
                        <div className="ml-auto pl-3">
                            <div className="-mx-1.5 -my-1.5">
                                <button
                                    type="button"
                                    className="inline-flex rounded-md bg-red-50 p-1.5 text-red-500 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-offset-2 focus:ring-offset-red-50"
                                >
                                    <span className="sr-only">Dismiss</span>
                                    <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}*/}
        </div>
    )}