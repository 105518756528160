import TelephoneInput from "./telephoneInput";
import React from "react";

export default function RetailClientContactView({
  contact,
  setKeyContact,
  setOpen,
  setCurField,
  setCurAction,
}) {
  return (
    <div>
      <div>
        <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-6 mt-5">
          <div className="lg:col-span-1 md:col-span-3 sm:col-span-3">
            <label className="block text-sm font-medium leading-6 text-gray-900 ml-3">
              Email
            </label>
            <div className="grid grid-cols-3">
              <input
                type="text"
                value={contact.email}
                disabled
                onChange={(e) => {
                  setKeyContact("email", e.target.value);
                }}
                className="mt-2 block w-full col-span-2 rounded-md border-1 px-3 py-1.5 text-gray-900  ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
              <div className="">
                <svg
                  onClick={() => {
                    setOpen(true);
                    setCurField("email");
                    setCurAction("contact");
                  }}
                  xmlns="http://www.w3.org/2000/svg"
                  className="grid-cols-1 ml-10 h-4 w-4 cursor-pointer hover:red-500 mt-5"
                  viewBox="0 0 512 512"
                >
                  <path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z" />
                </svg>
              </div>
            </div>
          </div>

          <div className="lg:col-span-1 md:col-span-3 sm:col-span-3">
            <label className="block text-sm font-medium leading-6 text-gray-900 ml-3">
              Phone (M)
            </label>
            <div className="grid grid-cols-3">
              <TelephoneInput
                setKey={setKeyContact}
                path={"mobilePhone"}
                value={contact.mobilePhone}
                className={
                  "mt-2 block w-full col-span-2 rounded-md border-1 px-3 py-1.5 text-gray-900  ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                }
              />
              <div className="">
                <svg
                  onClick={() => {
                    setOpen(true);
                    setCurField("mobilePhone");
                    setCurAction("contact");
                  }}
                  xmlns="http://www.w3.org/2000/svg"
                  className="grid-cols-1 ml-10 h-4 w-4 cursor-pointer hover:red-500 mt-5"
                  viewBox="0 0 512 512"
                >
                  <path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z" />
                </svg>
              </div>
            </div>
          </div>

          <div className="lg:col-span-1 md:col-span-3 sm:col-span-3">
            <label className="block text-sm font-medium leading-6 text-gray-900 ml-3">
              Phone (H)
            </label>

            <div className="grid grid-cols-3">
              <TelephoneInput
                setKey={setKeyContact}
                path={"housePhone"}
                value={contact.housePhone}
                className={
                  "mt-2 block w-full col-span-2 rounded-md border-1 px-3 py-1.5 text-gray-900  ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                }
              />
              <div className="">
                <svg
                  onClick={() => {
                    setOpen(true);
                    setCurField("housePhone");
                    setCurAction("contact");
                  }}
                  xmlns="http://www.w3.org/2000/svg"
                  className="grid-cols-1 ml-10 h-4 w-4 cursor-pointer hover:red-500 mt-5"
                  viewBox="0 0 512 512"
                >
                  <path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z" />
                </svg>
              </div>
            </div>
          </div>

          <div className="lg:col-span-1 md:col-span-3 sm:col-span-3">
            <label className="block text-sm font-medium leading-6 text-gray-900 ml-3">
              Phone (W)
            </label>

            <div className="grid grid-cols-3">
              <TelephoneInput
                setKey={setKeyContact}
                path={"workPhone"}
                value={contact.workPhone}
                className={
                  "mt-2 block w-full col-span-2 rounded-md border-1 px-3 py-1.5 text-gray-900  ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                }
              />
              <div className="">
                <svg
                  onClick={() => {
                    setOpen(true);
                    setCurField("workPhone");
                    setCurAction("contact");
                  }}
                  xmlns="http://www.w3.org/2000/svg"
                  className="grid-cols-1 ml-10 h-4 w-4 cursor-pointer hover:red-500 mt-5"
                  viewBox="0 0 512 512"
                >
                  <path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z" />
                </svg>
              </div>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-12 gap-6 mt-5">
          <div className="col-span-4 sm:col-span-4"></div>
        </div>
      </div>
    </div>
  );
}
