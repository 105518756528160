import React from "react";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import Http from "../../../util/http";
import Pagination from "../../../components/pagination";
import ClientListItem from "../../../components/dashboard/clientlistitem";
import Clip from "../../../components/clip";
import CommercialClientAmortiseModal from "../../../components/CommercialClientAmortiseModal";
import Infopib from "../../../components/infopib";
import QuotationModal from "../../../components/qoutationModal";
import RetailAmortizeModalComponent from "../../../components/dashboard/retailamortizemodal";

export default function ClientsIndex() {
  const months = useState([2, 3, 6, 11]);
  const [mode, setMode] = useState("");
  const [fields, setFields] = useState([
    { key: "id", value: "ID", text: "" },
    { key: "telephone", value: "Phone No", text: "" },
    { key: "email", value: "Email", text: "" },
    { key: "policyNumber", value: "Policy No", text: "" },
  ]);
  const [field, setField] = useState("name");
  const [empty, setEmpty] = useState(false);
  const [clients, setClients] = useState([]);
  const [paging, setPaging] = useState({
    text: "",
    sort: "",
    total: 0,
    page: 1,
    count: 0,
    perPage: 5,
    organization: "",
  });
  const [modalToggle, setModalToggle] = useState(false);
  const [modalAction, setModalAction] = useState("");
  const [modalLink, setModalLink] = useState("");

  async function search(text) {
    let pagingData = { ...paging };
    pagingData["text"] = text;
    pagingData["mode"] = mode;
    pagingData["field"] = field;
    pagingData["fields"] = fields;
    pagingData.page = 1;
    setPaging(pagingData);
    // let url = window.location.origin + window.location.pathname +'?' +new URLSearchParams(pagingData)
    // window.history.replaceState( {} , 'users', url)
    if (text === "") {
      return;
    }
    getData(pagingData);
  }
  function setTargetField(index, value) {
    let _fields = JSON.parse(JSON.stringify(fields));
    _fields[index].text = value;
    setFields(_fields);
    let pagingData = { ...paging };
    pagingData["fields"] = _fields;
    pagingData["text"] = document.getElementById("email").value;
    getData(pagingData);
  }
  async function getData(pagingData) {
    // let dialog = Swal.fire({
    //   title: 'Loading...',
    //   timerProgressBar: true,
    //   allowOutsideClick: false,
    //   didOpen: () => {
    //     Swal.showLoading()
    //   },
    //   willClose: () => {
    //   }
    // })
    pagingData.text = document.getElementById("email").value;
    Http.request("contracts", `contracts/clients/${mode}/get-all`, pagingData)
      .then((response) => {
        setClients(response.clients);
        setEmpty(true);
        let newPaging = { ...pagingData };
        delete newPaging.text;
        setPaging({
          ...newPaging,
          total: response.total,
          count: response.clients.length,
          perPage: response.perPage,
        });
        //dialog.close()
      })
      .catch((e) => {
        console.log(e);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Failed to make request to servers.",
        });
      });
  }
  function changeMode(nMode) {
    setMode(nMode);
    let pagingData = { ...paging };
    pagingData["mode"] = nMode;
    pagingData["field"] = field;
    pagingData["text"] = "";
    pagingData.page = parseInt(pagingData.page);
    setEmpty(false);
    setClients([]);
    setPaging(pagingData);
    setFields([
      { key: "id", value: "ID", text: "" },
      { key: "telephone", value: "Phone No", text: "" },
      { key: "email", value: "Email", text: "" },
      { key: "policyNumber", value: "Policy No", text: "" },
    ]);
    Swal.fire({
      icon: "info",
      title: "Attention...",
      text: `Search mode has been changed to ${nMode} clients.`,
    });
  }
  function changeField(nField) {
    setField(nField);
    let pagingData = { ...paging };
    pagingData["mode"] = mode;
    pagingData["field"] = nField;
    pagingData.page = parseInt(pagingData.page);
    setPaging(pagingData);
    // let url =
    //   window.location.origin +
    //   window.location.pathname +
    //   "?" +
    //   new URLSearchParams(pagingData);
    //window.history.replaceState({}, "users", url);
    //getData(pagingData)
  }

  function saveModalData(action, payload) {
    window.localStorage.setItem(
      "contractDraft",
      JSON.stringify({ action, payload })
    );
    window.location.href = modalLink;
  }

  function openModal(action, link) {
    console.log("link", link);
    setModalAction(action);
    setModalLink(link);
    setModalToggle(true);
  }

  function resetFilters(){
    setPaging({
      text: "",
      sort: "",
      total: 0,
      page: 1,
      count: 0,
      perPage: 5,
      organization: "",
    })

    setFields([
      { key: "id", value: "ID", text: "" },
      { key: "telephone", value: "Phone No", text: "" },
      { key: "email", value: "Email", text: "" },
      { key: "policyNumber", value: "Policy No", text: "" },
    ])
    setClients([])
    setField("name")
    setEmpty(false)
  }
  useEffect(() => {
    try {
      setMode("retail");
      let pagingData = {};
      if (window.location.search !== "") {
        pagingData = JSON.parse(
          '{"' +
            decodeURI(window.location.search.substring(1))
              .replace(/"/g, '\\"')
              .replace(/&/g, '","')
              .replace(/=/g, '":"') +
            '"}'
        );
        pagingData.text = pagingData.text.replaceAll("+", " ");
        pagingData.page = parseInt(pagingData.page);
        setPaging(pagingData);
      } else {
        pagingData = { ...paging };
        pagingData.page = parseInt(pagingData.page);
        pagingData.mode = mode || "retail";
        pagingData.field = field;
      }
    } catch (e) {
      console.log(e);
    }
  }, []);
  return (
    <>
      <Infopib />
      {
          !process.env.REACT_APP_USE_RETAIL_V2 || mode === 'commercial' &&
          <CommercialClientAmortiseModal
              open={modalToggle}
              setOpen={setModalToggle}
              action={modalAction}
              save={saveModalData}
              name={"New Client"}
          ></CommercialClientAmortiseModal>
      }

      {process.env.REACT_APP_USE_RETAIL_V2 && mode === 'retail'  && (
          <RetailAmortizeModalComponent
              open={modalToggle}
              setOpen={setModalToggle}
              action={modalAction}
              save={saveModalData}
              name={"New Client"}
              client={null}
          />
      )}
      <div className="mx-20">
        <div className="mt-6 grid justify-center w-100">
          <div className="flex justify-center w-100">
            <div className={mode === 'commercial' ? 'w-9/12' :''}>
            <h2 style={{marginLeft: mode=== "commercial" ? '12rem' : ''}} className="text-2xl text-center font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
              {mode.charAt(0).toUpperCase() + mode.slice(1)} Client Search
            </h2>
            </div>
            {mode === 'commercial' &&
              <div className="w-3/12">
                <QuotationModal />
              </div>
            }
          </div>

          <div className="flex justify-center">
            {mode === "retail" && (
              <div
                onClick={() => {
                  changeMode("commercial");
                }}
                className="text-sm leading-6 text-blue-600 hover:text-blue-500 cursor-pointer"
              >
                Switch to Commercial
              </div>
            )}
            {mode === "commercial" && (
              <div
                onClick={() => {
                  changeMode("retail");
                }}
                className="text-sm leading-6 text-blue-600 hover:text-blue-500 cursor-pointer"
              >
                Switch to Retail
              </div>
            )}
          </div>
          <div className="flex justify-content-center">
            <div style={{width: "890px"}} className="mt-2 flex">
              <div className={"w-11/12"}>
                <input
                    value={paging.text}
                    onChange={(e) => {
                      search(e.target.value);
                    }}
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    placeholder="Search client by name.Narrow your search below."
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
              <div className={"w-1/12 ml-2"}>
                <button type="button"
                        onClick={()=>{resetFilters()}}
                        className="rounded-full bg-indigo-600 p-1.5 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                  <i className="fa-solid fa-broom h-5 w-5"></i>
                </button>
              </div>
            </div>

          </div>
          <div className="w-100 flex justify-center mt-4">
            {fields.map((field, index) => {
              return (
                  <Clip
                      setTargetField={setTargetField}
                      field={field}
                      key={index}
                      index={index}
                  />
              );
            })}
          </div>
          <hr className="my-5"/>

          <div className="mt-4">
            {clients.length === 0 && empty === true && (
                <div className=" grid justify-content-center">
                <div className="text-4xl text-gray-400 text-center">
                  No results. Please modify your search.
                </div>
                <div className="text-center mt-2">
                  {" "}
                  or
                  <a
                    onClick={(e) => {
                      openModal(
                        mode,
                        `/dashboard/clients/${mode}/create?state=new`
                      );
                    }}
                    //href={`/dashboard/clients/${mode}/create?state=new`}
                    type="button"
                    className="ml-3 cursor-pointer rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    Create a New Client
                  </a>
                </div>
              </div>
            )}

            {clients.length > 0 && (
              <div className="grid justify-content-center">
                <div className="flex justify-between">
                  <div>
                    {paging.total} client
                    <>{paging.total > 1 && <>s</>}</>
                    <> found</>
                  </div>
                  {/*<a
              href={`/dashboard/clients/${mode}/create`}
              type="button"
              className="ml-3 rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Create a New Client
            </a>*/}
                  <a
                    onClick={(e) => {
                      openModal(
                        mode,
                        `/dashboard/clients/${mode}/create?state=new`
                      );
                    }}
                      //href={`/dashboard/clients/${mode}/create?state=new`}
                    type="button"
                    className="ml-3 cursor-pointer rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    Create a New Client
                  </a>
                </div>
                <div className="grid mt-4">
                  {clients.map((client, index) => {
                    return (
                      <ClientListItem
                        key={index}
                        client={client}
                        index={index}
                      />
                    );
                  })}
                </div>
              </div>
            )}
          </div>

          {clients.length > 0 && (
            <>
              <hr className="mt-3" />
              <Pagination getData={getData} paging={paging} />
            </>
          )}
        </div>
      </div>
    </>
  );
}
