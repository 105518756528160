import React, { Fragment, useState, useRef, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Swal from "sweetalert2";
import http from "../util/http";
import {
  ExclamationTriangleIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { CheckIcon } from "@heroicons/react/24/outline";
import swal from "sweetalert2";
import moment from "moment";
import PreviewTable from "./previewTable";
import QuotationTabs from "./qoutationtab";
export default function QuotationModal() {
  const [open, setOpen] = useState(false);
  const [mode, setMode] = useState("preview");
  const [info, setInfo] = useState({
    name: "",
    email: "",
    premium: 0,
    startDate: "",
    months: "",
    paymentDate: "",
    type: "commercial",
  });
  const [previewInfo, setPreviewInfo] = useState([]);
  const cancelButtonRef = useRef(null);
  const [tabs, setTabs] = useState([
    { name: "6 Months", href: "#", current: true },
    { name: "9 Months", href: "#", current: false },
    { name: "11 Months", href: "#", current: false },
  ]);
  const [preview, setPreview] = useState(null);

  const [summary, setSummary] = useState({
    data: [],
    headers: {
      interest: 2,
    },
  });
  const [premiumMask, setPremiumMask] = useState("$0.00");
  const delay = (delayInms) => {
    return new Promise((resolve) => setTimeout(resolve, delayInms));
  };
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    try {
      // if(mode === 'preview'){
      //   setLoading(true);
      //   getSummaries();
      //   setLoading(false);
      // }
    } catch (error) {
      console.log(error);
    }
  }, [mode]);

  async function changeVal(val, key) {
    let payload = { ...info };
    payload[key] = val;

    if (key === "startDate") {
      let _date = moment(val).add(1, "months").format().substring(0, 10);
      payload.paymentDate = _date;
      console.log({ payload });
    }
    setInfo(payload);
    await delay(1000);
    if (key === "premium") {
      if (isNaN(val) || val <= 0) {
        setPremiumMask("$0.00");
      } else {
        const CurrencyFormat = Intl.NumberFormat("en-us", {
          style: "currency",
          currency: "USD",
        });
        setPremiumMask(CurrencyFormat.format(val));
      }
    }

    //await getSummary(payload);
  }

  async function setDay(day) {
    let paymentDate;
    let daysInMonth = moment(info.startDate).add(1, "months").daysInMonth();
    if (day === 30 && daysInMonth < 30) {
      day = daysInMonth;
    }
    paymentDate = moment(info.startDate)
      .set({ date: day })
      .add(1, "month")
      .format()
      .substring(0, 10);
    if (
      info.paymentDate !== "" &&
      info.paymentDate !== undefined &&
      info.paymentDate !== null
    ) {
      paymentDate = moment(new Date(`${info.startDate}`))
        .add(1, "month")
        .set({ date: day })
        .format()
        .substring(0, 10);
    }

    let payload = { ...info };
    payload.paymentDate = paymentDate;
    setInfo(payload);
    getSummary(payload);
  }

  function isvalidSecondPaymentDate(startDate, secondPaymentDate) {
    var start = moment(startDate);
    var second = moment(secondPaymentDate);
    console.log({ start, second });
    var diff = second.diff(start, "day");
    console.log("diff", diff);
    if (diff > 0 && diff <= 61) {
      console.log("in range");
      return true;
    } else {
      console.log("out of range");
      Swal.fire({
        icon: "info",
        title: "Oops...",
        text: "Payment Date can only be within a 2 month range of Start Date.",
      });
      return false;
    }
  }

  async function getSummary(payload) {
    try {
      if (
        payload.premium === 0 ||
        payload.months === "" ||
        payload.startDate === "" ||
        payload.paymentDate === ""
      ) {
        return;
      }
      let validsecond = isvalidSecondPaymentDate(
        payload.startDate,
        payload.paymentDate
      );
      if (!validsecond) {
        return;
      }
      let isBetween = inRange(payload);
      if (!isBetween) {
        return;
      }

      payload.months = parseInt(payload.months || 0);
      payload.premium = parseFloat(payload.premium || 0);
      // payload.type = info.type;
      let response = await http.request(
        "contracts",
        "contracts/amortise",
        payload
      );
      if (payload.premium > 0 && info.type?.includes("commercial")) {
        let newSummary = { ...summary };
        newSummary.headers.interest = await getInterest(payload.premium);
        setSummary(newSummary);
      }
      setSummary(response);
    } catch (err) {
      console.log(err);
    }
  }

  async function getInterest(premium) {
    try {
      let response = await http.request("contracts", "contracts/get-interest", {
        premium,
      });
      console.log({ response });
      return response.rate;
    } catch (err) {
      console.log(err);
    }
  }

  async function setMonth(val) {
    console.log({ val });
    if (val === "") {
      await changeVal("", "months");
      return;
    }

    if (parseInt(val) < 12) {
      await changeVal(val, "months");
      //getSummary()
    }
  }

  function nullTransform(val) {
    if (val === null || val === undefined) {
      return "";
    }
    return val;
  }

  function FormatNumber(str) {
    return str.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  function inRange(payload) {
    let isBetween = false;
    try {
      let date = moment(payload.startDate);
      isBetween = date.isBetween(
        moment().subtract(1, "day"),
        moment().add(2, "years")
      );
      console.log({ isBetween, date: date.toDate(), raw: payload.startDate });
    } catch (error) {
      console.log("Error checking date", error);
    }
    return isBetween;
  }
  function closeModal() {
    setOpen(false);
    setMode("preview");
    setPreview(null);
    setPreviewInfo([]);
    setInfo({
      name: "",
      email: "",
      premium: 0,
      startDate: "",
      months: "",
      paymentDate: "",
      type: "retail",
    });

    setSummary({
      data: [],
      headers: {
        interest: 2,
      },
    });
  }
  async function send() {
    try {
      if (info.name === "" || info.email === "" || info.premium === 0) {
        return swal.fire("Please check your fields", "", "info");
      }
      let results = await http.request(
        "contracts",
        "contracts/send-quotes",
        info
      );
      swal.fire("Quotation sent successfully", "", "success");
      closeModal();
      console.log(results);
    } catch (e) {
      console.log("Error sending Quotation", e);
      swal.fire("Something went wrong", "", "error");
    }
  }
  async function getSummaries() {
    let months = [6, 9, 11];
    let results = [];

    for (let index = 0; index < months.length; index++) {
      const month = months[index];
      info.months = month;
      try {
        let response = await http.request(
          "contracts",
          "contracts/amortise",
          info
        );
        if (info.premium > 0 && info.type?.includes("commercial")) {
          response.headers.interest = await getInterest(info.premium);
          results.push(response);
        } else {
          results.push(response);
        }
      } catch (err) {
        console.log(err);
      }
    }
    setPreview(results[0]);
    setPreviewInfo(results);
  }
  async function generate(){
    try {
      let data = {
        name: "",
        email: "",
        premium: info.premium,
        startDate: moment().format("YYYY-MM-DD"),
        months: 3,
        paymentDate: "",
        type: "commercial",
      };
      let d = data.startDate.split("-");
      data.paymentDate = moment().add(1, "months").set( d[d.length -1], 'date').format("YYYY-MM-DD");
      setInfo(data);
      setLoading(true);
      setTimeout(async()=>{
        await getSummaries();
        setLoading(false);
      },1000)
    } catch (error) {
      console.log(error)
    }
  }
  return (
    <React.Fragment>
      <button
        style={{ minHeight: "100%" }}
        onClick={(e) => {
          setOpen(true);
        }}
        class="float-right mx-2 px-4 py-1 cursor-pointer font-semibold text-sm bg-cyan-500 text-white rounded-full shadow-sm"
      >
        Create Quotation <i className="fa-solid fa-calculator mx-2"></i>
      </button>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all opacity-100 translate-y-0 sm:scale-100">
                  <div>
                    <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                      <CheckIcon
                        className="h-6 w-6 text-green-600"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900"
                      >
                        Quotation Information
                      </Dialog.Title>
                      {mode === "send" && (
                        <div className="mt-3 ">
                          <div class="w-full w-lg">
                            <div class="flex flex-wrap -mx-3 mb-6">
                              <div class="w-1/2 px-3 mb-6 md:mb-0">
                                <label
                                  class="block uppercase text-left tracking-wide text-gray-700 text-xs font-bold mb-2"
                                  for="grid-first-name"
                                >
                                  Client Name
                                </label>
                                <input
                                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  id="grid-first-name"
                                  type="text"
                                  placeholder="Add client name"
                                  onChange={(e) => {
                                    setInfo({ ...info, name: e.target.value });
                                  }}
                                  value={info.name}
                                />
                              </div>
                              <div class="w-1/2 px-3">
                                <label
                                  class="block uppercase text-left tracking-wide text-gray-700 text-xs font-bold mb-2"
                                  for="grid-last-name"
                                >
                                  Email
                                </label>
                                <input
                                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  id="grid-first-name"
                                  type="email"
                                  placeholder="Add client email"
                                  onChange={(e) => {
                                    setInfo({ ...info, email: e.target.value });
                                  }}
                                  value={info.email}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {mode === "preview" && (
                        <div
                          style={{ minWidth: "48rem" }}
                          className="px-4 sm:px-6 lg:px-8"
                        >
                          <div className="w-full w-lg text-left">
                            <div className="flex w-100 pt-5">
                              <div>
                                <div className="sm:flex sm:items-center">
                                  <div className="sm:flex-auto">
                                    <h1 className="text-base font-bold leading-6 text-gray-900">
                                      Good Day, Please enter premium amount
                                      below.
                                    </h1>
                                    <div className="mb-6 mt-3">
                                      <div className="text-sm font-semibold leading-7 text-gray-900">
                                        Insurance Premium:
                                      </div>
                                      <div className="text-sm text-gray-500">
                                        Total premium to be financed, including
                                        tax.
                                      </div>
                                      <div className="mt-2 flex row">
                                        <input
                                          onChange={(e) => {
                                            changeVal(
                                              parseFloat(e.target.value),
                                              "premium"
                                            );
                                          }}
                                          step="0.01"
                                          type="number"
                                          name="premium"
                                          id="premium"
                                          className="block w-9/12 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                          placeholder="Enter premium"
                                        />
                                        <button
                                        onClick={()=> {generate()}}
                                          type="button"
                                          class="inline-flex ml-3 items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-md text-white bg-indigo-500 hover:bg-indigo-400 transition ease-in-out duration-150 cursor-pointer"
                                          disabled=""
                                        >
                                          Generate
                                        </button>
                                        
                                      </div>
                                      <p>Premium Value: {premiumMask}</p>
                                    </div>
                                    {previewInfo.length !== 0 && (
                                      <p className="mt-2 text-sm text-gray-700">
                                        Below is a break down showing all the
                                        premium at all available policy lengths.
                                      </p>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <>
                            {loading && (
                              <div class="relative rounded-xl overflow-auto p-8">
                                <div class="flex items-center justify-center">
                                  <button
                                    type="button"
                                    class="inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-md text-white bg-indigo-500 hover:bg-indigo-400 transition ease-in-out duration-150 cursor-not-allowed"
                                    disabled=""
                                  >
                                    <svg
                                      class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                    >
                                      <circle
                                        class="opacity-25"
                                        cx="12"
                                        cy="12"
                                        r="10"
                                        stroke="currentColor"
                                        stroke-width="4"
                                      ></circle>
                                      <path
                                        class="opacity-75"
                                        fill="currentColor"
                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                      ></path>
                                    </svg>
                                    Processing...
                                  </button>
                                </div>
                              </div>
                            )}
                          </>
                          <hr className="mt-5"/>
                          <div className="max-h-50">
                            {previewInfo.length !== 0 && !loading && (
                              <div>
                                <QuotationTabs
                                  tabs={tabs}
                                  setTabs={setTabs}
                                  setPreview={setPreview}
                                  previewInfo={previewInfo}
                                />
                                {preview !== null && (
                                  <PreviewTable preview={preview} />
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                      {mode === "send" && (
                        <button
                          type="button"
                          className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                          onClick={() => {
                            send();
                          }}
                        >
                          Send
                        </button>
                      )}
                      {mode === "amortize" && summary.data.length > 0 && (
                        <button
                          type="button"
                          className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                          onClick={() => {
                            setMode("preview");
                          }}
                        >
                          Preview Quotation
                        </button>
                      )}
                      {mode === "preview" && previewInfo.length > 0 && (
                        <button
                          type="button"
                          className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                          onClick={() => {
                            setMode("send");
                          }}
                        >
                          Set Client Information
                        </button>
                      )}
                      <button
                        type="button"
                        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                        onClick={() => {
                          closeModal();
                        }}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </React.Fragment>
  );
}
