import React from "react";


export default function RetailClientDetailsView({
  setKey,
  user,
  setOpen,
  setCurField,
  setCurAction,
  curCountry,
  countries2,
  editing,
  setPoliticallyExposed,
  setKeyAddress,
  address,
  employment,
  politicallyExposed,
  setKeyEmployment,
}) {
  return (
    <div>
      <div>
        <h2 className="text-base font-semibold leading-6 text-gray-900 mb-5">
          Click on a value to change
        </h2>

        <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-6 ">
          <div className="lg:col-span-1 md:col-span-3 sm:col-span-3">
            <label className="block text-sm font-medium leading-6 text-gray-900 ml-3">
              First Name
            </label>
            <div className="grid grid-cols-3">
              <input
                type="text"
                onChange={(e) => {
                  setKey("firstname", e.target.value);
                }}
                disabled
                value={user.firstname}
                className="mt-2 col-span-2 block w-full rounded-md border-1 text-gray-900 ring-inset ring-gray-300 placeholder:text-gray-400  focus:outline-none sm:text-sm sm:leading-6"
              />
              <div className="">
                <svg
                  onClick={() => {
                    setOpen(true);
                    setCurField("firstname");
                    setCurAction("user");
                  }}
                  xmlns="http://www.w3.org/2000/svg"
                  className="grid-cols-1 ml-10 h-4 w-4 cursor-pointer hover:red-500 mt-5"
                  viewBox="0 0 512 512"
                >
                  <path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z" />
                </svg>
              </div>
            </div>
          </div>

          <div className="lg:col-span-1 md:col-span-3 sm:col-span-3">
            <label className="block text-sm font-medium leading-6 text-gray-900 ml-3">
              Middle Name
            </label>
            <div className="grid grid-cols-3">
              <input
                type="text"
                disabled
                onChange={(e) => {
                  setKey("middlename", e.target.value);
                }}
                value={user.middlename}
                className="mt-2 col-span-2 block w-full rounded-md border-1 px-3 py-1.5 text-gray-900  ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
              <div className="">
                <svg
                  onClick={() => {
                    setOpen(true);
                    setCurField("middlename");
                    setCurAction("user");
                  }}
                  xmlns="http://www.w3.org/2000/svg"
                  className="grid-cols-1 ml-10 h-4 w-4 cursor-pointer hover:red-500 mt-5"
                  viewBox="0 0 512 512"
                >
                  <path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z" />
                </svg>
              </div>
            </div>
          </div>

          <div className="lg:col-span-1 md:col-span-3 sm:col-span-3">
            <label className="block text-sm font-medium leading-6 text-gray-900 ml-3">
              Last Name
            </label>
            <div className="grid grid-cols-3">
              <input
                type="text"
                disabled
                value={user.lastname}
                onChange={(e) => {
                  setKey("lastname", e.target.value);
                }}
                className="mt-2 block col-span-2 w-full rounded-md border-1 px-3 py-1.5 text-gray-900  ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
              <div className="">
                <svg
                  onClick={() => {
                    setOpen(true);
                    setCurField("lastname");
                    setCurAction("user");
                  }}
                  xmlns="http://www.w3.org/2000/svg"
                  className="grid-cols-1 ml-10 h-4 w-4 cursor-pointer hover:red-500 mt-5"
                  viewBox="0 0 512 512"
                >
                  <path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z" />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-6 mt-5">
          <div className="lg:col-span-1 md:col-span-3 sm:col-span-3">
            <label className="block text-sm font-medium leading-6 text-gray-900 ml-3">
              Date of Birth
            </label>
            <div className="grid grid-cols-3">
              <input
                type="text"
                disabled
                onChange={(e) => {
                  setKey("dateOfBirth", e.target.value);
                }}
                value={user.dateOfBirth}
                className="mt-2 block w-full col-span-2 rounded-md border-1 px-3 py-1.5 text-gray-900  ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
              <div className="">
                <svg
                  onClick={() => {
                    setOpen(true);
                    setCurField("dateOfBirth");
                    setCurAction("user");
                  }}
                  xmlns="http://www.w3.org/2000/svg"
                  className="grid-cols-1 ml-10 h-4 w-4 cursor-pointer hover:red-500 mt-5"
                  viewBox="0 0 512 512"
                >
                  <path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z" />
                </svg>
              </div>
            </div>
          </div>

          {curCountry.code == "lca" && (
            <div className="lg:col-span-1 md:col-span-3 sm:col-span-3">
              <label className="block text-sm font-medium leading-6 text-gray-900 ml-3">
                Place of Birth
              </label>
              <div className="grid grid-cols-3">
                <input
                  type="text"
                  disabled
                  onChange={(e) => {
                    setKey("placeOfBirth", e.target.value);
                  }}
                  value={user.placeOfBirth}
                  className="mt-2 col-span-2 block w-full rounded-md border-1 px-3 py-1.5 text-gray-900  ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
                <div className="">
                  <svg
                    onClick={() => {
                      setOpen(true);
                      setCurField("placeOfBirth");
                      setCurAction("user");
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    className="grid-cols-1 ml-10 h-4 w-4 cursor-pointer hover:red-500 mt-5"
                    viewBox="0 0 512 512"
                  >
                    <path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z" />
                  </svg>
                </div>
              </div>
            </div>
          )}

          {curCountry.code == "lca" && (
            <div className="lg:col-span-1 md:col-span-3 sm:col-span-3">
              <label className="block text-sm font-medium leading-6 text-gray-900 ml-3">
                Nationality
              </label>
              <div className="grid grid-cols-3">
                <input
                  type="text"
                  disabled
                  onChange={(e) => {
                    setKey("nationality", e.target.value);
                  }}
                  value={user.nationality}
                  className="mt-2 col-span-2 block w-full rounded-md border-1 px-3 py-1.5 text-gray-900  ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
                <div className="">
                  <svg
                    onClick={() => {
                      setOpen(true);
                      setCurField("nationality");
                      setCurAction("user");
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    className="grid-cols-1 ml-10 h-4 w-4 cursor-pointer hover:red-500 mt-5"
                    viewBox="0 0 512 512"
                  >
                    <path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z" />
                  </svg>
                </div>
              </div>
            </div>
          )}

          {/* <div className="lg:col-span-1 md:col-span-3 sm:col-span-3">
                        <label className="block text-sm font-medium leading-6 text-gray-900 ml-3">
                          Nationality
                        </label>
                        <select
                          required
                          onChange={(e) => {
                            setKey("nationality", e.target.value);
                          }}
                          value={user.nationality}
                          className="mt-2 block w-full rounded-md border-1 px-3 py-1.5 text-gray-900  ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        >
                          <option value="">Select Nationality</option>
                          {countries2.map((item) => (
                            <option key={item.code} value={item.code}>
                              {item.title}
                            </option>
                          ))}{" "}
                        </select>
                      </div> */}

          <div className="lg:col-span-1 md:col-span-3 sm:col-span-3">
            <label className="block text-sm font-medium leading-6 text-gray-900 ml-3">
              ID {user.idType}{" "}
            </label>
            <div className="grid grid-cols-3">
              <input
                type="text"
                disabled={true}
                value={`${user.id} / Expires ${user.expiryDate}`}
                className="mt-2 block col-span-2 w-full rounded-md border-1 px-3 py-1.5 text-gray-900  ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
              <div className="">
                <svg
                  onClick={() => {
                    setOpen(true);
                    setCurField("id");
                    setCurAction("user");
                  }}
                  xmlns="http://www.w3.org/2000/svg"
                  className="grid-cols-1 ml-10 h-4 w-4 cursor-pointer hover:red-500 mt-5"
                  viewBox="0 0 512 512"
                >
                  <path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z" />
                </svg>
              </div>
            </div>
          </div>

          {editing == true && (
            <div className="lg:col-span-1 md:col-span-3 sm:col-span-3">
              <label className="block text-sm font-medium leading-6 text-gray-900 ml-3">
                ID
              </label>
              <input
                type="text"
                disabled
                onChange={(e) => {
                  setKey("id", e.target.value);
                }}
                value={user.id}
                className="mt-2 block w-full rounded-md border-1 px-3 py-1.5 text-gray-900  ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          )}

          {editing == true && (
            <div className="lg:col-span-1 md:col-span-3 sm:col-span-3">
              <label className="block text-sm font-medium leading-6 text-gray-900 ml-3">
                Issued Date
              </label>
              <input
                type="date"
                disabled
                onChange={(e) => {
                  setKey("issuedDate", e.target.value);
                }}
                value={user.issuedDate}
                className="mt-2 block w-full rounded-md border-1 px-3 py-1.5 text-gray-900  ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          )}

          {editing == true && (
            <div className="lg:col-span-1 md:col-span-3 sm:col-span-3">
              <label className="block text-sm font-medium leading-6 text-gray-900 ml-3">
                Expiry Date
              </label>
              <input
                type="date"
                disabled
                onChange={(e) => {
                  setKey("expiryDate", e.target.value);
                }}
                value={user.expiryDate}
                className="mt-2 block w-full rounded-md border-1 px-3 py-1.5 text-gray-900  ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          )}
        </div>

        {curCountry.code == "lca" && (
          <div className="col-span-4 sm:col-span-4 mt-5">
            <div className="flex items-center">
              <label className="block text-sm font-medium leading-6 text-gray-900 mr-2 ml-3">
                Politically Exposed Person
              </label>
              <input
                type="checkbox"
                onChange={(e) => {
                  setPoliticallyExposed(e.target.checked);
                }}
                checked={politicallyExposed}
                className=" block rounded-md px-3 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
        )}

        <div>
          <div>
            <h2 className="text-2xl ml-2 mb-5 mt-10 mb-10">Address</h2>
          </div>
          <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-6">
            <div className="lg:col-span-1 md:col-span-3 sm:col-span-3">
              <label className="block text-sm font-medium leading-6 text-gray-900 ml-3">
                Address - First Line
              </label>
              <div className="grid grid-cols-3">
                <input
                  type="text"
                  disabled
                  onChange={(e) => {
                    setKeyAddress("addressFirstLine", e.target.value);
                  }}
                  value={address.addressFirstLine}
                  className="mt-2 col-span-2 block w-full rounded-md border-1 px-3 py-1.5 text-gray-900  ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
                <div className="">
                  <svg
                    onClick={() => {
                      setOpen(true);
                      setCurField("addressFirstLine");
                      setCurAction("address");
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    className="grid-cols-1 ml-10 h-4 w-4 cursor-pointer hover:red-500 mt-5"
                    viewBox="0 0 512 512"
                  >
                    <path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z" />
                  </svg>
                </div>
              </div>
            </div>

            <div className="lg:col-span-1 md:col-span-3 sm:col-span-3">
              <label className="block text-sm font-medium leading-6 text-gray-900 ml-3">
                Address - Second Line
              </label>
              <div className="grid grid-cols-3">
                <input
                  type="text"
                  disabled
                  onChange={(e) => {
                    setKeyAddress("addressSecondLine", e.target.value);
                  }}
                  value={address.addressSecondLine}
                  className="mt-2 col-span-2 block w-full rounded-md border-1 px-3 py-1.5 text-gray-900  ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
                <div className="">
                  <svg
                    onClick={() => {
                      setOpen(true);
                      setCurField("addressSecondLine");
                      setCurAction("address");
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    className="grid-cols-1 ml-10 h-4 w-4 cursor-pointer hover:red-500 mt-5"
                    viewBox="0 0 512 512"
                  >
                    <path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z" />
                  </svg>
                </div>
              </div>
            </div>

            <div className="lg:col-span-1 md:col-span-3 sm:col-span-3">
              <label className="block text-sm font-medium leading-6 text-gray-900 ml-3">
                Address - State or Province*
              </label>
              <div className="grid grid-cols-3">
                <input
                  type="text"
                  disabled
                  onChange={(e) => {
                    setKeyAddress("addressStateOrProvince", e.target.value);
                  }}
                  value={address.addressStateOrProvince}
                  className="mt-2 block col-span-2 w-full rounded-md border-1 px-3 py-1.5 text-gray-900  ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
                <div className="">
                  <svg
                    onClick={() => {
                      setOpen(true);
                      setCurField("addressStateOrProvince");
                      setCurAction("address");
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    className="grid-cols-1 ml-10 h-4 w-4 cursor-pointer hover:red-500 mt-5"
                    viewBox="0 0 512 512"
                  >
                    <path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z" />
                  </svg>
                </div>
              </div>
            </div>

            <div className="lg:col-span-1 md:col-span-3 sm:col-span-3">
              <label className="block text-sm font-medium leading-6 text-gray-900 ml-3">
                Address - City or Town
              </label>
              <div className="grid grid-cols-3">
                <input
                  type="text"
                  disabled
                  onChange={(e) => {
                    setKeyAddress("addressCityOrTown", e.target.value);
                  }}
                  value={address.addressCityOrTown}
                  className="mt-2 block col-span-2 w-full rounded-md border-1 px-3 py-1.5 text-gray-900  ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
                <div className="">
                  <svg
                    onClick={() => {
                      setOpen(true);
                      setCurField("addressCityOrTown");
                      setCurAction("address");
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    className="grid-cols-1 ml-10 h-4 w-4 cursor-pointer hover:red-500 mt-5"
                    viewBox="0 0 512 512"
                  >
                    <path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z" />
                  </svg>
                </div>
              </div>
            </div>

            <div className="lg:col-span-1 md:col-span-3 sm:col-span-3">
              <label className="block text-sm font-medium leading-6 text-gray-900 ml-3">
                Country
              </label>
              <div className="grid grid-cols-3">
                <select
                  required
                  disabled
                  onChange={(e) => {
                    setKeyAddress("addressCountry", e.target.value);
                  }}
                  value={address.addressCountry}
                  className="mt-2 block w-full col-span-2 rounded-md border-1 px-3 py-1.5 text-gray-900  ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                >
                  <option value="">Select Country</option>
                  {countries2.map((item) => (
                    <option key={item.code} value={item.code}>
                      {item.title}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>

        {curCountry.code == "lca" && (
          <div>
            <div>
              <h2 className="text-2xl ml-2 mb-5 mt-10 mb-10">Employment</h2>
            </div>
            <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-6">
              <div className="lg:col-span-1 md:col-span-3 sm:col-span-3">
                <label className="block text-sm font-medium leading-6 text-gray-900 ml-3">
                  Occupation
                </label>
                <div className="grid grid-cols-3">
                  <input
                    type="text"
                    disabled
                    onChange={(e) => {
                      setKeyEmployment("occupation", e.target.value);
                    }}
                    value={employment.occupation}
                    className="mt-2 block col-span-2 w-full rounded-md border-1 px-3 py-1.5 text-gray-900  ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                  <div className="">
                    <svg
                      onClick={() => {
                        setOpen(true);
                        setCurField("occupation");
                        setCurAction("employment");
                      }}
                      xmlns="http://www.w3.org/2000/svg"
                      className="grid-cols-1 ml-10 h-4 w-4 cursor-pointer hover:red-500 mt-5"
                      viewBox="0 0 512 512"
                    >
                      <path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z" />
                    </svg>
                  </div>
                </div>
              </div>

              <div className="lg:col-span-1 md:col-span-3 sm:col-span-3">
                <label className="block text-sm font-medium leading-6 text-gray-900 ml-3">
                  Employer
                </label>
                <div className="grid grid-cols-3">
                  <input
                    type="text"
                    disabled
                    onChange={(e) => {
                      setKeyEmployment("employer", e.target.value);
                    }}
                    value={employment.employer}
                    className="mt-2 block w-full col-span-2 rounded-md border-1 px-3 py-1.5 text-gray-900  ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                  <div className="">
                    <svg
                      onClick={() => {
                        setOpen(true);
                        setCurField("employer");
                        setCurAction("employment");
                      }}
                      xmlns="http://www.w3.org/2000/svg"
                      className="grid-cols-1 ml-10 h-4 w-4 cursor-pointer hover:red-500 mt-5"
                      viewBox="0 0 512 512"
                    >
                      <path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z" />
                    </svg>
                  </div>
                </div>
              </div>

              <div className="lg:col-span-1 md:col-span-3 sm:col-span-3">
                <label className="block text-sm font-medium leading-6 text-gray-900 ml-3">
                  Position
                </label>
                <div className="grid grid-cols-3">
                  <input
                    type="text"
                    disabled
                    onChange={(e) => {
                      setKeyEmployment("position", e.target.value);
                    }}
                    value={employment.position}
                    className="mt-2 block w-full col-span-2 rounded-md border-1 px-3 py-1.5 text-gray-900  ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                  <div className="">
                    <svg
                      onClick={() => {
                        setOpen(true);
                        setCurField("position");
                        setCurAction("employment");
                      }}
                      xmlns="http://www.w3.org/2000/svg"
                      className="grid-cols-1 ml-10 h-4 w-4 cursor-pointer hover:red-500 mt-5"
                      viewBox="0 0 512 512"
                    >
                      <path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z" />
                    </svg>
                  </div>
                </div>
              </div>

              <div className="lg:col-span-1 md:col-span-3 sm:col-span-3">
                <label className="block text-sm font-medium leading-6 text-gray-900 ml-3">
                  Source of Wealth
                </label>
                <div className="grid grid-cols-3">
                  <input
                    type="text"
                    onChange={(e) => {
                      setKeyEmployment("sourceOfWealth", e.target.value);
                    }}
                    value={employment.sourceOfWealth}
                    className="mt-2 block w-full col-span-2 rounded-md border-1 px-3 py-1.5 text-gray-900  ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                  <div className="">
                    <svg
                      onClick={() => {
                        setOpen(true);
                        setCurField("sourceOfWealth");
                        setCurAction("employment");
                      }}
                      xmlns="http://www.w3.org/2000/svg"
                      className="grid-cols-1 ml-10 h-4 w-4 cursor-pointer hover:red-500 mt-5"
                      viewBox="0 0 512 512"
                    >
                      <path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z" />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
