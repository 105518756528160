import React, {Fragment} from "react";
import {Dialog, Transition} from "@headlessui/react";
import {PencilSquareIcon} from "@heroicons/react/24/outline";
import TelephoneInput from "./telephoneInput";
import AccessControl from "../../util/accessControl";
import {camelCaseToWords} from "../../util/util";

export default function RetailClientEditModal({curAction, curField, open, setOpen, user, setKey, address, setKeyAddress, employment, setKeyEmployment, contact, setKeyContact, userInfo, setStatus, saveForm, getClient,cancelButtonRef}) {

    return(
        <Transition.Root show={open} as={Fragment}>
            <Dialog
                as="div"
                className="relative z-10"
                initialFocus={cancelButtonRef}
                onClose={setOpen}
            >
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                                <div>
                                    <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-blue-100">
                                        <PencilSquareIcon
                                            className="h-6 w-6 text-white-600"
                                            aria-hidden="true"
                                        />
                                    </div>
                                    <div className="mt-3 text-center sm:mt-5">
                                        <Dialog.Title
                                            as="h3"
                                            className="text-base font-semibold leading-6 text-gray-900"
                                        >
                                            Manage Retail Client
                                        </Dialog.Title>

                                        {curAction == "user" &&
                                            curField != "status" &&
                                            curField != "dateOfBirth" &&
                                            curField != "id" && (
                                                <div className="block rounded-lg bg-gray-200 p-6 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] ">
                                                    <label className="block text-sm font-medium leading-6 text-gray-900">
                                                        {camelCaseToWords(curField)}
                                                    </label>
                                                    <input
                                                        type="text"
                                                        onChange={(e) => {
                                                            setKey(curField, e.target.value);
                                                        }}
                                                        value={user[curField]}
                                                        className="mt-2 block w-full rounded-md border-1 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                    />
                                                </div>
                                            )}

                                        {curAction == "user" && curField == "dateOfBirth" && (
                                            <div className="block rounded-lg bg-gray-200 p-6 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] ">
                                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                                    {camelCaseToWords(curField)}
                                                </label>
                                                <input
                                                    type="date"
                                                    onChange={(e) => {
                                                        setKey(curField, e.target.value);
                                                    }}
                                                    value={user[curField]}
                                                    className="mt-2 block w-full rounded-md border-1 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                />
                                            </div>
                                        )}

                                        {curAction == "user" && curField == "id" && (
                                            <div>
                                                <div className="mt-2 block rounded-lg bg-gray-200 p-6 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] ">
                                                    <label className="block text-sm font-medium leading-6 text-gray-900">
                                                        ID Information
                                                    </label>
                                                    <input
                                                        type="text"
                                                        onChange={(e) => {
                                                            setKey("id", e.target.value);
                                                        }}
                                                        value={user[curField]}
                                                        className="mt-2 block w-full rounded-md border-1 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                    />
                                                </div>
                                                <div className="mt-2 block rounded-lg bg-gray-200 p-6 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] ">
                                                    <label className="block text-sm font-medium leading-6 text-gray-900">
                                                        Issued Date
                                                    </label>
                                                    <input
                                                        type="date"
                                                        onChange={(e) => {
                                                            setKey("issuedDate", e.target.value);
                                                        }}
                                                        value={user.issuedDate}
                                                        className="mt-2 block w-full rounded-md border-1 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                    />
                                                </div>
                                                <div className="mt-2 block rounded-lg bg-gray-200 p-6 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] ">
                                                    <label className="block text-sm font-medium leading-6 text-gray-900">
                                                        Expiry Date
                                                    </label>
                                                    <input
                                                        type="date"
                                                        id="expiryDate"
                                                        onChange={(e) => {
                                                            setKey("expiryDate", e.target.value);
                                                        }}
                                                        value={user.expiryDate}
                                                        className="mt-2 block w-full rounded-md border-1 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                    />
                                                </div>

                                                <div className="mt-2 block rounded-lg bg-gray-200 p-6 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] ">
                                                    <label className="block text-sm font-medium leading-6 text-gray-900">
                                                        ID Type
                                                    </label>
                                                    <select
                                                        required
                                                        onChange={(e) => {
                                                            setKey("idType", e.target.value);
                                                        }}
                                                        value={user.idType}
                                                        className="mt-2 block w-full rounded-md border-1 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                    >
                                                        <option value=""> Select ID Type</option>
                                                        <option value="National ID">National ID</option>
                                                        <option value="Driver's Licenses">
                                                            Driver's Licenses
                                                        </option>
                                                        <option value="Passport">Passport</option>
                                                    </select>
                                                </div>
                                            </div>
                                        )}

                                        {curAction == "address" &&
                                            curField != "addressCountry" && (
                                                <div className="block rounded-lg bg-gray-200 p-6 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] ">
                                                    <label className="block text-sm font-medium leading-6 text-gray-900">
                                                        {camelCaseToWords(curField)}
                                                    </label>
                                                    <input
                                                        type="text"
                                                        onChange={(e) => {
                                                            setKeyAddress(curField, e.target.value);
                                                        }}
                                                        value={address[curField]}
                                                        className="mt-2 block w-full rounded-md border-1 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                    />
                                                </div>
                                            )}

                                        {curAction == "employment" && (
                                            <div className="block rounded-lg bg-gray-200 p-6 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] ">
                                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                                    {camelCaseToWords(curField)}
                                                </label>
                                                <input
                                                    type="text"
                                                    onChange={(e) => {
                                                        setKeyEmployment(curField, e.target.value);
                                                    }}
                                                    value={employment[curField]}
                                                    className="mt-2 block w-full rounded-md border-1 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                />
                                            </div>
                                        )}

                                        {curAction == "contact" &&
                                            !(
                                                curField == "mobilePhone" ||
                                                curField == "housePhone" ||
                                                curField == "workPhone"
                                            ) && (
                                                <div className="block rounded-lg bg-gray-200 p-6 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] ">
                                                    <label className="block text-sm font-medium leading-6 text-gray-900">
                                                        {camelCaseToWords(curField)}
                                                    </label>
                                                    <input
                                                        type="text"
                                                        onChange={(e) => {
                                                            setKeyContact(curField, e.target.value);
                                                        }}
                                                        value={contact[curField]}
                                                        className="mt-2 block w-full rounded-md border-1 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                    />
                                                </div>
                                            )}

                                        {curAction == "contact" &&
                                            (curField == "mobilePhone" ||
                                                curField == "housePhone" ||
                                                curField == "workPhone") && (
                                                <div className="block rounded-lg bg-gray-200 p-6 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] ">
                                                    <label className="block text-sm font-medium leading-6 text-gray-900">
                                                        {camelCaseToWords(curField)}
                                                    </label>
                                                    <TelephoneInput
                                                        setKey={setKeyContact}
                                                        path={curField}
                                                        value={contact[curField]}
                                                        className={
                                                            "mt-2 block w-full rounded-md border-1 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                        }
                                                    />
                                                </div>
                                            )}

                                        {curAction == "user" && curField == "status" && (
                                            <div className="block rounded-lg bg-gray-200 p-6 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] ">
                                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                                    {camelCaseToWords(curField)}
                                                </label>
                                                <select
                                                    required
                                                    onChange={(e) => {
                                                        setStatus("status", e.target.value);
                                                    }}
                                                    value={userInfo.status}
                                                    className="mt-2 block w-full rounded-md border-1 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                >
                                                    <option value="">Select Status</option>
                                                    <option value="active">Active</option>
                                                    <option value="disabled">Disabled</option>
                                                </select>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                                    <AccessControl
                                        permission={"updateCommercialClient"}
                                        fallback={<></>}
                                        target={["god"]}
                                    >
                                        <button
                                            type="button"
                                            className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                                            onClick={() => saveForm()}
                                        >
                                            Save
                                        </button>
                                    </AccessControl>
                                    <button
                                        type="button"
                                        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                                        onClick={() => {
                                            setOpen(false);
                                            getClient();
                                        }}
                                        ref={cancelButtonRef}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}