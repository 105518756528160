import { parse } from "@fortawesome/fontawesome-svg-core";
import React, { useEffect, useState } from "react";

export default function TelephoneInput({ setKey, path, value, className,isRequired }) {
  const [code, setCode] = useState("");
  const [number, setNumber] = useState("");
  useEffect(() => {
    console.log({ path });
    let country = JSON.parse(localStorage.geolocation);
    switch (country.code) {
      case "tto":
        setCode("868");
        break;
      case "vct":
        setCode("784");
        break;
      case "lca":
        setCode("758");
        break;
      case "grd":
        setCode("473");
        break;

      default:
        setCode("868");
        break;
    }
    console.log({ value });
    // if(value !== undefined && value !== null && value !== ""){
    //     let telephone = `${value}`
    //     setNumber(value)
    //     console.log({telephone})

    //     // if(telephone.charAt(0) === '1'){
    //     //     telephone = telephone.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '').substring(1,telephone.length)
    //     // }
    //     // if(telephone.length === 10){
    //     //     telephone = telephone.substring(3)
    //     // }
    //    // alert("telephone",telephone)
    //     //telephone = formatPhoneNumber(`${code}${telephone}`)
    //     setNumber(telephone)
    // }
  }, []);

  function formatPhoneNumber(phoneNumberString) {
    var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    cleaned = cleaned.replace(/^.{3}/g, code);
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    var match2 = cleaned.match(/^(\d{3})(\d{3})$/);
    var match1 = cleaned.match(/^(\d{3})$/);
    
    if (match) {
      return match[1] + "-" + match[2] + "-" + match[3];
    }
    if (match) {
      return match[1] + "-" + match[2] + "-";
    }
    if (match1) {
      return match1[1] + "-";
    }
    return phoneNumberString;
  }

  function makeNumber(text) {
    let paresedText = text.replaceAll("-", "");
    console.log({ paresedText, l: paresedText.length });
    if (paresedText.length === 11) {
      return;
    }
    if (text === "") {
      console.log({ make: text });
      text = `${code}${text}`;
      console.log({ w: text });
    }

    // let first = paresedText.substring(0, 3);
    // if (first !== code) {
    //   console.log({ make: text });
    //   text = `${code}${text}`;
    //   console.log({ w: text });
    // }
    // console.log({text})
    for (let i = 0; i < paresedText.length; i++) {
        let val = isNaN(parseInt(paresedText.charAt(i)))
        console.log({ val })
      if (val) {
        return;
      }
    }

    setKey(path, formatPhoneNumber(text));
  }
  function handleKeyPress(event){
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);

    // Allow only numbers and specific keys such as backspace, delete, arrow keys, etc.
    const allowedKeys = /^[0-9\b]+$/; // regex to match numbers and backspace

    // Allow inputs from numpad (96 to 105)
    if (!(keyCode >= 96 && keyCode <= 105) && !allowedKeys.test(keyValue)) {
      event.preventDefault();
    }
  }


  return (
    <input
      type="text"
      onChange={(e) => {
        makeNumber(e.target.value);
      }}
      onKeyDown={(e)=>{handleKeyPress(e)}}
      required={isRequired || false}
      value={formatPhoneNumber(value)}
      className={className}
    />
  );
}
