import React, { Fragment, useState, useRef, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Swal from "sweetalert2";
import http from "../util/http";
import { CheckIcon } from "@heroicons/react/24/outline";
import moment from "moment";
import TelephoneInput from "./dashboard/telephoneInput";
import AccessControl from "../util/accessControl";
export default function SmsTool() {
  const [open, setOpen] = useState(false);
  const [payload, setPayload] = useState({ number: "" });
  const [otp, setOtp] = useState(null);
  useEffect(() => {
    try {
    } catch (error) {
      console.log(error);
    }
  }, []);

  const delay = (delayInms) => {
    return new Promise((resolve) => setTimeout(resolve, delayInms));
  };
  function setKey(key, value) {
    setPayload({ ...payload, [key]: value });
  }
  async function getOTP() {
    try {
      let response = await http.request("contracts", "get-otp", {
        number: payload.number,
      });
      console.log(response);
      setOtp(response.otp);
      if (!response.otp) {
        alert("There are not OTPs available for this number");
      }
    } catch (error) {
      console.log(error);
      alert("Failed to get OTP");
    }
  }
  function closeModal() {
    setPayload({ number: "" });
    setOtp(null);
    setOpen(false);
  }
  return (
    <React.Fragment>
      <div class="fixed bottom-24 right-6">
        <AccessControl
          permission={"pullOTP"}
          fallback={<></>}
          target={["god"]}
        >
          <button
            onClick={(e) => {
              setOpen(true);
            }}
            class="w-16 h-16 bg-yellow-500 hover:bg-yellow-600 text-white rounded-full shadow-lg flex items-center justify-center transition transform hover:scale-110"
          >
            <i className="fa-solid fa-phone mx-2"></i>
          </button>
        </AccessControl>
      </div>
      <Transition.Root show={open} as={Fragment}>
        <Dialog open={open} onClose={closeModal} className="relative z-10">
          <Dialog.Backdrop
            transition
            className="fixed inset-0 bg-gray-500/75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
          />

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Dialog.Panel
                transition
                className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
              >
                <div>
                  <div className="mx-auto flex size-12 items-center justify-center rounded-full bg-green-100">
                    <CheckIcon
                      aria-hidden="true"
                      className="size-6 text-green-600"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-base font-semibold text-gray-900"
                    >
                      SMS Tool
                    </Dialog.Title>
                    <div className="mt-2">
                      <div className="col-span-1 sm:col-span-1">
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                          Please Enter a Telephone Number
                        </label>
                        <TelephoneInput
                          setKey={setKey}
                          path={"number"}
                          value={payload.number}
                          className={
                            "mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          }
                        />
                        {otp && (
                          <p className="text-lg font-bold mt-3">OTP: [{otp}]</p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                  <button
                    type="button"
                    onClick={() => {
                      getOTP();
                    }}
                    className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                  >
                    Get OTP
                  </button>
                  <button
                    type="button"
                    data-autofocus
                    onClick={() => {
                      closeModal();
                    }}
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </React.Fragment>
  );
}
