import React, { useState, useEffect } from "react";
import {
  setDeepValue,
  getDeepValue,
  formatPhoneNumber,
  getStatus,
} from "../../../../../util/util";
import Http from "../../../../../util/http";
import AccessControl from "../../../../../util/accessControl";
import Flag from "react-world-flags";
import Swal from "sweetalert2";
import CommercialClientEditModal from "../../../../../components/CommercialClientEditModal";
import TableRow from "../../../../../components/dashboard/tablerow";
import {
  PencilSquareIcon,
  CurrencyDollarIcon,
  EnvelopeIcon,
} from "@heroicons/react/24/outline";
import VerifyClientModal from "../../../../../components/dashboard/verifyclient";
import swal from "sweetalert2";
import DetailedSchedule from "../../../../../components/dashboard/DetailedSchedule";
import SummarySchedule from "../../../../../components/dashboard/SummarySchedule";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function CommercialClientProfile() {
  const [commUser, setCommUser] = useState({
    _id: "",
    photoLink: "",
    businessPhoneNumberOne: "...",
    businessPhoneNumberTwo: "...",
    businessFaxNumber: "...",
    businessEmailAddress: "...",
    businessName: "...",
    businessAddress: "...",
    businessAddressFirstLine: "...",
    businessAddressSecondLine: "",
    businessAddressCity: "...",
    businessAddressState: "...",
    businessAddressCountry: "...",
    businessClientCode: "...",
    businessDescription: "...",
    businessRegistrationNumber: "...",
    authorizerFirstName: "...",
    authorizerLastName: "...",
    paymentInfoType: "...",
    paymentInfoBankName: "...",
    paymentInfoAccountType: "...",
    paymentInfoAccountNumber: "...",
    status: "...",
  });
  const [modCommUser, setModCommUser] = useState({
    _id: "",
    photoLink: "",
    businessPhoneNumberOne: "",
    businessPhoneNumberTwo: "",
    businessFaxNumber: "",
    businessEmailAddress: "",
    businessName: "",
    businessAddress: "",
    businessAddressFirstLine: "...",
    businessAddressSecondLine: "",
    businessAddressCity: "...",
    businessAddressState: "...",
    businessAddressCountry: "...",
    businessClientCode: "",
    businessDescription: "",
    businessRegistrationNumber: "",
    authorizerFirstName: "",
    authorizerLastName: "",
    paymentInfoType: "",
    paymentInfoBankName: "",
    paymentInfoAccountType: "",
    paymentInfoAccountNumber: "",
    status: "",
  });
  const [current, setCurrent] = useState(0);
  const [contractView, setContractView] = useState(false);
  const [selectedContract, setSelectedContract] = useState({
    _id: "",
    contractNumber: "",
    status: "",
    insuranceCompany: "",
    policyStartDate: "",
    policyEndDate: "",
    totalPremiumFinanced: "",
    scheduledPaymentDate: "",
    policyBreakdown: [],
    paymentSchedule: [],
  });
  const [modalToggle, setModalToggle] = useState(false);
  const [modalAction, setModalAction] = useState(0);
  const [curAction, setCurAction] = useState("");
  const [curField, setCurField] = useState("");
  const [open, setOpen] = useState(false);

  const [contracts, setContract] = useState([]);
  const [contractDetailsList, setContractDetailsList] = useState([]);
  const [link,setLink] = useState("");
  const [tabs, setTabs] = useState([
    { name: "Business Details", current: true },
    { name: "Commercial Contacts", current: false },
  ]);
  const [isBank, setIsBank] = useState(false);


  useEffect(() => {
    try {
      getCommercialClientData(window.location.pathname.split("/")[5]).then(
        () => {
          getCommercialClientContracts(
            window.location.pathname.split("/")[5]
          ).then(() => {});
        }
      );
    } catch (err) {
      console.error(err);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Failed to make request to servers.",
      });
    }
  }, []);

  function openModal(action) {
    setModalAction(action);
    setModCommUser({ ...commUser });
    setModalToggle(true);
  }
  function isValidEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }
  function saveModalData(action, obj) {

    if (isValidEmail(obj.businessEmailAddress)) {
      console.log('Valid email:', obj.businessEmailAddress);
    } else {
      console.log('Invalid email:', obj.businessEmailAddress);
      swal.fire({
        title: 'Oops',
        text: 'Please check the email address.',
        icon: 'info',
        showConfirmButton: true
      })
      return
    }
    let dialog = Swal.fire({
      title: "Loading...",
      timerProgressBar: true,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
      willClose: () => {},
    });
    Http.request("contracts", "client/commercial/edit-info", obj)
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "Hurray!",
          text: "Commercial client account updated successfully",
        }).then(() => {
          dialog.close();
          setModalToggle(false);
          window.location.reload();
        });
      })
      .catch((err) => {
        dialog.close();
        console.log(err);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: err || "Failed to make request to servers.",
        });
      });
    //reload page after posting info
  }

  function selectTab(index) {
    let tempTabs = tabs;
    tempTabs[index].current = true;
    tempTabs[current].current = false;
    setTabs(tempTabs);
    setCurrent(index);
  }

  function setKey(key, value) {
    let object = { ...commUser };

    setDeepValue(object, value, key);
    setCommUser(object);
  }

  function nullTransform(val) {
    if (val === null || val === undefined) {
      return "";
    }
    return val;
  }

  function FormatNumber(str) {
    return str.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  async function cancelLoan(id) {
    try {
      Swal.fire({
        title: "Attention!",
        text: "Are you sure you want to cancel this loan\n!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, send it!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          let response = await Http.request(
            "contracts",
            `contracts/cancel/${id}`
          );
          Swal.fire(
            "Success!",
            "Loan has been cancelled successfully.",
            "success"
          ).then(() => {
            window.location.reload();
          });
        }
      });
    } catch (e) {
      console.log(e);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Failed to cancel loan!",
      });
    }
  }

  function getPaymentScheduleStatus(status) {
    const mainStyle =
      "inline-flex items-center rounded-full px-7 py-1 text-xs text-white";
    const st = {
      paid: {
        name: "Paid",
        style: mainStyle + " bg-lime-500",
      },
      late: {
        name: "Late",
        style: mainStyle + " bg-red-600",
      },
      due: {
        name: "Due",
        style: mainStyle + " bg-cyan-600",
      },
      scheduled: {
        name: "Scheduled",
        style: "inline-flex items-center text-sm text-gray-500",
      },
    };
    return st[status]
      ? st[status]
      : {
          name: "Scheduled",
          style: "inline-flex items-center text-sm text-gray-500",
        };
  }

  function openContractDetailsPage(id) {
    if (id !== "" && id !== null && id !== undefined) {
      getCommercialClientContractDetail(id);
    } else {
      setContractView(false);
    }
  }

  async function getCommercialClientData(id) {
    try {
      let userData = await Http.request(
        "contracts",
        "client/commercial/get-one",
        { id }
      );
      if (typeof userData !== "undefined") {
        setCommUser(userData);
        setModCommUser(userData);
      } else {
        await Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Failed to retrieve commercial client information",
        });
      }
    } catch (err) {
      await Swal.fire({
        icon: "error",
        title: "Oops...",
        text: err || "Failed to make request to servers.",
      });
    }
  }

  async function getCommercialClientContracts(id) {
    try {
      let contracts = await Http.request(
        "contracts",
        "contract/client/commercial/get-all",
        { id }
      );
      if (typeof contracts !== "undefined" && contracts.length > 0) {
        setContract(contracts);
      } else {
      }
    } catch (err) {
      await Swal.fire({
        icon: "error",
        title: "Oops...",
        text: err || "Failed to make request to servers.",
      });
    }
  }

  async function getCommercialClientContractDetail(id) {
    let dialog = Swal.fire({
      title: "Loading...",
      timerProgressBar: true,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
      willClose: () => {},
    });
    try {
      let details = await Http.request(
        "contracts",
        "contract/client/commercial/details",
        { id }
      );
      dialog.close();
      if (typeof details !== "undefined" && details) {
        console.log("This is dataaaaaaa", details);
        setSelectedContract(details);
        setContractView(true);
        let base = `${'https://portal.yoozit.today/quickpay/1/'}${getDeepValue(
            details,
            "loan.loanNumber"
        )}/0/${commUser.businessEmailAddress}`

        if(localStorage.geolocation){
          let geo = JSON.parse(localStorage.geolocation)
          if(geo.code === "grd" || geo.code === "lca"){
            base = 'https://dtu.linkuptt.com/linkupv2/index.php/welcome'
          }
        }
        setLink(base)
      } else {
      }
    } catch (err) {
      await Swal.fire({
        icon: "error",
        title: "Oops...",
        text: err || "Failed to make request to servers.",
      });
    }
  }

  function getBalance() {
    let balance = 0;
    let paid = selectedContract?.loan?.installments?.reduce(
      (n, { principalPaid, interest }) => {
        return n + (principalPaid + interest);
      },
      0
    );
    balance = selectedContract?.loan?.startingBalance - paid;
    return FormatNumber(balance.toFixed(2));
  }

  function back() {
    window.history.back();
  }

  async function sendEmailInvite() {
    try {
      let url = window.location.href.split("/");
      let response = await Http.request(
        "contracts",
        `contracts/clients/verify/email`,
        { id: `${window.location.href.split("/")[url.length - 1]}` }
      );
      Swal.fire(
        "Hurray!",
        "Verification code has been sent to the clients email",
        "success"
      );
    } catch (e) {
      console.log(e);
      Swal.fire("Oops", "Failed to send email verification code.", "error");
    }
  }

  return (
    <div className="flex justify-center ">
      <div className="space-y-6 sm:px-6 lg:col-span-9 lg:px-0 w-full">
        <div className="shadow sm:overflow-hidden sm:rounded-md">
          <div className="space-y-6 bg-white px-4 py-6 sm:p-6">
            <div className="mb-20">
              <h1 className="text-4xl text-center font-semibold  leading-6 text-gray-900">
                Commercial Client Profile
              </h1>
              <CommercialClientEditModal
                open={modalToggle}
                setOpen={setModalToggle}
                action={modalAction}
                commUser={modCommUser}
                setCommUser={setModCommUser}
                save={saveModalData}
              ></CommercialClientEditModal>
            </div>
            <div className="mb-5 px-20">
              <div className="grid grid-cols-10">
                <div
                  className="w-1/12"
                  style={{
                    border: "1px solid black",
                    borderRadius: "12%",
                    height: "64px",
                    width: "64px",
                  }}
                >
                  <img style={{}} src="/avatar4.png" alt="Solis Credit" />
                </div>
                <div className="col-span-9">
                  <h2 className="text-2xl">{commUser.businessName}</h2>
                  <div className="flex w-full">
                    <div className="w-1/5">
                      <div className="grid grid-cols-5">
                        <div className="col-span-1">
                          <div className="" style={{ width: "35px" }}>
                            <Flag code={commUser.businessAddressCountry} />
                          </div>
                        </div>
                        <div className="col-span-1">
                          <span className="text-sm  mt-2 font-bold">
                            {commUser.businessClientCode}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="w-2/5">
                      <b>Email: </b>
                      {commUser.businessEmailAddress}
                    </div>
                    <div className="w-1/5 text-sm">
                      <b>Registration: </b>{" "}
                      {commUser.businessRegistrationNumber}
                    </div>
                    <div className="w-1/5 text-sm">
                      <b>Phone: </b>{" "}
                      {formatPhoneNumber(commUser.businessPhoneNumberOne)}
                    </div>
                  </div>
                  <div className="flex">
                    <p className="col-span-1 mr-2">Status:</p>
                    {commUser.status == "active" && (
                      <>
                        <p className="col-span-2 text-green-700 bg-green-200 ring-green-600/10 rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset">
                          {commUser.status}
                        </p>
                        <span>
                          <AccessControl
                            permission={"changeClientStatus"}
                            fallback={<></>}
                            target={["god"]}
                          >
                            <svg
                              onClick={() => {
                                openModal(13);
                              }}
                              xmlns="http://www.w3.org/2000/svg"
                              className="grid-cols-1 ml-3 mt-1 h-4 w-4 cursor-pointer hover:red-500"
                              viewBox="0 0 512 512"
                            >
                              <path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z" />
                            </svg>
                          </AccessControl>
                        </span>
                      </>
                    )}
                    {commUser.status == "disabled" && (
                      <>
                        <p className="col-span-2 text-red-700 bg-red-200 ring-red-600/10 rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset">
                          {commUser.status}
                        </p>
                        <span>
                          <AccessControl
                            permission={"changeClientStatus"}
                            fallback={<></>}
                            target={["god"]}
                          >
                            <svg
                              onClick={() => {
                                openModal(13);
                              }}
                              xmlns="http://www.w3.org/2000/svg"
                              className="grid-cols-1 ml-3 mt-1 h-4 w-4 cursor-pointer hover:red-500"
                              viewBox="0 0 512 512"
                            >
                              <path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z" />
                            </svg>
                          </AccessControl>
                        </span>
                      </>
                    )}
                  </div>
                  {process.env.REACT_APP_USE_SMS === 'true' && (
                          <div className="flex mt-2">
                            <p className="col-span-1 mr-2">Verified:</p>
                            <>
                      <span className="inline-flex items-center gap-x-1.5 rounded-full px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200">
                        <svg
                            className={
                                "h-1.5 w-1.5 " +
                                (commUser?.smsVerified === true
                                    ? "fill-green-500"
                                    : "fill-red-500")
                            }
                            viewBox="0 0 6 6"
                            aria-hidden="true"
                        >
                          <circle cx={3} cy={3} r={3} />
                        </svg>
                        Telephone
                      </span>
                              <span className="mx-2 inline-flex items-center gap-x-1.5 rounded-full px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200">
                        <svg
                            className={
                                "h-1.5 w-1.5 " +
                                (commUser?.emailVerified === true
                                    ? "fill-green-500"
                                    : "fill-red-500")
                            }
                            viewBox="0 0 6 6"
                            aria-hidden="true"
                        >
                          <circle cx={3} cy={3} r={3} />
                        </svg>
                        Email
                      </span>
                              <span>
                        {commUser?.emailVerified !== true && (
                            <AccessControl
                                permission={"sendEmailVerification"}
                                fallback={<></>}
                                target={["god"]}
                            >
                              <button
                                  onClick={() => sendEmailInvite()}
                                  type="button"
                                  className="inline-flex items-center gap-x-1.5 rounded-md bg-green-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                              >
                                Verify Email
                                <EnvelopeIcon
                                    aria-hidden="true"
                                    className="-mr-0.5 h-5 w-5"
                                />
                              </button>
                            </AccessControl>
                        )}
                      </span>
                            </>
                          </div>
                      )}
                </div>
              </div>
            </div>

            <div className="border-b border-gray-200 pb-5 px-20 sm:pb-0 p-5">
              <div className="mt-3 sm:mt-4">
                <div className="sm:hidden">
                  <label htmlFor="current-tab" className="sr-only">
                    Select a tab
                  </label>
                  <select
                    id="current-tab"
                    name="current-tab"
                    className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    defaultValue={tabs[current].name}
                  >
                    {tabs.map((tab) => (
                      <option key={tab.name}>{tab.name}</option>
                    ))}
                  </select>
                </div>
                <div className="hidden sm:block">
                  <nav className="-mb-px flex space-x-8">
                    {tabs.map((tab, index) => (
                      <a
                        key={tab.name}
                        style={{ cursor: "pointer" }}
                        onClick={() => selectTab(index)}
                        className={classNames(
                          tab.current
                            ? "border-indigo-500 text-indigo-600"
                            : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                          "whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium"
                        )}
                        aria-current={tab.current ? "page" : undefined}
                      >
                        {tab.name}
                      </a>
                    ))}
                  </nav>
                </div>
              </div>
            </div>

            <div className="p-5 px-20">
              {current === 0 && (
                <div>
                  <div className="mt-6 border-t border-gray-100">
                    <div className="mb-5">
                      <div className="grid grid-cols-2 mb-10">
                        <div className="col-span-1">
                          <h2 className="text-2xl">Authorized Officer</h2>
                          <div className="grid grid-cols-2">
                            <div className="col-span-2">
                              <div className="grid grid-cols-12">
                                <div className="col-span-9">
                                  <input
                                    disabled
                                    type="text"
                                    value={
                                      commUser.authorizerFirstName +
                                      " " +
                                      commUser.authorizerLastName
                                    }
                                    className="mt-2 block w-full rounded-md border-1 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                                <div className="col-span-3">
                                  <svg
                                    onClick={() => {
                                      openModal(1);
                                    }}
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="ml-10 h-4 w-4 cursor-pointer hover:red-500"
                                    viewBox="0 0 512 512"
                                  >
                                    <path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z" />
                                  </svg>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-span-1">
                          <h2 className="text-2xl">Company Registration </h2>
                          <div className="flex">
                            <div className="w-9/12 mr-5">
                              <input
                                disabled
                                type="text"
                                value={commUser.businessRegistrationNumber}
                                className="mt-2 block w-full rounded-md border-1 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              />
                            </div>
                            <div className="col-span-1">
                              <svg
                                onClick={() => {
                                  openModal(2);
                                }}
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-4 w-4 cursor-pointer hover:red-500"
                                viewBox="0 0 512 512"
                              >
                                <path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z" />
                              </svg>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="mb-10">
                        <h2 className="text-2xl">Description</h2>
                        <div className="grid grid-cols-2">
                          <div className="col-span-1 mr-3">
                            <div className="grid grid-cols-12">
                              <div className="col-span-9">
                                <textarea
                                  rows={5}
                                  disabled
                                  type="text"
                                  value={commUser.businessDescription}
                                  className="mt-2 block w-full rounded-md border-1 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                              <div className="col-span-3">
                                <svg
                                  onClick={() => {
                                    openModal(3);
                                  }}
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="ml-10 h-4 w-4 cursor-pointer hover:red-500"
                                  viewBox="0 0 512 512"
                                >
                                  <path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z" />
                                </svg>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="grid grid-cols-2">
                          <div className="col-span-1">
                            <div className="mb-2">
                              <h2 className="text-2xl">Address</h2>
                              <div className="flex">
                                <div className="w-10/12 mr-5">
                                  <div className="grid grid-cols-12">
                                    <div className="col-span-9">
                                      <textarea
                                        rows={3}
                                        disabled
                                        value={commUser.businessAddress}
                                        className="mt-2 block w-full rounded-md border-1 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                      />
                                    </div>
                                    <div className="col-span-3">
                                      <svg
                                        onClick={() => {
                                          openModal(4);
                                        }}
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="ml-10 h-4 w-4 cursor-pointer hover:red-500"
                                        viewBox="0 0 512 512"
                                      >
                                        <path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z" />
                                      </svg>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div>
                              <h2 className="text-2xl">Email Address</h2>
                              <div className="grid grid-cols-2">
                                <div className="col-span-2">
                                  <div className="grid grid-cols-12">
                                    <div className="col-span-9">
                                      <input
                                        disabled
                                        value={commUser.businessEmailAddress}
                                        className="mt-2 block w-full rounded-md border-1 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                      />
                                    </div>
                                    <div className="col-span-3">
                                      <svg
                                        onClick={() => {
                                          openModal(5);
                                        }}
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="ml-10 h-4 w-4 cursor-pointer hover:red-500"
                                        viewBox="0 0 512 512"
                                      >
                                        <path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z" />
                                      </svg>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-span-1">
                            <div className="mb-2">
                              <h2 className="text-2xl">Business Phone # 1</h2>
                              <div className="flex">
                                <div className="w-10/12 mr-5">
                                  <input
                                    disabled
                                    value={formatPhoneNumber(
                                      commUser.businessPhoneNumberOne
                                    )}
                                    className="mt-2 block w-full rounded-md border-1 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                                <div className="col-span-1">
                                  <svg
                                    onClick={() => {
                                      openModal(6);
                                    }}
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-4 w-4 cursor-pointer hover:red-500"
                                    viewBox="0 0 512 512"
                                  >
                                    <path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z" />
                                  </svg>
                                </div>
                              </div>
                            </div>
                            <div className="mb-2">
                              <h2 className="text-2xl">Business Phone # 2</h2>
                              <div className="flex">
                                <div className="w-10/12 mr-5">
                                  <input
                                    disabled
                                    value={formatPhoneNumber(
                                      commUser.businessPhoneNumberTwo
                                    )}
                                    className="mt-2 block w-full rounded-md border-1 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                                <div className="col-span-1">
                                  <svg
                                    onClick={() => {
                                      openModal(7);
                                    }}
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-4 w-4 cursor-pointer hover:red-500"
                                    viewBox="0 0 512 512"
                                  >
                                    <path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z" />
                                  </svg>
                                </div>
                              </div>
                            </div>
                            <div>
                              <h2 className="text-2xl">Business Fax Phone</h2>
                              <div className="flex">
                                <div className="w-10/12 mr-5">
                                  <input
                                    disabled
                                    value={formatPhoneNumber(
                                      commUser.businessFaxNumber
                                    )}
                                    className="mt-2 block w-full rounded-md border-1 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                                <div className="col-span-1">
                                  <svg
                                    onClick={() => {
                                      openModal(8);
                                    }}
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-4 w-4 cursor-pointer hover:red-500"
                                    viewBox="0 0 512 512"
                                  >
                                    <path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z" />
                                  </svg>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr />
                  </div>
                </div>
              )}

              {current === 1 && !contractView && (
                <ul role="list" className="divide-y divide-gray-100">
                  {contracts.length > 0 && (
                    <>
                      {contracts.map((contract) => (
                        <li
                          style={{ cursor: "pointer" }}
                          onClick={(e) => {
                            getCommercialClientContractDetail(
                              contract._id
                            ).then(() => {});
                          }}
                          key={contract._id}
                          className="relative justify-between gap-x-6 py-5"
                        >
                          <div className="border border-solid">
                            <div className="grid grid-cols-4 px-5 py-5">
                              <div className="col-span-1">
                                <span className="whitespace-nowrap py-4 pl-4 pr-3 text-2xl  text-gray-900">
                                  {nullTransform(
                                    getDeepValue(contract, "loan.loanNumber") ||
                                      ""
                                  )}
                                </span>
                                <br />
                                <br />
                                <span className="whitespace-nowrap py-4 pl-4 pr-3 text-md font-medium text-gray-900">
                                  Balance:{" "}
                                  <b>
                                    $
                                    {nullTransform(
                                      FormatNumber(
                                        `${
                                          parseFloat(
                                            getDeepValue(
                                              contract,
                                              "loan.balance.total"
                                            )
                                          ).toFixed(2) || 0
                                        }`
                                      )
                                    )}
                                  </b>
                                </span>
                              </div>
                              <div className="col-span-2">
                                <span className="whitespace-nowrap px-3 py-4 font-extrabold text-lg text-cyan-700">
                                  {contract.insuranceCompany}
                                </span>
                                <br />
                                <br />
                                <span className="whitespace-nowrap px-3 py-4 text-md font-medium text-gray-900">
                                  Policies: <b>{contract.policies.length}</b>
                                </span>
                              </div>
                              <div className="col-span-1">
                                <span
                                  className={getStatus(contract.status).style}
                                >
                                  {getStatus(contract.status).name}
                                </span>
                                <br />
                                <br />
                                <span className="whitespace-nowrap py-4 text-md font-medium text-gray-900">
                                  Premium Financed:{" "}
                                  <b>
                                    $
                                    {nullTransform(
                                      FormatNumber(
                                        `${parseFloat(
                                          getDeepValue(
                                            contract,
                                            "loan.principal"
                                          ).toFixed(2) || 0
                                        )}`
                                      )
                                    )}
                                  </b>
                                </span>
                              </div>
                            </div>
                          </div>
                        </li>
                      ))}
                    </>
                  )}
                </ul>
              )}

              {current === 1 && contractView && (
                <div className="container">
                  <div className="grid grid-cols-6 mb-10">
                    <div className="col-span-1">
                      <p className="text-center align-text-middle">
                        Select a Contract:
                      </p>
                    </div>
                    <div className="col-span-3">
                      <select
                        required
                        id="current-tab"
                        name="current-tab"
                        value={selectedContract._id}
                        onChange={(e) => {
                          openContractDetailsPage(e.target.value);
                        }}
                        className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      >
                        <option value="" style={{ textAlign: "center" }}>
                          {" "}
                          Select Contract{" "}
                        </option>
                        {contracts.map((contract) => (
                          <option
                            value={contract._id}
                            style={{ textAlign: "center" }}
                          >
                            {" "}
                            {nullTransform(
                              getDeepValue(contract, "loan.loanNumber") || ""
                            )}{" "}
                            -{" "}
                            {
                              contract?.loan?.meta?.insuranceDetails
                                ?.company_name
                            }{" "}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="flex w-11/12 my-5">
                    <div className="w-4/12">
                      <p className="text-2xl font-bold">
                        Loan ID:{" "}
                        {nullTransform(
                          getDeepValue(selectedContract, "loan.loanNumber") ||
                            ""
                        )}
                      </p>
                    </div>
                    <div className="flex w-4/12">
                      <div className="w-4/12"></div>
                      <div className="w-4/12">
                        <span
                          className={
                            getStatus(selectedContract.loan.status).style +
                            " justify-self-center"
                          }
                        >
                          {getStatus(selectedContract.loan.status).name}
                        </span>
                      </div>
                      <div className="w-4/12"></div>
                    </div>
                    <div className="w-4/12">
                      <span className="isolate inline-flex rounded-md shadow-sm">
                        <a
                          target="_blank"
                          href={link}
                          className="relative -ml-px inline-flex items-center bg-indigo-600 px-3 py-2 text-sm font-semibold text-white ring-1 ring-inset ring-gray-300 hover:bg-indigo-500 focus:z-10"
                          rel="noreferrer"
                        >
                          <CurrencyDollarIcon
                            className="-ml-0.5 h-5 w-5"
                            aria-hidden="true"
                          />
                          Apply Payment
                        </a>
                        {["current", "late"].includes(
                          selectedContract?.loan?.status
                        ) && (
                          <AccessControl
                            permission={"instantCancelLoan"}
                            fallback={<></>}
                            target={["god"]}
                          >
                            <button
                              onClick={() => {
                                cancelLoan(selectedContract?.loan?.loanNumber);
                              }}
                              type="button"
                              className="relative -ml-px inline-flex items-center bg-red-600 px-3 py-2 text-sm font-semibold text-white ring-1 ring-inset ring-gray-300 hover:bg-red-500 focus:z-10"
                            >
                              Cancel
                            </button>
                          </AccessControl>
                        )}
                      </span>
                    </div>
                  </div>

                  <div className="grid grid-cols-12 mb-10">
                    <div className="col-span-4">
                      <div className="mb-5">
                        <h2 className="text-xl text-cyan-900 font-bold">
                          Insurance Company:
                        </h2>
                        <div className="grid grid-cols-2 font-extralight">
                          <p>
                            {
                              selectedContract?.loan?.meta?.insuranceDetails
                                ?.company_name
                            }
                          </p>
                        </div>
                      </div>
                      <div className="mb-5">
                        <h2 className="text-xl text-cyan-900 font-bold">
                          Total Premium Financed:
                        </h2>
                        <div className="grid grid-cols-2 font-extralight">
                          <p>
                            $
                            {FormatNumber(
                              parseFloat(
                                selectedContract?.loan?.principal || 0
                              ).toFixed(2)
                            )}
                          </p>
                        </div>
                      </div>
                      <div className="mb-2">
                        <h2 className="text-xl text-cyan-900 font-bold">
                          Current Balance
                        </h2>
                        <div className="grid grid-cols-2 font-extralight">
                          <p>
                            $
                            {FormatNumber(
                              nullTransform(
                                parseFloat(
                                  selectedContract?.loan?.balance?.total || 0
                                ).toFixed(2)
                              )
                            )}
                          </p>
                        </div>
                      </div>
                      <div className="mb-5">
                        <h2 className="text-xl text-cyan-900 font-bold">
                          Disbursed At:
                        </h2>
                        <div className="grid grid-cols-2 font-extralight">
                          <p>
                            {selectedContract?.loan?.disbursedAt?.substring(
                              0,
                              10
                            )}
                          </p>
                        </div>
                      </div>
                      {selectedContract.loan.status === 'late' && (
                          <div className="mb-5">
                            <h2 className="text-xl text-cyan-900 font-bold">
                              Late Days
                            </h2>
                            <div className="grid grid-cols-2 font-extralight">
                              <p>
                                {selectedContract?.loan?.lateDays} Days
                              </p>
                            </div>
                          </div>
                      )

                      }
                    </div>
                    <div className="col-span-4">
                      <div className="mb-5">
                        <h2 className="text-xl text-cyan-900 font-bold">
                          Policy Start Date:
                        </h2>
                        <div className="grid grid-cols-2 font-extralight">
                          <p>
                            {selectedContract.policyStartDate.substring(0, 10)}
                          </p>
                        </div>
                      </div>
                      <div className="mb-5">
                        <h2 className="text-xl text-cyan-900 font-bold">
                          Policy End Date:
                        </h2>
                        <div className="grid grid-cols-2 font-extralight">
                          <p>
                            {selectedContract.policyEndDate.substring(0, 10)}
                          </p>
                        </div>
                      </div>
                      <div className="mb-5">
                        <h2 className="text-xl text-cyan-900 font-bold">
                          Disbursed At:
                        </h2>
                        <div className="grid grid-cols-2 font-extralight">
                          <p>
                            {selectedContract?.loan?.disbursedAt?.substring(0, 10)}
                          </p>
                        </div>
                      </div>
                      <div className="mb-2">
                        <h2 className="text-xl text-cyan-900 font-bold">
                          Arrears Due:
                        </h2>
                        <div className="grid grid-cols-2 font-extralight">
                          <p>
                            $
                            {FormatNumber(
                              parseFloat(
                                selectedContract?.loan?.due?.totalAllDue || 0
                              ).toFixed(2) || "0"
                            )}
                          </p>
                        </div>
                      </div>
                      <div className="mb-2">
                        <h2 className="text-xl text-cyan-900 font-bold">
                          Current Balance
                        </h2>
                        <div className="grid grid-cols-2 font-extralight">
                          <p>
                            $
                            {FormatNumber(
                              nullTransform(
                                parseFloat(
                                  selectedContract?.loan?.balance?.total || 0
                                ).toFixed(2)
                              )
                            )}
                          </p>
                        </div>
                      </div>

                      <div className="mb-5">
                        <h2 className="text-xl text-cyan-900 font-bold">
                          Scheduled Payment Date:
                        </h2>
                        <div className="grid grid-cols-2 font-extralight">
                          <p>{selectedContract.scheduledPaymentDate}</p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mb-10">
                    <div className="mb-5">
                      <p className="text-3xl">Policies - Breakdown</p>
                    </div>
                    <ul
                      role="list"
                      className="divide-y divide-y-8 divide-white"
                    >
                      {selectedContract.policyBreakdown.map((polBr, index) => (
                        <li key={index} className="flex bg-stone-200">
                          <div className="p-5 grid grid-cols-5">
                            <div className="col-span-1">
                              <h1 className="text-lg">Policy Number</h1>
                              <h2 className="text-sm">{polBr.policyNumber}</h2>
                            </div>
                            <div className="col-span-2 mx-2">
                              <h1 className="text-lg">Coverage Type</h1>
                              <h2 className="text-sm">{polBr.coverageType}</h2>
                            </div>
                            <div className="col-span-1">
                              <h1 className="text-lg">Premium</h1>
                              <h2 className="text-sm">{polBr.premium}</h2>
                            </div>
                            <div className="col-span-1">
                              <h1 className="text-lg">Insurance Company</h1>
                              <h2 className="text-sm">
                                {polBr.insuranceCompany}
                              </h2>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>

                  <DetailedSchedule currentContract={selectedContract}/>
                </div>
              )}
            </div>
          </div>
          <div className="bg-gray-100 px-4 py-3 text-right sm:px-6">
            <button
              type="button"
              onClick={back}
              className="text-sm font-semibold leading-6 text-gray-900 mx-3"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
