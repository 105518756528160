
import { setDeepValue } from "../../../../util/util";
import Http from "../../../../util/http";
import { useEffect, useState } from "react";
import swal from "sweetalert2";
import moment from "moment";
import http from "../../../../util/http";
import EasyAddress from "../../../../components/dashboard/easyaddress";
import TelephoneInput from "../../../../components/dashboard/telephoneInput";

export default function CreateRetailClient() {
  const [user, setUser] = useState({});
  const [personal, setPersonal] = useState({});
  const [contact, setContact] = useState({});
  const [address, setAddress] = useState({});
  const [employment, setEmployment] = useState({});
  const [payment, setPayment] = useState({});
  const [emailAddresses, setEmailAddresses] = useState([]);
  const [phoneNumbers, setPhoneNumbers] = useState([]);
  const [addresses, setAddresses] = useState([]);
  const [tempEmail, setTempEmail] = useState("");
  const [tempPhone, setTempPhone] = useState("");
  const [tempAddress2, setTempAddress] = useState("");
  const [countries, setCountries] = useState([]);
  const [curCountry, setCurCountry] = useState({});
  const [politicallyExposed, setPoliticallyExposed] = useState(false);
  const [mortgage, setMortgage] = useState(false);

  function setKey(key, value) {
    let object = { ...user };

    setDeepValue(object, value, key);
    setUser(object);
  }

  function handlePhoneNumberChange(e, type) {
    console.log(e.target.value);
    let formattedNumber = "";
    const value = e.target.value.replace(/\D/g, "");
    if (e.target.value.length <= 9) {
      formattedNumber = e.target.value;
    } else {
      formattedNumber = `(${value.slice(0, 3)}) ${value.slice(
        3,
        6
      )}-${value.slice(6)}`;
    }

    // Remove non-numeric characters
    setKeyContact(type, formattedNumber);
  }

  function setKeyContact(key, value) {
    let object = { ...contact };

    setDeepValue(object, value, key);
    setContact(object);
  }

  function setKeyAddress(key, value) {
    let object = { ...address };

    setDeepValue(object, value, key);
    setAddress(object);
  }

  function setKeyEmployment(key, value) {
    let object = { ...employment };

    setDeepValue(object, value, key);
    setEmployment(object);
  }

  function setKeyPayment(key, value) {
    let object = { ...payment };

    setDeepValue(object, value, key);
    setPayment(object);
  }

  function isPhoneNumber(text) {
    const phoneNumberRegex =
      /^(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9][0-8][0-9])\s*\)|([2-9][0-8][0-9]))\s*(?:[.-]\s*)?)?([2-9][0-9]{2})\s*(?:[.-]\s*)?([0-9]{4})$/;

    return phoneNumberRegex.test(text);
  }

  async function getCountries() {
    let countriesRaw = await http.request("admin", "countries");
    setCountries(countriesRaw);
    let countryId = JSON.parse(localStorage.getItem("geolocation"))._id;
    let curCountry = countriesRaw.filter(
      (country) => country._id == countryId
    )[0];
    setCurCountry(curCountry);
    setKeyAddress(
      "addressCountry",
      JSON.parse(localStorage.getItem("geolocation")).code
    );
  }

  function checkGeo() {}
  async function validateClient(email,phone){
    try{
      let response = await http.request("contracts", "clients/verify",{email: email,phone:phone,type: "retail"});
      console.log({response});
      if(response.id){
        return {isValid: false,id: response.id};
      }else{
        return {isValid: true};
      }
    }catch(e){
      console.log(e);
      swal.fire("Oops", "An error has occurred validating user emails", "error");
      return {isValid: false};
    }
  }

  function isValidEmail(email) {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  }
  async function saveForm(event) {
    event.preventDefault();
    if (isValidEmail(contact.email)) {
      console.log('Valid email:', contact.email);
    } else {
      console.log('Invalid email:', contact.email);
      swal.fire({
        title: 'Oops',
        text: 'Please check the email address.',
        icon: 'info',
        showConfirmButton: true
      })
      return
    }

    let result = await validateClient(contact.email,contact.mobilePhone);
    if(result.isValid === false && result.id === undefined){
      return
  }
    if(result.isValid === false){
        swal.fire({
            title: 'Attention!',
            text: "A client has been found with this email address or telephone number. Would you like to view this client?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes!',
            cancelButtonText: 'No, cancel!',
        }).then((opt)=>{
            if(opt.isConfirmed){
              window.location.href = `${window.location.origin}/dashboard/clients/retail/profile/${result.id}`;
            }
        })
      return;
    }

    let dialog = swal.fire({
      title: "Loading...",
      timerProgressBar: true,
      allowOutsideClick: false,
      didOpen: () => {
        swal.showLoading();
      },
      willClose: () => {},
    });
    event.preventDefault();

    // var day = new Date(user.expiryDate).getUTCDay();
    const selectedDay = moment(user.expiryDate);
    const today = moment().add(2,'weeks').startOf('day');
    console.log({exp: selectedDay.toDate(), week: today.toDate(),diff: selectedDay.diff(today,'days')})
    if (selectedDay.diff(today,'days') < 0) {
      document.getElementById("expiryDate").value = "";
      swal.fire("Invalid Id Expiration date. It should last more than 2 weeks from today.", "", "error");
      dialog.close();
    } else {
      user["politicallyExposed"] = politicallyExposed;
      user["mortgage"] = mortgage;
      let body = {
        personal: user,
        address,
        employment,
        contact,
        accounts: payment,
        type: "retail",
      };

      Http.request("contracts", "clients/retail/create", body).then(
        (response) => {
          dialog.close();
          if (response.error != null) {
            swal.fire("Oops", "An error has occurred", "error");
            dialog.close();
          } else {
            swal.fire("Client Created", "", "success").then((result) => {
              dialog.close();
              let search = window.location.search.substring(1);
              try{
                search = JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}')
              }catch(e){
                console.log(e)
              }
              //Remove this moving them to create contract page
              //window.location = `${window.location.origin}/dashboard/clients`

              window.location = `${window.location.origin}/dashboard/contracts/retail/create?id=${response._id}`

              // if(search.state === 'new'){
              //     window.location = `${window.location.origin}/dashboard/contracts/retail/create?id=${response._id}`
              // }else{
              //     window.location.href = `${window.location.origin}/dashboard/clients/retail/profile/${response._id}`;
              // }
            });
          }
        }
      );
    }
  }
  const delay = (delayInms) => {
    return new Promise(resolve => setTimeout(resolve, delayInms));
  };

  async function setEasyAddress(place)
  {
    console.log(place);
    let parts = place.shortFormattedAddress.split(',');
    if(parts.length < 4){
      setKeyAddress("addressFirstLine",parts[0]);
      await delay(1000);
      setKeyAddress("addressCityOrTown", parts[1]);
    }else if (parts.length >= 4)
    {

    }
  }

  useEffect(() => {
    if (countries.length < 1) {
      getCountries();
    }
  });

  return (
    <form onSubmit={(e)=>{saveForm(e)}}>
      <div className="shadow sm:overflow-hidden sm:rounded-md">
        <div className="space-y-6 bg-white px-4 py-6 sm:p-6">
          <h1 className="text-4xl text-center font-semibold  leading-6 text-gray-900">
            New Retail Client
          </h1>
          <hr />
          <div className="p-7">
            <div>
              <h2 className="text-2xl">Names</h2>
            </div>

            <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-3">
              <div className="col-span-1 sm:col-span-1">
                <input
                  required
                  type="text"
                  onChange={(e) => {
                    setKey("firstname", e.target.value);
                  }}
                  value={user.firstname}
                  className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
                <label className="block text-sm font-medium leading-6 text-gray-900">
                  First Name*
                </label>
              </div>
              <div className="col-span-1 sm:col-span-1">
                <input
                  type="text"
                  onChange={(e) => {
                    setKey("middlename", e.target.value);
                  }}
                  value={user.middlename}
                  className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
                <label className="block text-sm font-medium leading-6 text-gray-900">
                  Middle Name
                </label>
              </div>
              <div className="col-span-1 sm:col-span-1">
                <input
                  required
                  type="text"
                  onChange={(e) => {
                    setKey("lastname", e.target.value);
                  }}
                  value={user.lastname}
                  className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
                <label className="block text-sm font-medium leading-6 text-gray-900">
                  Last Name*
                </label>
              </div>
            </div>
          </div>

          <div className="p-7">
            <div>
              <h2 className="text-2xl">Contact Info</h2>
            </div>

            <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-3">
              <div className="col-span-1 sm:col-span-1">
                <input
                  required
                  type="text"
                  onChange={(e) => {
                    setKeyContact("email", e.target.value);
                  }}
                  value={contact.email}
                  className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
                <label className="block text-sm font-medium leading-6 text-gray-900">
                  Email Address*
                </label>
              </div>
            </div>
            <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-3 mt-5">
              <div className="col-span-1 sm:col-span-1">

                <label className="block text-sm font-medium leading-6 text-gray-900">
                <TelephoneInput isRequired={true} setKey={setKeyContact} path={'mobilePhone'} value={contact.mobilePhone}  className={"mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"}/>
                  Phone Number (M)*
                </label>
                
              </div>
              <div className="col-span-1 sm:col-span-1">
              <TelephoneInput setKey={setKeyContact} path={'housePhone'} value={contact.housePhone}  className={"mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"}/>
                
                <label className="block text-sm font-medium leading-6 text-gray-900">
                  Phone Number (H)
                </label>
              </div>
              <div className="col-span-1 sm:col-span-1">
              <TelephoneInput setKey={setKeyContact} path={'workPhone'} value={contact.workPhone}  className={"mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"}/>
                <label className="block text-sm font-medium leading-6 text-gray-900">
                  Phone Number (W)
                </label>
              </div>
            </div>
          </div>

          <div className="p-7">
            <div className="flex">
              <h2 className="text-2xl">Address</h2>
              <EasyAddress setEasyAddress={setEasyAddress}/>
            </div>

            <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-3">
              <div className="col-span-1 sm:col-span-1">
                <input
                  required
                  type="text"
                  onChange={(e) => {
                    setKeyAddress("addressFirstLine", e.target.value);
                  }}
                  value={address.addressFirstLine}
                  className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
                <label className="block text-sm font-medium leading-6 text-gray-900">
                  Address - First Line*
                </label>
              </div>
              <div className="col-span-1 sm:col-span-1">
                <input
                  type="text"
                  onChange={(e) => {
                    setKeyAddress("addressSecondLine", e.target.value);
                  }}
                  value={address.addressSecondLine}
                  className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
                <label className="block text-sm font-medium leading-6 text-gray-900">
                  Address - Second Line
                </label>
              </div>
              <div className="col-span-1 sm:col-span-1">
                <input
                  required
                  type="text"
                  onChange={(e) => {
                    setKeyAddress("addressCityOrTown", e.target.value);
                  }}
                  value={address.addressCityOrTown}
                  className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
                <label className="block text-sm font-medium leading-6 text-gray-900">
                  Address - City or Town*
                </label>
              </div>
              <div className="col-span-1 sm:col-span-1">
                <input
                  required
                  type="text"
                  onChange={(e) => {
                    setKeyAddress("addressStateOrProvince", e.target.value);
                  }}
                  value={address.addressStateOrProvince}
                  className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
                <label className="block text-sm font-medium leading-6 text-gray-900">
                  Address - State or Province*
                </label>
              </div>
              {/* <div className="col-span-1 sm:col-span-1">
                <select
                  required
                  name="country"
                  className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  onChange={(e) => {
                    setKeyAddress("addressCountry", e.target.value);
                  }}
                >
                  <option key="">Select Country</option>
                  {countries.map(item => <option key={item.code} value={item.code}>{item.title}</option>)}                        </select>

                <label className="block text-sm font-medium leading-6 text-gray-900">
                  Address - Country*
                </label>
              </div> */}
            </div>
          </div>

          <div className="p-7">
            <div>
              <h2 className="text-2xl">Personal Details</h2>
            </div>

            <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-3">
              <div className="col-span-1 sm:col-span-1">
                <input
                  type="date"
                  required
                  rows="10"
                  onChange={(e) => {
                    setKey("dateOfBirth", e.target.value);
                  }}
                  className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
                <label className="block text-sm font-medium leading-6 text-gray-900 mb-3">
                  Date of Birth*
                </label>
              </div>
              <div className="col-span-1 sm:col-span-1">
                <select
                  type="date"
                  required
                  onChange={(e) => {
                    setKey("gender", e.target.value);
                  }}
                  className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                >
                  <option value="">Select a Gender</option>
                  <option value={"male"}>Male</option>
                  <option value={"female"}>Female</option>
                </select>
                <label className="block text-sm font-medium leading-6 text-gray-900 mb-3">
                  Gender*
                </label>
              </div>
              {curCountry.code == "lca" && (
                <div className="col-span-1 sm:col-span-1">
                  <input
                    required
                    type="text"
                    onChange={(e) => {
                      setKey("placeOfBirth", e.target.value);
                    }}
                    value={user.placeOfBirth}
                    className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                  <label className="block text-sm font-medium leading-6 text-gray-900">
                    Place of Birth*
                  </label>
                </div>
              )}

              {curCountry.code == "lca" && (
                <div className="col-span-1 sm:col-span-1">
                  <select
                    required
                    name="country"
                    className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    onChange={(e) => {
                      setKey("nationality", e.target.value);
                    }}
                    value={user.nationality}
                  >
                    <option key="">Select Country</option>
                    {countries.map((item) => (
                      <option key={item.code} value={item.code}>
                        {item.title}
                      </option>
                    ))}
                  </select>

                  <label className="block text-sm font-medium leading-6 text-gray-900">
                    Nationality*
                  </label>
                </div>
              )}
            </div>

            <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-3">
              <div className="col-span-1 sm:col-span-1">
                <select
                  required
                  onChange={(e) => {
                    setKey("idType", e.target.value);
                  }}
                  className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                >
                  <option value=""> Select ID Type</option>
                  <option value="National ID">National ID</option>
                  <option value="Driver's Licenses">Driver's Licenses</option>
                  <option value="Passport">Passport</option>
                </select>
                <label className="block text-sm font-medium leading-6 text-gray-900">
                  ID Type*
                </label>
              </div>
              <div className="col-span-1 sm:col-span-1">
                <input
                  required
                  type="text"
                  onChange={(e) => {
                    setKey("id", e.target.value);
                  }}
                  value={user.id}
                  className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
                <label className="block text-sm font-medium leading-6 text-gray-900">
                  ID Number*
                </label>
              </div>

              <div className="col-span-1 sm:col-span-1">
                <input
                  type="date"
                  onChange={(e) => {
                    setKey("issuedDate", e.target.value);
                  }}
                  className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
                <label className="block text-sm font-medium leading-6 text-gray-900">
                  ID Issued Date*
                </label>
              </div>
            </div>

            <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-3 mt-3">
              <div className="col-span-1 sm:col-span-1">
                <input
                  id="expiryDate"
                  type="date"
                  onChange={(e) => {
                    setKey("expiryDate", e.target.value);
                  }}
                  className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
                <label className="block text-sm font-medium leading-6 text-gray-900">
                  ID Expiry Date*
                </label>
              </div>
            </div>
            <br />

            {curCountry.code == "lca" && (
              <div className="col-span-4 sm:col-span-4">
                <div className="flex items-center">
                  <label className="block text-sm font-medium leading-6 text-gray-900 mr-2">
                    Politically Exposed Person
                  </label>
                  <input
                    type="checkbox"
                    onChange={(e) => {
                      setPoliticallyExposed(e.target.checked);
                    }}
                    checked={politicallyExposed}
                    className=" block rounded-md px-3 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
            )}

            {curCountry.code == "lca" && (
              <div className="p-7">
                <div>
                  <h2 className="text-2xl">Employment</h2>
                </div>

                <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-3">
                  <div className="col-span-1 sm:col-span-1">
                    <input
                      type="text"
                      required
                      onChange={(e) => {
                        setKeyEmployment("occupation", e.target.value);
                      }}
                      value={employment.occupation}
                      className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                    <label className="block text-sm font-medium leading-6 text-gray-900 mb-3">
                      Occupation*
                    </label>
                  </div>
                  <div className="col-span-1 sm:col-span-1">
                    <input
                      required
                      type="text"
                      onChange={(e) => {
                        setKeyEmployment("employer", e.target.value);
                      }}
                      value={employment.employmer}
                      className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                    <label className="block text-sm font-medium leading-6 text-gray-900">
                      Employer*
                    </label>
                  </div>

                  <div className="col-span-1 sm:col-span-1">
                    <input
                      required
                      type="text"
                      onChange={(e) => {
                        setKeyEmployment("position", e.target.value);
                      }}
                      value={employment.position}
                      className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                    <label className="block text-sm font-medium leading-6 text-gray-900">
                      Position*
                    </label>
                  </div>
                </div>

                <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-3">
                  <div className="col-span-1 sm:col-span-1">
                    <input
                      type="text"
                      required
                      onChange={(e) => {
                        setKeyEmployment("sourceOfWealth", e.target.value);
                      }}
                      value={employment.sourceOfWealth}
                      className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                    <label className="block text-sm font-medium leading-6 text-gray-900">
                      Source of Wealth*
                    </label>
                  </div>{" "}
                  <br />
                </div>
              </div>
            )}

            {/* <div className="p-7">
            <div>
              <h2 className="text-2xl">Payment Information</h2>
            </div>

            <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-3">
              <div className="col-span-1 sm:col-span-1">
                <select
                  required
                  onChange={(e) => {
                    setKeyPayment("paymentType", e.target.value);
                  }}
                  value={payment.paymentType}
                  className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                >
                  <option value="">Select Type</option>
                  <option value="bank">Bank</option>
                  <option value="cash">Cash</option>
                </select>
                <label className="block text-sm font-medium leading-6 text-gray-900 mb-3">
                  Payment Type*
                </label>
              </div>
              {payment.paymentType == 'bank' && <div className="col-span-1 sm:col-span-1">
                <input
                  required
                  type="text"
                  onChange={(e) => {
                    setKeyPayment("bankName", e.target.value);
                  }}
                  value={employment.employmer}
                  className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
                <label className="block text-sm font-medium leading-6 text-gray-900">
                  Bank Name*
                </label>
              </div>}

              {payment.paymentType == 'bank' && <div className="col-span-1 sm:col-span-1">
                <input
                  required
                  type="text"
                  onChange={(e) => {
                    setKeyPayment("bankAccountNumber", e.target.value);
                  }}
                  value={payment.bankAccountNumber}
                  className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
                <label className="block text-sm font-medium leading-6 text-gray-900">
                  Bank Account Number*
                </label>
              </div>}
            </div>

            {payment.paymentType == 'bank' && <div className="grid grid-cols-3 gap-3">
              <div className="col-span-1 sm:col-span-1">
                <select
                  required
                  onChange={(e) => {
                    setKeyPayment("accountType", e.target.value);
                  }}
                  value={payment.type}
                  className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                >
                  <option value="">Select Account Type</option>
                  <option value="savings">Savings</option>
                  <option value="chequing">Chequing</option>
                </select>
                <label className="block text-sm font-medium leading-6 text-gray-900 mb-3">
                  Account Type*
                </label>
              </div>
            </div>}
          </div> */}
          </div>
          <div className="bg-gray-100 px-4 py-3 text-right sm:px-6">
            <a
              href="/dashboard/clients"
              type="button"
              className="text-sm font-semibold leading-6 text-gray-900 mx-3"
            >
              Cancel
            </a>
            <button
              type="submit"
              className="inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Save & Continue
            </button>
          </div>
        </div>
      </div>
    </form>
  );
}
